import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { isMobile } from '@avocadoui/utils';

export default function BookingUrl() {
  const { token } = useParams();

  useEffectOnce(() => {
    if (!isMobile()) return;
    const env = window.location.hostname === 'v.fbmms.cn' ? 'release' : 'trial';
    const url = `http://sms.elihr.cn/?env=${env}&scene=3:${token}&path=realTimePages/pages/schedule/schedule&auto=1`;
    window.location.replace(url);
  });

  if (isMobile()) return null;

  return (
    <div
      style={{
        margin: 'auto',
        maxWidth: '600px',
        height: '100vh',
        background:
          'url("https://webcdn.fbmms.cn/miniprogram/panohire-wechat/asset/images/weakup-background.png") center center / cover no-repeat',
      }}
    >
      <div style={{ height: '500px' }} />
      <div
        style={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 500,
          color: 'rgba(0,0,0,0.56)',
        }}
      >
        请在移动端打开此链接
      </div>
    </div>
  );
}
