import { useEffect, useRef } from 'react';
import { useAppSelector } from '../../redux/hooks';

const Video = ({ src, onEnd }: { src: string; onEnd?: (ev: Event) => any }) => {
  const ref = useRef<HTMLVideoElement>(null);
  const step = useAppSelector((state) => state.interview.step);
  const isFllowUp = useAppSelector(
    (state) => state.interview.stepInfo.isFllowUp
  );
  useEffect(() => {
    if (step === 0 && ref.current && !isFllowUp) {
      ref.current.play();
    }
  }, [isFllowUp, step]);

  useEffect(() => {
    const videoRef = ref.current;
    if (videoRef) {
      videoRef.onended = onEnd;
    }
    return () => {
      if (videoRef) {
        videoRef.onended = null;
      }
    };
  }, [onEnd]);

  return (
    <video
      ref={ref}
      src={src}
      width="100%"
      height="100%"
      autoPlay
      playsInline
      style={{ objectFit: 'contain' }}
    />
  );
};

export default Video;
