import { useAppSelector } from '../../redux/hooks';
import useRoom from './useRoom';
import ShowOrHide from './ShowOrHide';
import useEffectPlayIngUsers from './useEffectPlayIngUsers';
import RemotePlayerContainer from './RemotePlayerContainer';

function RemotePlayerContainerInGridMode() {
  const { ingUserIds } = useRoom();
  const remoteUsersChanged = useAppSelector(
    (state) => state.room.remoteUsersChanged
  );

  useEffectPlayIngUsers();

  if (remoteUsersChanged && ingUserIds.length > 0) {
    return (
      <>
        {ingUserIds.map((uid, index) => {
          const id = `remote-player-container-${uid}`;
          return (
            <ShowOrHide key={id} idx={index}>
              <RemotePlayerContainer uid={uid} />
            </ShowOrHide>
          );
        })}
      </>
    );
  }

  return null;
}

export default RemotePlayerContainerInGridMode;
