import { Box } from '..';

interface IDrawer {
  isOpen: boolean;
  width?: number;
  duration?: number;
}

const RightDrawer: React.FC<IDrawer> = ({
  children,
  isOpen = true,
  width = 320,
  duration = 250,
}) => {
  return (
    <Box position="relative" height="100%">
      <Box
        sx={{
          height: '100%',
          backgroundColor: 'white',
          overflowY: 'auto',
          transition: `transform ${duration}ms ease-in-out, width ${duration}ms ease-in-out`,
          ...(isOpen
            ? {
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
                width: `${width}px`,
                transform: 'none',
              }
            : {
                width: '0px',
                transform: `translateX(${width}px)`,
              }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default RightDrawer;
