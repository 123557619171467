import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { ArrowLeftIcon } from 'fbm-ui';
import { IconButton } from '@mui/material';
import {
  Box,
  Button,
  Stack,
  TextField,
  Text,
  toast,
  LoadingDots,
} from '@avocadoui/components';
import { loginActions } from './slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import CodeButton from './CodeButton';
import { useRoomSend } from '../Room/useMachineContext';
import useMutationLogin from '../Room/useMutationLogin';
import useQueryCurrentUserInfo from '../Room/useQueryCurrentUserInfo';

type FormData = {
  code?: string;
};

const schema = z.object({
  code: z.string().length(4, '验证码错误'),
});

function bluredMobile(mobile: string) {
  return `${mobile.slice(0, 3)}****${mobile.slice(7)}`;
}

function bluredEmail(email: string) {
  const headLength = 3;
  const head = email.split('@')[0].slice(0, headLength);
  const tail = email.split('.')[1];
  const star = '*'.repeat(email.length - headLength - tail.length + 2);
  return `${head}${star}${tail}`;
}

function bluredAccount(account: string[], mode: number) {
  const value = account[mode];
  if (mode === 0) return bluredMobile(value);
  if (mode === 1) return bluredEmail(value);
  return '';
}

function StepTwo() {
  const send = useRoomSend();
  const dispatch = useAppDispatch();
  const { mode, mobile, mobileAreaCode, email } = useAppSelector(
    (state) => state.login
  );
  const account = [mobile, email];

  const { mutate: login, isLoading: isLoadingLogin } = useMutationLogin();
  const {
    refetch: refetchCurrentUserInfo,
    isFetching: isFetchingCurrentUserInfo,
  } = useQueryCurrentUserInfo();

  const isSubmitting = isLoadingLogin || isFetchingCurrentUserInfo;

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      code: '',
    },
    resolver: zodResolver(schema),
  });

  const code = watch('code');

  const onSubmit = handleSubmit(async (data) => {
    const { code } = data;
    if (code) {
      login(
        { mode, mobile, mobileAreaCode, email, code },
        {
          onSuccess: async (res) => {
            if (res.code === 0) {
              await refetchCurrentUserInfo();
              send('NEXT');
            } else if (res.code === -2016) {
              toast.error('验证码错误');
            } else if (res.code === -3004) {
              const message =
                mode === 0
                  ? '该手机号无面试安排，请检查后再试'
                  : '该邮箱无面试安排，请检查后再试';
              toast.error(message);
            } else if (res.code === -5003) {
              const message =
                mode === 0 ? '登录失败，手机号重复' : '登录失败，邮箱重复';
              toast.error(message);
            } else {
              if (res.message) toast.error(res.message);
            }
          },
        }
      );
    }
  });

  const isSubmitDisabled = React.useMemo(() => {
    return !!errors.code || code?.length !== 4 || isSubmitting;
  }, [errors.code, code, isSubmitting]);

  const handleBack = React.useCallback(() => {
    dispatch(loginActions.prevStep());
  }, [dispatch]);

  return (
    <Stack spacing={2} px={3} pt={2} pb={3}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton onClick={handleBack} sx={{ color: 'rgba(0,0,0,0.86)' }}>
          <ArrowLeftIcon sx={{ color: 'rgba(0,0,0,0.86)' }} />
        </IconButton>
        <Text variant="h6">验证{mode === 0 ? '手机号' : '邮箱地址'}</Text>
      </Stack>

      <Text variant="body2" color="text.secondary">
        请输入发送至 {bluredAccount(account, mode)} 的4位验证码，有效期5分钟
      </Text>

      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} minHeight="70px">
            <Controller
              control={control}
              name="code"
              // render={({ field, fieldState: { error } }) => (
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="验证码"
                  autoFocus
                  fullWidth
                  // error={!!error}
                  // helperText={error?.message}
                />
              )}
            />
            <Box flexShrink={0}>
              <CodeButton />
            </Box>
          </Stack>

          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            disabled={isSubmitDisabled}
          >
            登录{isSubmitting && <LoadingDots />}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default StepTwo;
