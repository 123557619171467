import * as React from 'react';
import { Avatar, Box, Button, Stack, Text, toast } from '@avocadoui/components';
import { log } from '@avocadoui/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { roomActions } from './slice';
import useRoom from './useRoom';
import useRTM from './useRTM';
import JoinTimeText from './JoinTimeText';

declare global {
  interface Window {
    openCandidateModal: (
      candidateToken: string,
      applicationTken: string,
      processType: number
    ) => void;
  }
}

interface IToJoinUserItem {
  id: string;
  name: string;
  avatar: string;
  applicationToken: string;
  candidateToken: string;
  isOnline: boolean;
  joinTime: number;
  hasInterviewed: boolean;
}

function ToJoinUserItem({
  id,
  name,
  avatar,
  applicationToken,
  candidateToken,
  isOnline,
  joinTime,
  hasInterviewed,
}: IToJoinUserItem) {
  const { processType, isAgoraConnected } = useRoom();
  const { sendInvitation } = useRTM();
  const dispatch = useAppDispatch();
  const rtcConnectionState = useAppSelector(
    (state) => state.room.rtcConnectionState
  );
  const rtmConnectionState = useAppSelector(
    (state) => state.room.rtmConnectionState
  );

  const handleSendInvitation = (e: React.FormEvent) => {
    e.stopPropagation();
    if (isAgoraConnected) {
      sendInvitation(id);
      dispatch(roomActions.addToUserIdsAtInviting(id));
    } else {
      toast.error('正在连接服务器，若长时间未连接成功，请刷新页面重试', {
        duration: 5000,
      });
      log.capture('FAIL:发起视频:服务器未连接', {
        rtc: rtcConnectionState,
        rtm: rtmConnectionState,
      });
    }
  };

  return (
    <Stack
      onClick={() =>
        window.openCandidateModal(candidateToken, applicationToken, processType)
      }
      direction="row"
      justifyContent="space-between"
      px={2}
      py={1}
      sx={{
        ...(isOnline
          ? {
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '& .status': {
                  display: 'none',
                },
                '& .action': {
                  display: 'flex',
                },
              },
            }
          : {
              opacity: 0.2,
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }),
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Avatar src={avatar} name={name} />
        <Stack>
          <Text variant="body2" color="text.primary">
            {name}
            {!isOnline && '（离线）'}
          </Text>
        </Stack>
      </Stack>

      <Stack className="status" direction="row" alignItems="center">
        {isOnline && joinTime > 0 && !hasInterviewed && (
          <JoinTimeText joinTime={joinTime} />
        )}
        {hasInterviewed && (
          <Text variant="body2" color="text.secondary">
            已视频
          </Text>
        )}
      </Stack>
      <Stack
        className="action"
        direction="row"
        alignItems="center"
        sx={{ display: 'none' }}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          variant="outlined"
          color="default"
          onClick={handleSendInvitation}
        >
          发起视频
        </Button>
      </Stack>
    </Stack>
  );
}

function ToJoinUserList() {
  const { ingUserIds, joinedUserIds } = useRoom();
  const roomUsers = useAppSelector((state) => state.room.roomUsers);
  const userIdsAtInviting = useAppSelector(
    (state) => state.room.userIdsAtInviting
  );

  const users = React.useMemo(() => {
    const formated = roomUsers
      .filter((u) => !ingUserIds.includes(u.agoraUid.toString()))
      .filter((u) => !userIdsAtInviting.includes(u.agoraUid.toString()))
      .filter((u) => u.userType !== 1)
      .map((user) => {
        return {
          id: user.agoraUid.toString(),
          name: user.userName,
          avatar: user.avatar,
          applicationToken: user.applicationToken,
          candidateToken: user.candidateToken,
          isOnline: joinedUserIds.includes(user.agoraUid.toString()),
          joinTime: user.joinTime,
          hasInterviewed: user.connectionStatus === 2,
        };
      });
    const sortedJoinTime = [...formated].sort((a, b) => {
      if (a.joinTime === 0 && b.joinTime !== 0) return 1;
      if (a.joinTime !== 0 && b.joinTime === 0) return -1;
      return a.joinTime - b.joinTime;
    });
    const sortedHasInterviewed = [...sortedJoinTime].sort((a, b) => {
      return a.hasInterviewed === b.hasInterviewed
        ? 0
        : a.hasInterviewed
        ? 1
        : -1;
    });
    const sortedOnline = [...sortedHasInterviewed].sort((a, b) => {
      return a.isOnline === b.isOnline ? 0 : a.isOnline ? -1 : 1;
    });
    return sortedOnline;
  }, [roomUsers, ingUserIds, userIdsAtInviting, joinedUserIds]);

  if (users && users.length === 0) {
    return null;
  }

  return (
    <Box>
      <Box px={2} py={1.5}>
        <Text variant="body2" color="text.secondary">
          待视频列表
        </Text>
      </Box>
      {users.map((u) => (
        <ToJoinUserItem
          key={u.id}
          id={u.id}
          name={u.name}
          avatar={u.avatar}
          applicationToken={u.applicationToken}
          candidateToken={u.candidateToken}
          isOnline={u.isOnline}
          joinTime={u.joinTime}
          hasInterviewed={u.hasInterviewed}
        />
      ))}
    </Box>
  );
}

export default ToJoinUserList;
