import { Box, Button, Typography } from 'fbm-ui';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { interviewActions } from './slice';
import { Stack } from 'fbm-ui/lib/mui';
import { useTranslation } from 'react-i18next';

const VideoMask = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { tryRemainTimes } = useAppSelector(
    (state) => state.interview.stepInfo.videoBroadcast
  );

  const [countdown, setCountdown] = useState(60);

  const handleOk = useCallback(() => {
    dispatch(interviewActions.nextStep());
  }, [dispatch]);

  const handleReplay = useCallback(() => {
    dispatch(interviewActions.initialStep());
  }, [dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((timeLeft) => {
        if (timeLeft === 1) {
          dispatch(interviewActions.nextStep());
          return 0;
        } else {
          return timeLeft - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [dispatch]);

  return (
    <Box
      width={1}
      height="calc(100vh - 60px)"
      bgcolor="rgba(0, 0, 0, 0.56)"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      top={0}
      sx={{ position: 'absolute', zIndex: 10, paddingBottom: 10 }}
    >
      <Box
        sx={{ flex: 1 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" color="white" mb={7}>
          {t('Auto-advance to answering after Xs; no replay available.', {
            duration: countdown,
          })}
        </Typography>
      </Box>
      <Stack
        spacing={2}
        sx={{ position: 'absolute', top: 600 }}
        direction="row"
      >
        <Button onClick={handleOk}>{t('Start')}</Button>
        {tryRemainTimes > 1 && (
          <Button onClick={handleReplay} color="inherit">
            重听本题（剩余{tryRemainTimes - 1}次）
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default VideoMask;
