import useRoom from './useRoom';
import GridModeLayout from './GridModeLayout';
import FocusedModeLayout from './FocusedModeLayout';

function VideoContainerLayout() {
  const { ingUserIds } = useRoom();

  if (ingUserIds.length > 1) {
    return <FocusedModeLayout />;
  }

  return <GridModeLayout />;
}

export default VideoContainerLayout;
