import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'zh',
    debug: true,
  });

i18n.services.formatter.add('mmmsss', (value, lng, options) => {
  const dur = dayjs.duration(value, 'seconds');
  const minutes = Math.floor(dur.asMinutes());
  const seconds = dur.seconds();

  if (minutes > 0) {
    return `${minutes}m${seconds}s`;
  } else {
    return `${seconds}s`;
  }
});

i18n.services.formatter.add('m分s秒', (value, lng, options) => {
  return dayjs.duration(value, 'seconds').format('m分s秒');
});

export default i18n;
