import client from './client';
import { apiCheck } from '../utils/apiCheck';

export function getTypewriterArticles(interviewToken: string) {
  return client(`tpInterview/${interviewToken}/selectArticle`).then(apiCheck);
}
export function login(
  interviewToken: string,
  mobile: string,
  code: string,
  mobileAreaCode: string
) {
  return client('tpInterview/login', {
    body: {
      interviewToken,
      mobile,
      code,
      mobileAreaCode,
    },
  }).then(apiCheck);
}

export function sendSmsCode(mobile: string, mobileAreaCode: string) {
  return client('mobile/login/sendSmsCode', {
    body: {
      mobile,
      mobileAreaCode,
    },
  }).then(apiCheck);
}

export function getTypewriterArticle(
  interviewToken: string,
  articleToken: string
) {
  return client(
    `tpInterview/${interviewToken}/interviewInfo/${articleToken}`
  ).then(apiCheck);
}
export function setTypewriterResult(
  token,
  articleToken,
  speed,
  accuracyRate,
  errorWordCount,
  backCount,
  allWordCount,
  streamName
) {
  return client('tpInterview/sync', {
    body: {
      token,
      status: 3,
      streamName,
      articleToken,
      speed,
      accuracyRate,
      errorWordCount,
      backCount,
      allWordCount,
    },
  }).then(apiCheck);
}

export function getTypewriterStatus(interviewToken: string) {
  return client(`tpInterview/interviewInfo/${interviewToken}`).then(apiCheck);
}

const TypewriterAPI = {
  getTypewriterArticle,
  getTypewriterArticles,
  login,
  sendSmsCode,
  setTypewriterResult,
  getTypewriterStatus,
};

export default TypewriterAPI;
