import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Stack,
  Text,
  ConfirmDialog,
  useDialog,
  LoadingDots,
  IOSSwitch,
  Tooltip,
} from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';
import useRoom from './useRoom';
import useRTM from './useRTM';
import useMutationRoomModel from './useMutationRoomModel';

declare global {
  interface Window {
    openCandidateModal: (
      candidateToken: string,
      applicationTken: string,
      processType: number
    ) => void;
  }
}

interface IJoinedUserItem {
  id: string;
  name: string;
  avatar: string;
  role: number;
  applicationToken: string;
  candidateToken: string;
  isInviting: boolean;
  isTalking: boolean;
  isMe: boolean;
}

function JoinedUserItem({
  id,
  name,
  avatar,
  role,
  applicationToken,
  candidateToken,
  isInviting,
  isTalking,
  isMe,
}: IJoinedUserItem) {
  const { processType } = useRoom();
  const { kickUser, cancelInvitation } = useRTM();
  const {
    isOpen: isKickUserOpen,
    onOpen: onKickUserOpen,
    onClose: onKickUserClose,
  } = useDialog();

  const handleKickUser = () => {
    kickUser(id);
  };

  const handleCancel = (e: React.FormEvent) => {
    e.stopPropagation();
    cancelInvitation(id);
  };

  return (
    <>
      <Stack
        onClick={() => {
          if (role === 2) {
            window.openCandidateModal(
              candidateToken,
              applicationToken,
              processType
            );
          }
        }}
        direction="row"
        justifyContent="space-between"
        px={2}
        py={1}
        sx={
          isMe
            ? {}
            : {
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  '& .status': {
                    display: 'none',
                  },
                  '& .action': {
                    display: 'flex',
                  },
                },
              }
        }
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Box position="relative">
            <Avatar src={avatar} name={name} />
            {isTalking && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: 'primary.main',
                  borderRadius: '50%',
                }}
              />
            )}
          </Box>
          <Stack>
            <Text variant="body2" color="text.primary">
              {name}
              {isMe && '(我)'}
            </Text>
            <Text variant="caption" color="text.secondary">
              {role === 1 ? '企业成员' : '候选人'}
            </Text>
          </Stack>
        </Stack>
        {!isMe && isInviting && (
          <Stack className="status" direction="row" alignItems="center">
            <Text variant="body2" color="text.secondary">
              呼叫中
              <LoadingDots />
            </Text>
          </Stack>
        )}
        {!isMe && isInviting && (
          <Stack
            className="action"
            direction="row"
            alignItems="center"
            sx={{ display: 'none' }}
          >
            <Button variant="outlined" color="default" onClick={handleCancel}>
              取消
            </Button>
          </Stack>
        )}
        {!isMe && !isInviting && (
          <Stack
            className="action"
            direction="row"
            alignItems="center"
            sx={{ display: 'none' }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e: React.FormEvent) => {
                e.stopPropagation();
                onKickUserOpen();
              }}
            >
              移出视频
            </Button>
          </Stack>
        )}
      </Stack>
      <ConfirmDialog
        isOpen={isKickUserOpen}
        onClose={onKickUserClose}
        desc="你确认要将所选人员移出吗？"
        confirmTxt="移出"
        variant="alert"
        onConfirm={handleKickUser}
      />
    </>
  );
}

function JoinedUserList() {
  const { ingUserIds, isWaitMode } = useRoom();
  const localUserId = useAppSelector((state) => state.room.localUserId);
  const roomUsers = useAppSelector((state) => state.room.roomUsers);
  const userIdsAtInviting = useAppSelector(
    (state) => state.room.userIdsAtInviting
  );
  const maxAudioVolumeUserId = useAppSelector(
    (state) => state.room.maxAudioVolumeUserId
  );
  const isRecordRoomType = useAppSelector(
    (state) => state.room.roomInfo.roomType === 1
  );

  const users = React.useMemo(() => {
    return [`${localUserId}`, ...ingUserIds]
      .map((id) => {
        const user = roomUsers.find((u) => u.agoraUid.toString() === id);
        if (user) {
          return {
            id,
            name: user.userName,
            avatar: user.avatar,
            role: user.userType,
            applicationToken: user.applicationToken,
            candidateToken: user.candidateToken,
            isInviting: userIdsAtInviting.includes(id),
            isTalking: maxAudioVolumeUserId.toString() === id,
            isMe: id === localUserId.toString(),
          };
        }
        return null;
      })
      .filter((u) => u);
  }, [
    roomUsers,
    localUserId,
    ingUserIds,
    userIdsAtInviting,
    maxAudioVolumeUserId,
  ]);

  const { mutate: toggleInvitationModel } = useMutationRoomModel();

  const handleToggleModel = React.useCallback(
    (event) => {
      console.log('handleToggleModel:', event.target.checked);
      const model = event.target.checked ? 2 : 1;
      toggleInvitationModel({ model });
    },
    [toggleInvitationModel]
  );

  return (
    <Box>
      <Stack px={2} py={1.5} direction="row" justifyContent="space-between">
        <Text variant="body2" color="text.secondary">
          视频中
        </Text>
        {!isRecordRoomType && (
          <Tooltip title="开启叫号模式后，候选人需手动邀请才能进入视频">
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ cursor: 'default' }}
            >
              <Text variant="body2" color="text.secondary">
                叫号模式
              </Text>
              <IOSSwitch checked={isWaitMode} onChange={handleToggleModel} />
            </Stack>
          </Tooltip>
        )}
      </Stack>
      {users.map(
        (u) =>
          u && (
            <JoinedUserItem
              key={u.id}
              id={u.id}
              name={u.name}
              avatar={u.avatar}
              role={u.role}
              applicationToken={u.applicationToken}
              candidateToken={u.candidateToken}
              isInviting={u.isInviting}
              isTalking={u.isTalking}
              isMe={u.isMe}
            />
          )
      )}
    </Box>
  );
}

export default JoinedUserList;
