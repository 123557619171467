import { Paper } from '@mui/material';
import { Button, Dialog, DoneIcon, SpeedIcon, Typography } from 'fbm-ui';
import { Box, Stack } from 'fbm-ui/lib/mui';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Text = {
  2: '测试结束，本次暂未通过测试。',
  3: '恭喜你完成测试，祝接下来流程顺利！',
  1: '恭喜你完成测试，祝接下来流程顺利！',
};

const Finish = ({
  status = 1,
  retry = 0,
  typingInfo = { speed: 0, accuracyRate: 0 },
}) => {
  const navigate = useNavigate();
  const { interviewToken = '', applicationToken = '' } = useParams();
  const canRetry = status === 2 && retry > 1;

  const handleQuit = useCallback(() => {
    if (canRetry) {
      navigate(`/t/login/${applicationToken}/${interviewToken}`);
      return;
    }
    navigate(`/process/${applicationToken}`);
  }, [canRetry, navigate, applicationToken, interviewToken]);

  useEffect(() => {
    if (!canRetry && status === 2) {
      navigate(`/process/${applicationToken}`);
    }
  }, [canRetry, applicationToken, navigate, status]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleQuit();
    }, 30000);
    return () => clearTimeout(timer);
  }, [handleQuit]);

  return (
    <Dialog width={480} open footer={null}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        overflow="hidden"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Stack
          spacing={5}
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', height: 360 }}
        >
          <Typography fontWeight={700} textAlign="center" fontSize={20}>
            {Text[status]}
          </Typography>
          <Stack mb={2} component={Paper} variant="outlined" width={240}>
            <Stack direction="row" flex={1} py={1.5} px={2.5}>
              <Typography
                textAlign="center"
                fontWeight={500}
                sx={{ width: '100%' }}
              >
                打字成绩
              </Typography>
            </Stack>
            <Stack
              direction="row"
              flex={1}
              spacing={1}
              py={1.5}
              px={2.5}
              sx={{
                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
              }}
            >
              <SpeedIcon htmlColor="#FFC107"></SpeedIcon>
              <Typography>速度：{typingInfo?.speed} 字/分钟</Typography>
            </Stack>
            <Stack
              direction="row"
              flex={1}
              spacing={1}
              py={1.5}
              px={2.5}
              sx={{
                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
              }}
            >
              <DoneIcon color="primary"></DoneIcon>
              <Typography>正确率：{typingInfo?.accuracyRate} %</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button onClick={handleQuit}>{canRetry ? '再测一次' : '好的'}</Button>
      </Box>
    </Dialog>
  );
};

export default Finish;
