import * as React from 'react';
import { dayjs } from '@avocadoui/utils';

function diff(timestamp: number) {
  const d = dayjs(new Date()).diff(dayjs(timestamp), 'second');
  const h = Math.floor((d / 60 / 60) % 60)
    .toString()
    .padStart(2, '0');
  const m = Math.floor((d / 60) % 60)
    .toString()
    .padStart(2, '0');
  const s = (d % 60).toString().padStart(2, '0');
  return [h, m, s];
}

interface IProps {
  timestamp: number;
}

function Timer({ timestamp }: IProps) {
  const [timer, setTimer] = React.useState(diff(timestamp));
  const [h, m, s] = timer;

  React.useEffect(() => {
    const id = setInterval(() => {
      setTimer(diff(timestamp));
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [timestamp]);

  const style = { display: 'inline-block', width: '17px' };

  return (
    <span>
      {h !== '00' && (
        <span>
          <span style={style}>{h}</span>:
        </span>
      )}
      <span style={style}>{m}</span>
      <span>:</span>
      <span style={style}>{s}</span>
    </span>
  );
}

export default Timer;
