import { Box, Typography } from 'fbm-ui';
import { Stack, styled } from '@mui/material';
import { useRoomState } from './useMachineContext';
import { useAppSelector } from '../../redux/hooks';
import { isInterviewer } from '../../utils/localStorage';

function StatusLayout({ children }: { children: React.ReactNode }) {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {children}
    </Stack>
  );
}

function getColor(color: string) {
  switch (color) {
    case 'red':
      return '#EF5350';
    case 'grey':
      return 'rgba(0,0,0,0.26)';
    default:
      return 'rgba(0,0,0,0.26)';
  }
}

const StatusDot = styled(Box)(
  ({
    color,
    size = '10px',
    animate = false,
  }: {
    color: string;
    size?: string;
    animate?: boolean;
  }) => {
    const animateStyle = animate
      ? {
          animation: 'blinker 1s linear infinite', // thx https://stackoverflow.com/questions/16344354/how-to-make-blinking-flashing-text-with-css-3
          '@keyframes blinker': {
            '50%': {
              opacity: 0,
            },
          },
        }
      : {};

    return {
      width: size,
      height: size,
      borderRadius: '50%',
      backgroundColor: getColor(color),
      ...animateStyle,
    };
  }
);

function StatusText({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="body2" color="secondary">
      {children}
    </Typography>
  );
}

function RecordStatus() {
  const state = useRoomState();
  const hasPublished = ['ing.initial', 'ing.published'].some(state.matches);
  const isRecordRoomType = useAppSelector(
    (state) => state.room.roomInfo.roomType === 1
  );
  const channelStatus = useAppSelector(
    (state) => state.room.roomInfo.channelStatus
  );

  const isInitial = channelStatus === 0;
  const isIng = [1, 3].includes(channelStatus);
  const isPaused = channelStatus === 2;

  if (hasPublished && isRecordRoomType && isInterviewer()) {
    if (isInitial) {
      return (
        <StatusLayout>
          <StatusDot color="grey" />
          <StatusText>未录制</StatusText>
        </StatusLayout>
      );
    }

    if (isIng) {
      return (
        <StatusLayout>
          <StatusDot color="red" animate />
          <StatusText>录制中</StatusText>
        </StatusLayout>
      );
    }

    if (isPaused) {
      return (
        <StatusLayout>
          <StatusDot color="grey" />
          <StatusText>录制暂停</StatusText>
        </StatusLayout>
      );
    }
  }

  return null;
}

export default RecordStatus;
