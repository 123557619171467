import * as React from 'react';
import useRouterQuery from '../../hooks/useRouterQuery';
import { useRoomSend } from './useMachineContext';
import useQueryCurrentUserInfo from './useQueryCurrentUserInfo';

function useAuthTokenFromUrl() {
  const send = useRoomSend();
  const { refetch: refetchCurrentUserInfo } = useQueryCurrentUserInfo();

  const query = useRouterQuery();
  const authToken = query.get('authToken');

  React.useEffect(() => {
    if (authToken) {
      localStorage.setItem('id_token', authToken);
      localStorage.setItem('user_type', '1'); // 设为面试官
      refetchCurrentUserInfo();
      send('NEXT');
    }
  }, [authToken, send, refetchCurrentUserInfo]);
}

export default useAuthTokenFromUrl;
