import * as React from 'react';
import { useAppDispatch } from '../../redux/hooks';
import RoomModel from './RoomModel';
import { roomActions } from './slice';
import useRoom from './useRoom';

function useEffectPlayIngUsers() {
  const { ingUserIds } = useRoom();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    ingUserIds.forEach((id) => {
      const user = RoomModel.rtcClient.remoteUsers.find(
        (u) => u.uid.toString() === id
      );
      if (user) {
        const player = document.getElementById(`remote-player-${id}`);
        if (player) {
          user.videoTrack?.play(player, { fit: 'contain' });
          user.audioTrack?.play();
        }
      }
    });
  }, [ingUserIds]);

  React.useEffect(() => {
    if (ingUserIds.length === 1) {
      const id = ingUserIds[0];
      RoomModel.rtcClient.setRemoteVideoStreamType(+id, 0);
      dispatch(roomActions.setFocusedUserId(''));
    }
  }, [ingUserIds, dispatch]);

  return null;
}

export default useEffectPlayIngUsers;
