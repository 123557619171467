import * as React from 'react';
import RoomAPI from '../../api/room';
import { useAppDispatch } from '../../redux/hooks';
import { roomActions } from './slice';
import useRoomToken from './useRoomToken';

const useEffectFetchPositionInfoForCandidate = () => {
  const dispatch = useAppDispatch();
  const roomToken = useRoomToken();

  React.useEffect(() => {
    async function init() {
      const result = await RoomAPI.positionInfoForCandidate(roomToken);
      if (result.code === 0) {
        dispatch(roomActions.setPositionInfoForCandidate(result.data));
      }
    }

    init();
  }, [dispatch, roomToken]);

  return null;
};

export default useEffectFetchPositionInfoForCandidate;
