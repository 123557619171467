import * as React from 'react';
import useRoomToken from './useRoomToken';
import AccountAPI from '../../api/account';
import RoomModel from './RoomModel';
import { useAppDispatch } from '../../redux/hooks';
import { accountActions } from '../Account/slice';
import { log } from '@avocadoui/utils';

function useCallbackRenewAgoraToken() {
  const roomToken = useRoomToken();
  const dispatch = useAppDispatch();
  return React.useCallback(
    async (from: string) => {
      try {
        const res = await AccountAPI.refreshAgoraToken(roomToken);
        if (res.code === 0) {
          const { agoraRTCToken, agoraRTMToken } = res.data;
          dispatch(accountActions.renewAccount(res.data));
          await RoomModel.rtcClient.renewToken(agoraRTCToken);
          await RoomModel.rtmClient.renewToken(agoraRTMToken);
          console.log('renew agora token success');
          log.capture(`SUCCESS:更新声网 ${from} TOKEN`);
        }
      } catch (error) {
        console.log('renew agora token failed:', error);
        log.capture(`FAIL:更新声网 ${from} TOKEN`, error);
      }
    },
    [roomToken, dispatch]
  );
}

export default useCallbackRenewAgoraToken;
