import { toast } from '@avocadoui/components';

export function formatDeviceLabel(label: string, isDefault: boolean): string {
  if (isDefault) {
    return `${
      sessionStorage.getItem('lang') === 'en'
        ? 'Consistent with the system'
        : '与系统一致'
    } （${label}）`;
  }
  return label;
}

export function attachSinkId(element: any, sinkId: string) {
  if (typeof element.sinkId !== 'undefined') {
    element.setSinkId(sinkId).catch((error: any) => {
      console.error('attachSinkId:', error);
    });
  } else {
    toast.error('当前浏览器不支持切换扬声器');
  }
}

interface IStopReturn {
  audioBlob: Blob;
  audioUrl: string;
  play: () => void;
}

interface IRecordReturn {
  start: () => void;
  stop: () => Promise<IStopReturn>;
}

export const recordAudio = (deviceId?: string) => {
  return new Promise((resolve: (value: IRecordReturn) => void) => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks: Blob[] = [];

      mediaRecorder.addEventListener('dataavailable', (event) => {
        audioChunks.push(event.data);
      });

      const start = () => {
        mediaRecorder.start();
      };

      const stop = () => {
        return new Promise((resolve: (value: IStopReturn) => void) => {
          mediaRecorder.addEventListener('stop', () => {
            const audioBlob = new Blob(audioChunks);
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            if (deviceId) attachSinkId(audio, deviceId);
            const play = () => {
              audio.play();
            };

            resolve({ audioBlob, audioUrl, play });
          });

          mediaRecorder.stop();
        });
      };

      resolve({ start, stop });
    });
  });
};
