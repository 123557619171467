import React, { useMemo, useCallback } from 'react';
import { Message, Box } from 'fbm-ui';
import { AliRTSPusher } from 'aliyun-rts-pusher';
import { forwardRef, useEffect, useImperativeHandle } from 'react';

type PusherProps = {
  liveUrl?: string;
  onPusherError?: (err: any) => void;
};

const Pusher = forwardRef(
  ({ liveUrl = '', onPusherError }: PusherProps, ref) => {
    // 创建推流实例
    const pushClient = useMemo(() => AliRTSPusher.createClient(), []);

    const handlePusherError = useCallback(
      (err) => {
        if ([10000, 10002, 10004, 10008, 10006].includes(err.errorCode)) {
          Message.error('测试过程中必须开启摄像头');
          onPusherError?.(err);
        } else {
          Message.error(err.message);
        }
        console.log(err);
      },
      [onPusherError]
    );
    // 监听错误事件
    pushClient.on('error', handlePusherError);

    useEffect(() => {
      if (!liveUrl) return;
      pushClient.setVideoQuality('720p_1');
      const videoEl = pushClient.setRenderView(
        'videoContainer'
      ) as HTMLVideoElement;
      videoEl.controls = false;
      pushClient.startCamera().then(console.log).catch(handlePusherError);
    }, [handlePusherError, liveUrl, pushClient]);

    useImperativeHandle(ref, () => ({
      stopPush: () => {
        pushClient.stopCamera();
        pushClient.stopPush();
      },
      startPush: () => {
        pushClient
          .startPush(liveUrl)
          .then(console.log)
          .catch(handlePusherError);
      },
    }));

    return <Box id="videoContainer" sx={{ width: 288, height: 164 }}></Box>;
  }
);

export default Pusher;
