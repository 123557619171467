// source: https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
import config from '../config';
import { OEM_NAME } from '../oem';
const localStorageKey = 'id_token';

interface IOptions extends Omit<RequestInit, 'body'> {
  body?: any;
}

const { API_URL } = config;

function client(endpoint: RequestInfo, options?: IOptions, isUpload?: boolean) {
  const { body, ...customConfig } = options ?? {};
  const headers: HeadersInit = {
    'content-type': isUpload ? 'multipart/form-data' : 'application/json',
    'Accept-Language': sessionStorage.getItem('lang') || 'zh-CN',
  };

  const token = localStorage.getItem(localStorageKey);
  if (token) {
    headers.Authorization = `Basic ${token}`;
  }

  const config: RequestInit = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      oem: OEM_NAME,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = isUpload ? body : JSON.stringify(body);
  }
  if (isUpload) {
    delete config.headers['content-type'];
  }

  return fetch(`${API_URL}/${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {
      localStorage.removeItem(localStorageKey);
      window.location.assign(window.location.toString());
      return;
    }
    if (response.ok) {
      return await response.json();
    } else {
      const errorMessage = await response.text();
      return Promise.reject(new Error(errorMessage));
    }
  });
}

export default client;
