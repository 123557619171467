import { useLottie } from 'lottie-react';
import activatedAnimation from './Animation/activated.json';
import takingAnimation from './Animation/talking.json';
import thinkingAnimation from './Animation/thinking.json';
import { FC } from 'react';

const ACTIONS = {
  activated: activatedAnimation,
  taking: takingAnimation,
  thinking: thinkingAnimation,
};
const style = {
  width: '200px',
  height: '200px',
};

export interface ActionProps {
  action: 'activated' | 'taking' | 'thinking' | 'idle';
}

const Action: FC<ActionProps> = ({ action = 'idle' }) => {
  const options = {
    animationData: ACTIONS[action],
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return View;
};

export default Action;
