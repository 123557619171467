import * as React from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';

import { useAppDispatch } from '../../redux/hooks';
import { roomActions } from './slice';
import RoomModel from './RoomModel';
// import { filterDefaultDeviceId } from '../../utils/webrtc';

const useEffectChangedDevice = () => {
  const dispatch = useAppDispatch();

  const handleCameraChanged = React.useCallback(
    async (changedDevice) => {
      const camerasInfo = await AgoraRTC.getCameras();
      dispatch(roomActions.setCamerasInfo(camerasInfo));

      // 插入设备时，切换到新插入的设备。
      if (changedDevice.state === 'ACTIVE') {
        const deviceId = changedDevice.device.deviceId;
        dispatch(roomActions.setSelectedCameraId(deviceId));
        RoomModel.localTracks.videoTrack?.setDevice(deviceId);
        // 拔出设备为当前设备时，切换到一个已有的设备。
      } else if (
        changedDevice.device.label ===
        RoomModel.localTracks.videoTrack?.getTrackLabel()
      ) {
        // const filteredInfo = camerasInfo.filter(filterDefaultDeviceId);
        if (camerasInfo[0]) {
          const deviceId = camerasInfo[0].deviceId;
          dispatch(roomActions.setSelectedCameraId(deviceId));
          RoomModel.localTracks.videoTrack?.setDevice(deviceId);
        }
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    AgoraRTC.onCameraChanged = handleCameraChanged;
  }, [handleCameraChanged]);

  const handleMicrophoneChanged = React.useCallback(
    async (changedDevice) => {
      const micsInfo = await AgoraRTC.getMicrophones();
      dispatch(roomActions.setMicsInfo(micsInfo));

      if (changedDevice.state === 'ACTIVE') {
        const deviceId = changedDevice.device.deviceId;
        dispatch(roomActions.setSelectedMicId(deviceId));
        RoomModel.localTracks.audioTrack?.setDevice(deviceId);
      } else if (
        changedDevice.device.label ===
        RoomModel.localTracks.audioTrack?.getTrackLabel()
      ) {
        // const filteredInfo = micsInfo.filter(filterDefaultDeviceId);
        if (micsInfo[0]) {
          const deviceId = micsInfo[0].deviceId;
          dispatch(roomActions.setSelectedMicId(deviceId));
          RoomModel.localTracks.audioTrack?.setDevice(deviceId);
        }
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    AgoraRTC.onMicrophoneChanged = handleMicrophoneChanged;
  }, [handleMicrophoneChanged]);

  const handleSpeackerChanged = React.useCallback(
    async (changedDevice) => {
      const speackersInfo = await AgoraRTC.getPlaybackDevices();
      dispatch(roomActions.setSpeakersInfo(speackersInfo));

      if (changedDevice.state === 'ACTIVE') {
        const deviceId = changedDevice.device.deviceId;
        dispatch(roomActions.setSelectedSpeakerId(deviceId));
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    AgoraRTC.onPlaybackDeviceChanged = handleSpeackerChanged;
  }, [handleSpeackerChanged]);
};

export default useEffectChangedDevice;
