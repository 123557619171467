import RoomLayout from '../modules/Room/RoomLayout';
import RoomLogin from '../modules/Room/RoomLogin';
import RoomTest from '../modules/Room/RoomTest';
import RoomWaiting from '../modules/Room/RoomWaiting';
import RoomPublished from '../modules/Room/RoomPublished';
import RoomLeft from '../modules/Room/RoomLeft';
import { useAppSelector } from '../redux/hooks';
import {
  RoomContextProvider,
  useRoomState,
} from '../modules/Room/useMachineContext';
import useQueryPositionInfo from '../modules/Room/useQueryPositionInfo';

function Room() {
  const state = useRoomState();
  // console.log('state:', state);

  const accountRoomStatus = useAppSelector((state) => state.account.roomStatus);
  const { data: positionInfoData = {} } = useQueryPositionInfo();
  const roomStatus = accountRoomStatus || positionInfoData?.status;
  const { positionName } = positionInfoData;

  const isCheckAuth = ['before.checkAuth'].some(state.matches);
  const isTesting = ['before.getPermission'].some(state.matches);
  const isWaiting = ['before.waitInvitation'].some(state.matches);
  const hasPublished = ['ing.initial', 'ing.published'].some(state.matches);
  const hasLeft = ['after.initial'].some(state.matches);

  if (roomStatus === 0 || !positionName) {
    return null;
  }
  if (roomStatus > 1) {
    return <RoomLeft />;
  }

  if (isCheckAuth) {
    return <RoomLogin />;
  }
  if (isTesting) {
    return <RoomTest />;
  }
  if (isWaiting) {
    return <RoomWaiting />;
  }
  if (hasPublished) {
    return <RoomPublished />;
  }
  if (hasLeft) {
    return <RoomLeft />;
  }
  return null;
}

function RoomPage() {
  return (
    <RoomContextProvider>
      <RoomLayout>
        <Room />
      </RoomLayout>
    </RoomContextProvider>
  );
}

export default RoomPage;
