import { useQuery } from 'react-query';
import RoomAPI from '../../api/room';
import useRoomToken from './useRoomToken';
import { useAppDispatch } from '../../redux/hooks';
import { roomActions } from './slice';

function useQueryPositionInfo() {
  const roomToken = useRoomToken();
  const dispatch = useAppDispatch();
  return useQuery(
    ['positionInfo', roomToken],
    async () => {
      const result = await RoomAPI.positionInfo(roomToken);
      dispatch(roomActions.setPositionInfo(result.data));
      return result.data;
    },
    { refetchOnWindowFocus: false, refetchOnMount: true }
  );
}

export default useQueryPositionInfo;
