import * as React from 'react';

export default function useLatestRef<T>(value: T): { readonly current: T } {
  const ref = React.useRef(value);

  React.useLayoutEffect(() => {
    ref.current = value;
  });

  return ref;
}
