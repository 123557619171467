import client from './InterviewClient';
import { apiCheck } from '../utils/apiCheck';

function getPrepareInfo(token) {
  return client(`aiInterview/precheck`, {
    body: {},
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function getInterviewInfo(token) {
  return client(`aiInterview/info`, {
    body: {},
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function getProgress(token) {
  return client(`aiInterview/progress`, {
    body: {},
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function getStepInfo(token, data) {
  return client(`aiInterview/stepInfo`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function sync(token, data) {
  return client(`aiInterview/sync`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function verifyAnswer(token, data) {
  return client(`aiInterview/verify`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function followup(token, data) {
  return client(`aiInterview/stepInfo/followup`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function cheat(token, data) {
  return client(`aiInterview/cheat`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function broadcastVideo(token, data) {
  return client(`aiInterview/broadcastVideo/broadcast`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function setStage(token, data) {
  return client(`aiInterview/broadcastVideo/setStage`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

function getPluginOpenPId(token, data) {
  return client(`aiInterview/userInfo/getOpenId`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

// 获取AI面试结果
function getAiReportResult(token, data) {
  return client(`aiInterview/result`, {
    body: data,
    headers: {
      'x-interview-scence': `${token}`,
    },
  }).then(apiCheck);
}

const InerviewAPI = {
  getPrepareInfo,
  getInterviewInfo,
  getProgress,
  getStepInfo,
  sync,
  verifyAnswer,
  followup,
  cheat,
  broadcastVideo,
  setStage,
  getPluginOpenPId,
  getAiReportResult,
};

export default InerviewAPI;
