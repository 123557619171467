import { Box, Stack, Text } from '@avocadoui/components';
import LoginForm from '../LoginForm/LoginForm';
import useQueryPositionInfo from './useQueryPositionInfo';
import useAuthTokenFromUrl from './useAuthTokenFromUrl';

function RoomLogin() {
  const { data: positionInfoData } = useQueryPositionInfo();
  const { positionName, enterpriseName } = positionInfoData || {};

  useAuthTokenFromUrl();

  return (
    <Stack
      spacing={4}
      flex={1}
      alignItems="center"
      sx={{ backgroundColor: '#F9FAF9' }}
    >
      <Text mt="10%" px={2} variant="h5" color="text.primary">
        Hi，欢迎参加{enterpriseName}-{positionName}的视频沟通
      </Text>
      <Box width="360px">
        <LoginForm />
      </Box>
    </Stack>
  );
}

export default RoomLogin;
