import * as React from 'react';
import { useAppSelector } from '../../redux/hooks';
import useConnectRoom from './useConnectRoom';

function useEffectConnectedRoom() {
  const connectRoom = useConnectRoom();
  const connectedRTCCounts = useAppSelector(
    (state) => state.room.connectedRTCCounts
  );

  React.useEffect(() => {
    if (connectedRTCCounts > 0) {
      connectRoom();
    }
  }, [connectedRTCCounts, connectRoom]);

  return null;
}

export default useEffectConnectedRoom;
