import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  UID,
} from 'agora-rtc-sdk-ng';
import AgoraRTM, {
  LocalInvitation,
  RtmChannel,
  RtmClient,
} from 'agora-rtm-sdk';
import VirtualBackgroundExtension, {
  IVirtualBackgroundProcessor,
} from 'agora-extension-virtual-background';

import { IRemoteUsers, IRoomUser } from './slice';
import config from '../../config';
import { AntiVideoExtension } from '../../extensions/anti-cheat';
import { isChrome, log } from '@avocadoui/utils';
import { Message } from 'fbm-ui';
import { isInterviewee } from '../../utils/localStorage';

export interface ILocalTracks {
  videoTrack?: ICameraVideoTrack | null;
  audioTrack?: IMicrophoneAudioTrack | null;
}

export interface IRTMLocalInvitations {
  [key: string]: LocalInvitation;
}

export interface IAllUsers {
  [key: string]: IRoomUser;
}

AgoraRTC.enableLogUpload();
AgoraRTC.setLogLevel(1);

const vbExt = new VirtualBackgroundExtension();
const antiExt = new AntiVideoExtension();
AgoraRTC.registerExtensions([vbExt, antiExt]);

const appId = config.AGORA_APP_ID || '';

class RoomModel {
  static vbProcessor: IVirtualBackgroundProcessor;
  static async getVbProcessor() {
    if (!RoomModel.vbProcessor && RoomModel.localTracks.videoTrack) {
      RoomModel.vbProcessor = vbExt.createProcessor();
      try {
        await RoomModel.vbProcessor.init('/wasms-1.0.0-beta-3');
        console.log('vbProcessor init success');
      } catch (e) {
        console.log('init vbProcessor failed', e);
        return null;
      }
      // 将 VirtualBackgroundExtension 插件注入到 SDK 的媒体处理管道中
      RoomModel.localTracks.videoTrack
        .pipe(RoomModel.vbProcessor)
        .pipe(RoomModel.localTracks.videoTrack.processorDestination);
    }
    return RoomModel.vbProcessor;
  }
  static async enableBlurVbProcessor() {
    const processor = await RoomModel.getVbProcessor();
    if (!processor) return;
    try {
      processor.setOptions({ type: 'blur', blurDegree: 2 });
      await processor.enable();
      processor.onoverload = () => {
        log.capture('FAIL:虚拟背景性能过载', { vbType: 'blur' });
      };
      log.capture('SUCCESS:开启虚拟背景', { vbType: 'blur' });
    } catch (e) {
      log.capture('FAIL:开启虚拟背景', { vbType: 'blur' });
    }
  }
  static async enableVbProcessor(src) {
    const processor = await RoomModel.getVbProcessor();
    if (!processor || !src) return;

    try {
      const source = document.createElement('img');
      source.onload = async () => {
        processor.setOptions({ type: 'img', source });
        await processor.enable();
        processor.onoverload = () => {
          log.capture('FAIL:虚拟背景性能过载', {
            vbType: 'img',
            vbUrl: src,
          });
        };
        log.capture('SUCCESS:开启虚拟背景', { vbType: 'img', vbUrl: src });
      };
      source.onerror = (error) => {
        console.log('load img error:', error);
        Message.error('图片暂时不可用，请稍后重试');
      };
      source.crossOrigin = 'anonymous';
      source.src = src;
    } catch (e) {
      log.capture('FAIL:开启虚拟背景', { vbType: 'img', vbUrl: src });
    }
  }
  static async disableVbProcessor() {
    const processor = await RoomModel.getVbProcessor();
    if (!processor) return;
    try {
      await processor.disable();
    } catch (e) {
      log.capture('FAIL:关闭虚拟背景');
    }
  }

  static antiProcessor: any;
  static async getAntiProcessor() {
    if (
      !RoomModel.antiProcessor &&
      RoomModel.localTracks.videoTrack &&
      isInterviewee()
    ) {
      try {
        RoomModel.antiProcessor = antiExt.createProcessor();
        console.log('antiProcessor init success');
      } catch (e) {
        console.log('init antiProcessor failed', e);
        return null;
      }
      // 将 AntiVideoExtension 插件注入到 SDK 的媒体处理管道中
      RoomModel.localTracks.videoTrack.unpipe();
      RoomModel.localTracks.videoTrack
        .pipe(RoomModel.antiProcessor)
        .pipe(RoomModel.vbProcessor)
        .pipe(RoomModel.localTracks.videoTrack.processorDestination);
    }
    return RoomModel.antiProcessor;
  }
  static async enableAntiProcessor(key, data = {}) {
    const processor = await RoomModel.getAntiProcessor();
    if (!processor) return;
    const authorization = localStorage.getItem('id_token');
    processor.startCheat({
      mateData: data,
      cheatKey: key,
      authorization,
    });
  }

  static rtcClient: IAgoraRTCClient;
  static async createRtcClient() {
    RoomModel.rtcClient = AgoraRTC.createClient({
      mode: 'live',
      codec: 'h264',
      role: 'host',
    });
    RoomModel.rtcClient.enableAudioVolumeIndicator();

    if (isChrome()) {
      // 开启大小流
      await RoomModel.rtcClient.enableDualStream();
      // 设置大小流配置
      RoomModel.rtcClient.setLowStreamParameter({
        width: 320,
        height: 180,
        framerate: 10,
        bitrate: 140,
      });
    }
  }

  static rtmClient: RtmClient;
  static createRtmClient() {
    RoomModel.rtmClient = AgoraRTM.createInstance(appId, {
      enableLogUpload: true,
    });
  }
  static rtmChannel: RtmChannel;
  static createRtmChannel(name: string) {
    RoomModel.rtmChannel = RoomModel.rtmClient.createChannel(name);
  }

  static localTracks: ILocalTracks = {};
  static playbackDeviceId: string;

  static remoteUsers: IRemoteUsers = {};
  static setRemoteUser(id: UID, user: IAgoraRTCRemoteUser) {
    RoomModel.remoteUsers[id] = user;
  }
  static removeRemoteUser(id: UID) {
    delete RoomModel.remoteUsers[id];
  }
  static clearRemoteUsers() {
    RoomModel.remoteUsers = {};
  }

  static rtmLocalInvitations: IRTMLocalInvitations = {};
  static setRTMLocalInvitations(id: string, invitation: LocalInvitation) {
    RoomModel.rtmLocalInvitations[id] = invitation;
  }
  static removeRTMLocalInvitations(id: string) {
    delete RoomModel.rtmLocalInvitations[id];
  }

  static allUsers: IAllUsers = {};
  static audioVolumeLevelIsGood = false;
}

export default RoomModel;
