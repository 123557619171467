import { Stack } from '@avocadoui/components';
import useEffectPlayLocalUser from './useEffectPlayLocalUser';
import LocalPlayerContainer from './LocalPlayerContainer';

function LocalPlayerContainerInGridMode() {
  useEffectPlayLocalUser();

  return (
    <Stack justifyContent="center" alignItems="center">
      <LocalPlayerContainer />
    </Stack>
  );
}

export default LocalPlayerContainerInGridMode;
