import { Paper } from '@mui/material';
import { Stack, Box } from 'fbm-ui/lib/mui';
import { FC, ReactNode } from 'react';
import { StyledVideo } from '../styles';
import { Typography } from 'fbm-ui';

export interface SingleChoiceProps {
  extra: {
    imgUrl: string;
    digit: string;
  };
  selected?: string;
  title: ReactNode;
  pusher?: ReactNode;
  okButon?: ReactNode;
}

const ColorBlindness: FC<SingleChoiceProps> = ({
  selected,
  title,
  pusher,
  okButon,
  extra,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      height={1}
      sx={{
        background: '#F9FAF9',
      }}
    >
      <Stack
        spacing={4}
        p={2}
        component={Paper}
        sx={{ position: 'relative', borderRadius: 2 }}
      >
        <Stack>{title}</Stack>
        <Stack spacing={2} alignItems="center">
          <img
            src={extra.imgUrl}
            alt=""
            style={{
              width: 138,
              height: 138,
              objectFit: 'cover',
            }}
          />
          <Stack direction="row" spacing={2} justifyContent="center">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => (
              <Box
                key={ele}
                sx={{
                  cursor: 'pointer',
                  width: 48,
                  height: 48,
                  background: ' #475A60',
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 28,
                  color: '#fff',
                  fontWeight: 500,
                  lineHeight: 1.5,
                }}
              >
                {ele}
              </Box>
            ))}
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h6">请点击数字</Typography>
            <Typography variant="h6">请点击数字</Typography>
            <Typography variant="h6">请点击数字</Typography>
            <Typography variant="h6">请点击数字</Typography>
          </Stack>
          <Typography variant="h6">{extra.digit}</Typography>
        </Stack>
        {okButon}
      </Stack>
      <Stack
        component={Paper}
        sx={{
          position: 'relative',
          borderRadius: 2,
          width: 325,
          flexShrink: 0,
          height: 1,
          backgroundSize: '100% auto',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            "url('//webcdn.fbmms.cn/assets/ytGX/sRsajH3OEGH1NrzqjSW3n.png')",
          aspectRatio: '16/9',
        }}
      >
        <StyledVideo size="small">{pusher}</StyledVideo>
      </Stack>
    </Stack>
  );
};

export default ColorBlindness;
