import { Box, Button, Typography } from 'fbm-ui';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { interviewActions } from './slice';
import { useTranslation } from 'react-i18next';

const Mask: React.FC<{ countdownPreRef: MutableRefObject<number> }> = ({
  countdownPreRef,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { prepareTime = 3 } = useAppSelector(
    (state) => state.interview.stepInfo
  );

  const [countdown, setCountdown] = useState(prepareTime);

  const handleOk = useCallback(() => {
    dispatch(interviewActions.nextStep());
  }, [dispatch]);

  useEffect(() => {
    if (!prepareTime) return;

    setCountdown(prepareTime);

    const timer = setInterval(() => {
      setCountdown((timeLeft) => {
        if (timeLeft === 1) {
          dispatch(interviewActions.nextStep());
          return 0;
        } else {
          countdownPreRef.current = countdownPreRef.current + 1;
          return timeLeft - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [dispatch, prepareTime, countdownPreRef]);

  return (
    <Box
      width={1}
      height="calc(100vh - 60px)"
      bgcolor="rgba(0, 0, 0, 0.56)"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      top={0}
      sx={{ position: 'absolute', zIndex: 10, paddingBottom: 10 }}
    >
      <Box
        sx={{ flex: 1 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" color="white">
          {t('Preparation time')}
        </Typography>
        <Typography variant="h4" color="white" mb={4}>
          {countdown}s
        </Typography>
      </Box>
      <Button onClick={handleOk} sx={{ position: 'absolute', top: 600 }}>
        {t("I'm Ready")}
      </Button>
    </Box>
  );
};

export default Mask;
