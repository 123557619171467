export function textToLines(text) {
  const lines = [];
  try {
    let line = '';
    const regex = new RegExp('(?<=[，。！？；])', 'g');
    text.split(regex).forEach((sentence) => {
      // 保留原始标点
      sentence = sentence.trim();
      if (!sentence) return;

      if (sentence.length > 40) {
        // 此句超过40个字符,则需要分行
        while (sentence.length > 40) {
          lines.push(line + sentence.substring(0, 40));
          line = '';
          sentence = sentence.substring(40);
        }
      }

      // 这是一个长度在1-40之间的句子
      if (line.length + sentence.length < 20) {
        // 不足10个字符,将本句加入当前的 line
        line += sentence;
      } else if (line.length + sentence.length <= 40) {
        // 字符数量在10到40之间，添加到当前行并将行推到 lines
        lines.push(line + sentence);
        line = '';
      } else {
        // 加入后超过40个字符，因此新开一行
        lines.push(line);
        line = sentence;
      }
    });

    // 最后一行可能不满40个字符， 但是需要独立成行
    if (line) {
      lines.push(line);
    }
  } catch (e) {
    console.log(e);
  }

  return lines;
}
