import { useEffectOnce } from 'usehooks-ts';

import { useAppDispatch } from '../../redux/hooks';
import { roomActions } from './slice';

const useEffectSetJoinTimestamp = () => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(roomActions.setJoinedTimestamp(Date.now()));

    return () => {
      dispatch(roomActions.setJoinedTimestamp(0));
    };
  });

  return null;
};

export default useEffectSetJoinTimestamp;
