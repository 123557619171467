import client from './client';

function positionInfo(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/positionInfo`);
}

function positionInfoForCandidate(roomToken: string) {
  return client(`rtcInterview/${roomToken}/positionInfo`);
}

function roomUsersForInterviewer(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/interviewer/users`);
}

function roomUsersForInterviewee(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/interviewee/users`);
}

function connectInterviewee(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/connect`);
}

function connectInterviewer(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/connectInterviewer`);
}

function destroyRoom(roomToken: string) {
  return client(`rtcInterview/${roomToken}/close`);
}

function updateVideoLayout(roomToken: string, agoraUid: string) {
  return client(`rtcInterview/${roomToken}/updateVideoLayout`, {
    body: {
      agoraUid,
    },
  });
}

function joinRTMInterviewee(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/interviewee/joinRTM`);
}

function onlinePingInterviewee(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/interviewee/onlinePing`);
}

function onlinePingInterviewer(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/interviewer/onlinePing`);
}

function setRoomModel(roomToken: string, model: 1 | 2) {
  return client(`rtcInterview/room/${roomToken}/interviewer/setRoomModel`, {
    body: {
      model,
    },
  });
}

function getOnlineIntervieweeNum(roomToken: string) {
  return client(`rtcInterview/${roomToken}/getOnlineCandidateNums`);
}

function getRoomInfo(roomToken: string) {
  return client(`rtcInterview/room/${roomToken}/info`);
}

function startSingleRecord(roomToken: string, type: 1 | 2) {
  return client(`rtcInterview/room/${roomToken}/startRecord`, {
    body: {
      type,
    },
  });
}

function stopSingleRecord(roomToken: string, type: 1 | 2) {
  return client(`rtcInterview/room/${roomToken}/stopRecord`, {
    body: {
      type,
    },
  });
}

function kickRtcUser(roomToken: string, agoraUid: string) {
  return client(`rtcInterview/room/${roomToken}/kickOut`, {
    body: {
      agoraUid,
    },
  });
}

const RoomAPI = {
  positionInfo,
  positionInfoForCandidate,
  roomUsersForInterviewer,
  roomUsersForInterviewee,
  connectInterviewee,
  connectInterviewer,
  destroyRoom,
  updateVideoLayout,
  joinRTMInterviewee,
  onlinePingInterviewee,
  onlinePingInterviewer,
  setRoomModel,
  getOnlineIntervieweeNum,
  getRoomInfo,
  startSingleRecord,
  stopSingleRecord,
  kickRtcUser,
};

export default RoomAPI;
