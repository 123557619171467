import { ArrowDropRightIcon, PublicIcon } from 'fbm-icons';
import { Box, Typography, Popactions } from 'fbm-ui';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LanguageLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const navigateTo = useCallback(
    (lang) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('lang', lang);
      navigate(
        { pathname: location.pathname, search: searchParams.toString() },
        { replace: true }
      );
    },
    [navigate, location]
  );

  const queryParams = new URLSearchParams(window.location.search);
  const lang =
    queryParams.get('lang') || sessionStorage.getItem('lang') || 'zh';
  const languages = [
    {
      text: '简体中文',
      value: 'zh',
      onClick: (e) => {
        console.log('e', e);
        navigateTo('zh');
        setOpen(false);
      },
    },
    {
      text: 'English',
      value: 'en',
      onClick: (e) => {
        navigateTo('en');
        setOpen(false);
      },
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          position: 'fixed',
          backgroundColor: 'transparent',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px 24px',
        }}
      >
        <Popactions
          actions={languages}
          trigger="click"
          open={open}
          // @ts-ignore
          sx={{
            width: 141,
          }}
        >
          <Box
            sx={{
              height: 36,
              width: 141,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(!open)}
          >
            <PublicIcon />
            <Typography variant="body2" sx={{ width: 56 }}>
              {languages.find((item) => item.value === lang)?.text ||
                '简体中文'}
            </Typography>
            <ArrowDropRightIcon />
          </Box>
        </Popactions>
      </Box>
      {children}
    </Box>
  );
};

export default LanguageLayout;
