import { Box } from '@avocadoui/components';
import { styled } from '@mui/system';
import { useAppSelector } from '../../redux/hooks';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const Layout = styled(Box)(() => ({
  backgroundColor: 'white',
  border: '1px solid rgba(0, 0, 0, 0.08)',
  borderRadius: '4px',
}));

function LoginForm() {
  const { step } = useAppSelector(({ login }) => login);

  return (
    <Layout>
      {step === 0 && <StepOne />}
      {step === 1 && <StepTwo />}
    </Layout>
  );
}

export default LoginForm;
