import * as React from 'react';
import useRoom from './useRoom';

interface IProps {
  playerContainerWidth: number;
  playerContainerHeight: number;
}

interface IResize extends IProps {
  userIds: string[];
}

const GAP_WIDTH = 8;
const ratio = [9, 16];

function resize({
  userIds,
  playerContainerWidth,
  playerContainerHeight,
}: IResize) {
  const userLength = userIds.length;
  let width: number;

  if (userLength === 0) {
    width = playerContainerWidth - GAP_WIDTH;

    let height = (width * ratio[0]) / ratio[1];
    if (height > playerContainerHeight) {
      height = playerContainerHeight - GAP_WIDTH;
      width = (height * ratio[1]) / ratio[0];
    }
  } else if ([1].includes(userLength)) {
    width = playerContainerWidth / 2 - GAP_WIDTH;
  } else if ([2, 3].includes(userLength)) {
    width = playerContainerWidth / 2 - GAP_WIDTH;

    let height = (width * ratio[0]) / ratio[1];
    if (height * 2 > playerContainerHeight) {
      height = playerContainerHeight / 2 - GAP_WIDTH;
      width = (height * ratio[1]) / ratio[0];
    }
  } else if ([4, 5].includes(userLength)) {
    width = playerContainerWidth / 3 - GAP_WIDTH;

    let height = (width * ratio[0]) / ratio[1];
    if (height * 2 > playerContainerHeight) {
      height = playerContainerHeight / 2 - GAP_WIDTH;
      width = (height * ratio[1]) / ratio[0];
    }
  } else {
    width = playerContainerWidth / 3 - GAP_WIDTH;

    let height = (width * ratio[0]) / ratio[1];
    if (height * 3 > playerContainerHeight) {
      height = playerContainerHeight / 3 - GAP_WIDTH;
      width = (height * ratio[1]) / ratio[0];
    }
  }

  const height = (width * ratio[0]) / ratio[1];

  const localPlayer = document.getElementById('local-player-container');
  if (localPlayer) {
    localPlayer.style.width = `${width}px`;
    localPlayer.style.height = `${height}px`;
    if (userLength > 1) {
      localPlayer.style.margin = 'auto 0 0 auto';
    } else {
      localPlayer.style.margin = '0';
    }
  }

  userIds.forEach((userId, idx) => {
    const remotePlayerContainer = document.getElementById(
      `remote-player-container-${userId}`
    );
    if (remotePlayerContainer) {
      remotePlayerContainer.style.width = `${width}px`;
      remotePlayerContainer.style.height = `${height}px`;

      if (userLength === 1) {
        remotePlayerContainer.style.margin = '0';
      }

      if (userLength >= 2 && userLength <= 3) {
        if (idx === 0) {
          remotePlayerContainer.style.margin = 'auto auto 0 0';
        } else if (idx === 1) {
          remotePlayerContainer.style.margin = '0 0 auto auto';
        } else if (idx === 2) {
          remotePlayerContainer.style.margin = '0 auto auto 0';
        } else {
          remotePlayerContainer.style.margin = '0';
        }
      }

      if (userLength >= 4 && userLength <= 5) {
        if (idx === 0) {
          remotePlayerContainer.style.margin = 'auto 0 0 0';
        } else if (idx === 1) {
          remotePlayerContainer.style.margin = 'auto auto 0 0';
        } else if (idx === 2) {
          remotePlayerContainer.style.margin = '0 0 auto auto';
        } else if (idx === 3) {
          remotePlayerContainer.style.margin = '0 0 auto 0';
        } else if (idx === 4) {
          remotePlayerContainer.style.margin = '0 auto auto 0';
        } else {
          remotePlayerContainer.style.margin = '0';
        }
      }

      if (userLength >= 6) {
        if ([0, 8].indexOf(idx) > -1) {
          remotePlayerContainer.style.margin = 'auto 0 0 0';
        } else if ([1, 9].indexOf(idx) > -1) {
          remotePlayerContainer.style.margin = 'auto auto 0 0';
        } else if ([5, 13].indexOf(idx) > -1) {
          remotePlayerContainer.style.margin = '0 0 auto auto';
        } else if ([6, 14].indexOf(idx) > -1) {
          remotePlayerContainer.style.margin = '0 0 auto 0';
        } else if ([7, 15].indexOf(idx) > -1) {
          remotePlayerContainer.style.margin = '0 auto auto 0';
        } else {
          remotePlayerContainer.style.margin = '0';
        }
      }
    }

    const remotePlayer = document.getElementById(`remote-player-${userId}`);
    if (remotePlayer) {
      remotePlayer.style.width = `${width}px`;
      remotePlayer.style.height = `${height}px`;
    }
  });

  userIds.forEach((userId, idx) => {
    const remotePlayerContainer = document.getElementById(
      `remote-player-container-${userId}`
    );

    if (remotePlayerContainer) {
      remotePlayerContainer.style.transform = 'none';

      if (userLength === 2) {
        if (idx === 1) {
          remotePlayerContainer.style.transform = `translateX(${width / 2}px)`;
        }
      } else if (userLength === 4) {
        if (idx === 2 || idx === 3) {
          remotePlayerContainer.style.transform = `translateX(${width / 2}px)`;
        }
      } else if (userLength === 6) {
        if (idx === 5) {
          remotePlayerContainer.style.transform = `translateX(${width}px)`;
        }
      } else if (userLength === 7) {
        if (idx === 5 || idx === 6) {
          remotePlayerContainer.style.transform = `translateX(${width / 2}px)`;
        }
      }
    }
  });

  const playersContainer = document.getElementById('players-container');
  if (playersContainer) {
    if ([0, 1].includes(userLength)) {
      playersContainer.style.height = `${height}px`;
    } else if ([2, 3].includes(userLength)) {
      playersContainer.style.height = `${height * 2 + 4}px`;
    } else if ([2, 3, 4, 5].includes(userLength)) {
      playersContainer.style.height = `${height * 2 + 4}px`;
    } else {
      playersContainer.style.height = `${height * 3 + 4 * 2}px`;
    }
  }
}

const useEffectResizeVideoInGridMode = ({
  playerContainerWidth,
  playerContainerHeight,
}: IProps) => {
  const { ingUserIds } = useRoom();

  React.useEffect(() => {
    resize({
      userIds: ingUserIds,
      playerContainerWidth,
      playerContainerHeight,
    });
  }, [ingUserIds, playerContainerWidth, playerContainerHeight]);

  return null;
};

export default useEffectResizeVideoInGridMode;
