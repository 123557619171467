import * as React from 'react';
import { Button, Dialog, DoneIcon, Typography } from 'fbm-ui';
import { Divider, Stack } from '@mui/material';
import { sleep } from '@avocadoui/utils';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { roomActions } from './slice';
import SpeakerSelector from './SpeakerSelector';
import MicSelector from './MicSelector';
import MicVolumeLevel from './MicVolumeLevel';
import usePlaySound from './usePlaySound';
import { COLOR } from '../../utils/const';
import { recordAudio } from './helpers';
import { useTranslation } from 'react-i18next';

function Recorder({
  canInit,
  onNext,
}: {
  canInit: boolean;
  onNext: () => void;
}) {
  const selectedSpeakerId = useAppSelector(
    (state) => state.room.selectedSpeakerId
  );

  const hasUnmounted = React.useRef(false);
  React.useEffect(() => {
    async function init() {
      const recorder = await recordAudio(selectedSpeakerId);
      recorder?.start();
      await sleep(3000);
      onNext();
      const audio = await recorder?.stop();
      audio.play();
      await sleep(3500);
      onNext();
    }

    try {
      if (canInit) {
        init();
      }
    } catch (error) {
      console.error('recorder:', error);
    }

    return () => {
      hasUnmounted.current = true;
    };
  }, [canInit, onNext, selectedSpeakerId]);

  return null;
}

const TITLE = ['Speaker Test', 'Test microphone', 'Test Result'];
const DESC = [
  ["I'm playing, can you hear the voice?"],
  [
    'Please say something and wait for the playback',
    'Playing',
    'Did you hear the playback?',
  ],
];

enum ActionKind {
  SUCCESS_STEP = 'SUCCESS_STEP',
  FAIL_STEP = 'FAIL_STEP',
  NEXT_SUB_STEP = 'NEXT_SUB_STEP',
  RESET_SUB_STEP = 'RESET_SUB_STEP',
}

interface Action {
  type: ActionKind;
  payload?: any;
}

interface State {
  step: number;
  subStep: number;
  result: boolean[];
}

const initialState = {
  step: 0,
  subStep: 0,
  result: [false, false],
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionKind.SUCCESS_STEP: {
      const result = [...state.result];
      result[state.step] = true;
      return { ...state, step: state.step + 1, result };
    }
    case ActionKind.FAIL_STEP: {
      if (state.step === 0) {
        return { ...state, step: TITLE.length - 1 };
      } else {
        const result = [...state.result];
        result[state.step] = false;
        return { ...state, step: state.step + 1, result };
      }
    }
    case ActionKind.NEXT_SUB_STEP: {
      return { ...state, subStep: state.subStep + 1 };
    }
    case ActionKind.RESET_SUB_STEP: {
      return { ...state, subStep: 0 };
    }
    default:
      return state;
  }
}

function StepOne({
  desc,
  onSuccess,
  onFailed,
}: {
  desc: string;
  onSuccess: () => void;
  onFailed: () => void;
}) {
  const { t } = useTranslation();
  const { play: playSound } = usePlaySound();

  React.useEffect(() => {
    playSound();
    const timer = setInterval(() => {
      playSound();
    }, 5000);
    return () => timer && clearInterval(timer);
  }, [playSound]);

  return (
    <>
      <Typography variant="body1" color="secondary">
        {t(desc)}
      </Typography>
      <Stack direction="row" spacing={1} mt={2}>
        <Button variant="outlined" color="inherit" onClick={onSuccess}>
          {t('Yes')}
        </Button>
        <Button variant="outlined" color="inherit" onClick={onFailed}>
          {t('No, test the next one')}
        </Button>
      </Stack>
      <Divider sx={{ margin: '24px 0px 12px' }} />
      <SpeakerSelector />
    </>
  );
}

function StepTwo({
  desc,
  canInit,
  subStep,
  onSuccess,
  onFailed,
  onNext,
}: {
  desc: string;
  canInit: boolean;
  subStep: number;
  onSuccess: () => void;
  onFailed: () => void;
  onNext: () => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="body1" color="secondary">
        {t(desc)}
      </Typography>
      <Stack direction="row" spacing={1} mt={2} minHeight="32px">
        {subStep === 2 && (
          <>
            <Button variant="outlined" color="inherit" onClick={onSuccess}>
              {t('Yes')}
            </Button>
            <Button variant="outlined" color="inherit" onClick={onFailed}>
              {t('No, test the next one')}
            </Button>
          </>
        )}
      </Stack>
      <Divider sx={{ margin: '24px 0px 12px' }} />
      <Stack spacing={2}>
        <MicSelector />
        {subStep === 0 && <MicVolumeLevel />}
      </Stack>
      <Recorder canInit={canInit} onNext={onNext} />
    </>
  );
}

function StepResult({
  speakerIsSuccess,
  micIsSuccess,
  speakerLabel,
  micLabel,
}: {
  speakerIsSuccess: boolean;
  micIsSuccess: boolean;
  speakerLabel: string;
  micLabel: string;
}) {
  const { t } = useTranslation();
  if (speakerIsSuccess && micIsSuccess) {
    return (
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Typography variant="body2" sx={{ flexShrink: 0 }}>
            {t('Speaker')}
          </Typography>
          <Stack
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">{speakerLabel}</Typography>
            <DoneIcon style={{ color: COLOR.PRIMARY }} />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Typography variant="body2" sx={{ flexShrink: 0 }}>
            {t('Microphone')}
          </Typography>
          <Stack
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">{micLabel}</Typography>
            <DoneIcon style={{ color: COLOR.PRIMARY }} />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Typography variant="body1" color="secondary">
      {!speakerIsSuccess
        ? t('No available speakers, please check device settings.')
        : t('No available microphone, please check device settings.')}
    </Typography>
  );
}

function TestDeviceDialog({ onGlobalClose }: { onGlobalClose: () => void }) {
  const { t } = useTranslation();
  const reduxDispatch = useAppDispatch();
  const open = useAppSelector((state) => state.room.testDeviceDialogIsOpen);
  const speakersInfo = useAppSelector((state) => state.room.speakersInfo);
  const selectedSpeakerId = useAppSelector(
    (state) => state.room.selectedSpeakerId
  );
  const micsInfo = useAppSelector((state) => state.room.micsInfo);
  const selectedMicId = useAppSelector((state) => state.room.selectedMicId);

  const speakerLabel = React.useMemo(() => {
    const info = speakersInfo.find(
      (item) => item.deviceId === selectedSpeakerId
    );
    return info?.label || '';
  }, [speakersInfo, selectedSpeakerId]);
  const micLabel = React.useMemo(() => {
    const info = micsInfo.find((item) => item.deviceId === selectedMicId);
    return info?.label || '';
  }, [micsInfo, selectedMicId]);

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { step, subStep, result } = state;
  const title = TITLE?.[step];
  const desc = DESC?.[step]?.[subStep];

  function handleClose() {
    reduxDispatch(roomActions.setTestDeviceDialogIsOpen(false));
  }

  function handleSuccess() {
    dispatch({ type: ActionKind.SUCCESS_STEP });
  }

  const clickNextSpeakerCount = React.useRef(0);
  const clickNextMicCount = React.useRef(0);
  function handleFailed() {
    if (step === 0) {
      clickNextSpeakerCount.current += 1;
      if (clickNextSpeakerCount.current >= speakersInfo.length) {
        dispatch({ type: ActionKind.FAIL_STEP });
      } else {
        reduxDispatch(roomActions.selectNextSpeaker());
      }
    } else if (step === 1) {
      clickNextMicCount.current += 1;
      if (clickNextMicCount.current >= micsInfo.length) {
        dispatch({ type: ActionKind.FAIL_STEP });
      } else {
        reduxDispatch(roomActions.selectNextMic());
        dispatch({ type: ActionKind.RESET_SUB_STEP });
      }
    }
  }

  const handleNextSubStep = React.useCallback(() => {
    dispatch({ type: ActionKind.NEXT_SUB_STEP });
  }, []);

  React.useEffect(() => {
    if (!open) {
      setTimeout(() => {
        onGlobalClose();
      }, 1000);
    }
  }, [open, onGlobalClose]);

  return (
    <Dialog
      isShowClose
      open={open}
      title={t(title)}
      onClose={handleClose}
      footer={null}
    >
      <div style={{ minHeight: '200px' }}>
        {open && step === 0 && (
          <StepOne
            desc={desc}
            onSuccess={handleSuccess}
            onFailed={handleFailed}
          />
        )}
        {step === 1 && (
          <StepTwo
            desc={desc}
            canInit={subStep === 0}
            subStep={subStep}
            onSuccess={handleSuccess}
            onFailed={handleFailed}
            onNext={handleNextSubStep}
          />
        )}
        {step === 2 && (
          <StepResult
            speakerIsSuccess={result[0]}
            micIsSuccess={result[1]}
            speakerLabel={speakerLabel}
            micLabel={micLabel}
          />
        )}
      </div>
    </Dialog>
  );
}

function TestDeviceDialogWrapper() {
  const [globalOpen, setGlobalOpen] = React.useState(false);
  const open = useAppSelector((state) => state.room.testDeviceDialogIsOpen);

  const handleGlobalClose = React.useCallback(() => {
    setGlobalOpen(false);
  }, []);

  React.useEffect(() => {
    if (open) setGlobalOpen(true);
  }, [open]);

  if (globalOpen) {
    return <TestDeviceDialog onGlobalClose={handleGlobalClose} />;
  }

  return null;
}

export default TestDeviceDialogWrapper;
