import { ArrowLeftIcon } from 'fbm-icons';
import { Box, IconButton } from 'fbm-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { memo, useCallback, useEffect, useState } from 'react';
import { Stack } from 'fbm-ui/lib/mui';
import Layout from './Layout';
import SimulateAPI from '../../api/simulate';
import {
  avocadoVideoInterview,
  AiReportV2,
  useAiReportV2,
} from 'eli-video-interview';
import config from '../../config';
import CommonAPI from '../../api/common';

const { INTERVIEW_API_URL } = config;

const Report = () => {
  const { token = '' } = useParams();
  const navigate = useNavigate();

  const [steps, setSteps] = useState<any[]>([]);
  // const [faceAnalysis, setFaceAnalysis] = useState<any>({});
  const [pageStepIndexes, setPageStepIndexes] = useState<number[]>([]);
  const [stepIndex, setStepIndex] = useState<number>(0);

  useEffect(() => {
    avocadoVideoInterview.init({
      server_url: INTERVIEW_API_URL,
      account: '',
      enterpriseId: '',
      auth_callback: () =>
        new Promise((resolve, reject) => {
          SimulateAPI.getAiProcessCredential()
            .then((data) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        }),
    });
  }, []);

  const fetchFormResult = useCallback(
    () => CommonAPI.getReportForm(token).catch(console.log),
    [token]
  );

  const aiReport = useAiReportV2({
    token,
    disabledScore: true,
    showAiDimension: true,
    canEditAiDimension: false,
    scoreRankIntervals: [],
    stepToken: steps[pageStepIndexes[stepIndex]]?.token,
    operableButtons: [],
    onLoad: (params) => {
      console.log(params);
      setSteps(params.steps);
      // setFaceAnalysis(params.faceAnalysis);
      setPageStepIndexes(params.pageStepIndexes);
    },
    onScoreChange: (scoreState) => {
      console.log(scoreState);
    },
    onStepChange: (stepToken) => {
      const index = steps.findIndex(
        ({ token: tempToken }) => tempToken === stepToken
      );
      const stepChangedIndex = pageStepIndexes.findIndex((i) => i === index);
      setStepIndex(stepChangedIndex);
    },
    onScoreRankIntervalsChange: (scoreRankIntervals) => {
      console.log(scoreRankIntervals);
    },
    fetchFormResult,
    fetchCandidateInfo: null,
  });

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Layout>
      <Stack width={960} position="relative">
        <Box mb={2}>
          <IconButton onClick={handleGoBack}>
            <ArrowLeftIcon sx={{ color: 'rgba(0,0,0,0.86)' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ width: '1024px', height: '100%', margin: '0 auto' }}>
            <AiReportV2 {...aiReport} />
          </Box>
        </Box>
      </Stack>
    </Layout>
  );
};

export default memo(Report);
