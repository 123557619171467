import {
  Extension,
  VideoProcessor,
  IProcessorContext,
} from 'agora-rte-extension';
import FaceAnti from './face';

import type { MateData } from './api';

interface ExtensionParams {
  authorization: string;
  cheatKey: string;
  mateData: MateData;
}

class AntiVideoExtension extends Extension<AntiVideoProcessor> {
  protected _createProcessor(): AntiVideoProcessor {
    return new AntiVideoProcessor();
  }
}

class AntiVideoProcessor extends VideoProcessor {
  public name = 'AntiVideoProcessor';
  private faceAnti?: FaceAnti;
  private canvas: HTMLCanvasElement;
  private videoElement: HTMLVideoElement;
  private ctx: CanvasRenderingContext2D;

  public constructor() {
    super();

    this.canvas = document.createElement('canvas');
    this.canvas.width = 640;
    this.canvas.height = 480;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.ctx = this.canvas.getContext('2d')!;
    this.videoElement = document.createElement('video');
    this.videoElement.muted = true;
  }

  public startCheat({ cheatKey, mateData, authorization }: ExtensionParams) {
    if (!cheatKey || !authorization) {
      console.error('cheatKey and authorization not nil ');
    }
    this.faceAnti = new FaceAnti(cheatKey, mateData, authorization);
  }

  onTrack(track: MediaStreamTrack, context: IProcessorContext) {
    this.videoElement.srcObject = new MediaStream([track]);
    this.videoElement.play();

    this.output(track, context);

    this.loop();
  }

  async loop() {
    this.ctx.drawImage(this.videoElement, 0, 0);
    await this.faceAnti?.check(this.videoElement);

    requestAnimationFrame(() => this.loop());
  }

  doneProcessing() {
    const msStream = this.canvas.captureStream(30);
    const outputTrack = msStream.getVideoTracks()[0];

    if (this.context) {
      this.output(outputTrack, this.context);
    }
  }
}

export { AntiVideoExtension };
