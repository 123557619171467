import client from './client';
import { apiCheck } from '../utils/apiCheck';

export function simulateApplications() {
  return client('simulate/applications').then(apiCheck);
}
export function simulateDepartmentPositions(departmentToken, only) {
  return client(`simulate/${departmentToken}/${only}/positions`).then(apiCheck);
}

export function simulateRetry(applicationToken) {
  return client('simulate/moveTo', { body: { applicationToken } }).then(
    apiCheck
  );
}

export function simulateCategoryList() {
  return client(`simulate/category/list`).then(apiCheck);
}
export function simulatePositions() {
  return client(`simulate/positions`, { body: {} }).then(apiCheck);
}

export function simulateApplicationProcess(applicationToken) {
  return client(`simulate/applicationProcess/${applicationToken}`).then(
    apiCheck
  );
}

export const getApiAccess = (data) =>
  client('/aiInterview/apiAccess', { body: data }).then(apiCheck);

export const getAiProcessCredential = () =>
  client('interview/aiProcess/credential').then(apiCheck);

const SimulateAPI = {
  simulateCategoryList,
  simulateApplications,
  simulateRetry,
  simulateApplicationProcess,
  simulatePositions,
  simulateDepartmentPositions,
  getApiAccess,
  getAiProcessCredential,
};

export default SimulateAPI;
