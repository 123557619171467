import config from '../../config';

const { API_URL } = config;

const json = (resp: Response) => resp.json();

interface AntiReponse<T> {
  code: number;
  message: string;
  data: T;
}

const checkResponse = ({ code, message, data }: AntiReponse<any>) => {
  if (code === 0) {
    return data;
  } else {
    throw new Error(message);
  }
};

export interface MateData {
  mediaName?: string;
  offset?: number;
  stepToken?: string;
  title?: string;
  roomToken?: string;
  userToken?: string;
}

export interface CheatParams {
  cheatKey: string;
  faces: number;
  scene: number;
  photo: string;
  metaData: MateData;
}

export const report = (data: CheatParams, authorization: string) => {
  return fetch(`${API_URL}/vc1/cheat/reportPhoto`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      authorization: `Basic ${authorization}`,
      'content-type': 'application/json',
    },
  })
    .then(json)
    .then(checkResponse);
};

export const upload = (file: File, authorization: string) => {
  const formData = new FormData();
  formData.append('files', file);
  return fetch(`${API_URL}/common/upload`, {
    method: 'post',
    body: formData,
    headers: {
      authorization: `Basic ${authorization}`,
    },
  })
    .then(json)
    .then(checkResponse);
};
