import { useEffectOnce } from 'usehooks-ts';
import { useAppDispatch } from '../../redux/hooks';
import fromQualityToScore from '../../utils/fromQualityToScore';
import RoomModel from './RoomModel';
import { roomActions } from './slice';

function useEffectFetchRemoteNetworkQuality() {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    function fetchData() {
      const remoteNetworkQuality =
        RoomModel.rtcClient.getRemoteNetworkQuality();
      const remoteNetworkScores: Record<string, number> = {};
      Object.keys(remoteNetworkQuality).forEach((uid) => {
        const quality = remoteNetworkQuality[uid];
        remoteNetworkScores[uid] = fromQualityToScore(quality);
      });
      dispatch(roomActions.setRemoteNetworkScores(remoteNetworkScores));
    }

    const id = setInterval(() => {
      fetchData();
    }, 5000);

    return () => id && clearInterval(id);
  });
}

export default useEffectFetchRemoteNetworkQuality;
