import * as React from 'react';
import { Tabs, Tab } from '@avocadoui/components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loginActions } from './slice';

const tabData = ['手机号', '邮箱'];

export default function LoginTabs() {
  const dispatch = useAppDispatch();
  const { mode } = useAppSelector(({ login }) => login);

  const handleChangeTab = (_: React.SyntheticEvent, tab: number) => {
    dispatch(loginActions.setMode(tab));
  };

  return (
    <Tabs value={mode} onChange={handleChangeTab}>
      {tabData.map((label, index) => (
        <Tab key={`${index}${label}`} index={index} label={label} />
      ))}
    </Tabs>
  );
}
