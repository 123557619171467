import client from './client';
import { apiCheck } from '../utils/apiCheck';

interface LoginParams {
  mobile: string;
  mobileAreaCode: string;
  code: string;
}

export function sendSmsCode(mobile: string, mobileAreaCode: string) {
  return client('mobile/login/sendSmsCode', {
    body: {
      mobile,
      mobileAreaCode,
    },
  }).then(apiCheck);
}

function login(data: LoginParams) {
  return client(`interview/login`, { body: data }).then(apiCheck);
}

function studentLogin(data: any) {
  return client(`interview/studentLogin`, { body: data }).then(apiCheck);
}

function getCndidateInfo(applicationToken: string) {
  return client(
    `interview/candidate/info?applicationToken=${applicationToken}`
  ).then(apiCheck);
}

interface CndidateParams {
  name: string;
}

function updateCndidateInfo(applicationToken: string, data: CndidateParams) {
  return client(
    `interview/candidate/update?applicationToken=${applicationToken}`,
    { body: data }
  ).then(apiCheck);
}

function getCndidateNextProcess(applicationToken: string) {
  return client(
    `interview/application/nextProcess?applicationToken=${applicationToken}`
  ).then(apiCheck);
}
function getCndidateNextPrepare(positionToken: string) {
  return client(`interview/candidatePrepare?positionToken=${positionToken}`, {
    body: {},
  }).then(apiCheck);
}

function getSimulateSchools() {
  return client(`zjSchools`).then(apiCheck);
}

const RoomAPI = {
  sendSmsCode,
  login,
  studentLogin,
  getCndidateInfo,
  updateCndidateInfo,
  getCndidateNextProcess,
  getCndidateNextPrepare,
  getSimulateSchools,
};

export default RoomAPI;
