import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICommentItem {
  uid: string;
  type: 1 | 2; // 1:普通评论 2:提醒评论
  message: string;
  createdAt?: number;
}

interface CommentState {
  commentList: ICommentItem[];
  isCommentOpen: boolean;
  newCommentCount: number;
  canComment: boolean;
}

const initialState = {
  commentList: [],
  isCommentOpen: false,
  newCommentCount: 0,
  canComment: false,
} as CommentState;

const slice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    addComment: (state, action: PayloadAction<ICommentItem>) => {
      if (state.canComment) {
        const comment = action.payload;
        comment.createdAt = Date.now();
        state.commentList.push(comment);

        if (!state.isCommentOpen) {
          state.newCommentCount += 1;
        }
      }
    },
    toggleComment: (state) => {
      state.isCommentOpen = !state.isCommentOpen;
      state.newCommentCount = 0;
    },
    setCanComment: (state, action) => {
      state.canComment = action.payload;
    },
  },
});

export const commentActions = slice.actions;
export const commentReducer = slice.reducer;
