import { isMac, isWin } from '@avocadoui/utils';

const typeName = {
  audio: '麦克风',
  video: '摄像头',
  all: '麦克风和摄像头',
};

export function getPermissionTip(type: 'audio' | 'video'): string {
  if (isMac()) return `请在系统偏好设置中启用${typeName[type]}权限`;
  if (isWin()) return `请在电脑设置中启用${typeName[type]}权限`;
  return `请先启用${typeName[type]}权限`;
}

export function getPermissionTip2(type: 'audio' | 'video' | 'all'): string[] {
  if (isMac())
    return [`未获取到${typeName[type]}权限`, `请在系统偏好设置中授权`];
  if (isWin()) return [`未获取到${typeName[type]}权限`, `请在电脑设置中授权`];
  return [`未获取到${typeName[type]}权限`, ''];
}
