const configs = {
  development: {
    demo3: {
      API_URL: 'https://fbm-demo3e.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api-demo2.fbmms.cn',
      AGORA_APP_ID: '3bfba20ad4a54d37a732a5b8023389a9',
    },
    dev3: {
      API_URL: 'https://fbm-dev1e.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api-demo2.fbmms.cn',
      AGORA_APP_ID: '865732e2567c4d12964e42abb6ee8981',
    },
    dev1: {
      API_URL: 'https://fbm-dev1e.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api-demo2.fbmms.cn',
      AGORA_APP_ID: '865732e2567c4d12964e42abb6ee8981',
    },
    staging: {
      API_URL: 'https://staging-b.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api-demo2.fbmms.cn',
      AGORA_APP_ID: '1ae3d8a1608649a59f5e5d493cd266d7',
    },
    prod: {
      API_URL: 'https://b.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api.fbmms.cn',
      AGORA_APP_ID: '1ae3d8a1608649a59f5e5d493cd266d7',
    },
  },
  production: {
    demo3: {
      API_URL: 'https://fbm-demo3e.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api-demo2.fbmms.cn',
      AGORA_APP_ID: '3bfba20ad4a54d37a732a5b8023389a9',
    },
    dev1: {
      API_URL: 'https://fbm-dev1e.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api-demo2.fbmms.cn',
      AGORA_APP_ID: '865732e2567c4d12964e42abb6ee8981',
    },
    dev3: {
      API_URL: 'https://fbm-dev3e.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api-demo2.fbmms.cn',
      AGORA_APP_ID: '865732e2567c4d12964e42abb6ee8981',
    },
    staging: {
      API_URL: 'https://staging-b.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api-demo2.fbmms.cn',
      AGORA_APP_ID: '1ae3d8a1608649a59f5e5d493cd266d7',
    },
    prod: {
      API_URL: 'https://b.fbmms.cn',
      INTERVIEW_API_URL: 'https://fbm-api.fbmms.cn',
      AGORA_APP_ID: '1ae3d8a1608649a59f5e5d493cd266d7',
    },
  },
};

type TYPE_NODE_ENV = keyof typeof configs;
type TYPE_APP_ENV = keyof typeof configs['production'];

const config =
  configs[process.env.NODE_ENV as TYPE_NODE_ENV][
    (process.env.REACT_APP_ENV as TYPE_APP_ENV) || 'staging'
  ];

export default config;
