import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Text, Dialog, useDialog } from '@avocadoui/components';

interface ICard {
  children: React.ReactNode;
  title: string;
}

function Card({ children, title }: ICard) {
  return (
    <Stack spacing={2} p={2} sx={{ border: '1px solid rgba(0, 0, 0, 0.08)' }}>
      <Text variant="h6" color="text.primary">
        {title}
      </Text>
      <Text variant="body2" color="text.primary">
        {children}
      </Text>
    </Stack>
  );
}

function Content() {
  return (
    <Stack spacing={2}>
      <Card title="视频卡顿问题">
        <ol style={{ marginLeft: '16px' }}>
          <li>
            请先查看自己的网络环境，如果网络环境较差请更换网络（如使用手机4G热点），也可以尝试关闭视频画面，只通过语音通话。
          </li>
          <li>如不能解决，请换用其他浏览器（Chrome或Firefox）。</li>
        </ol>
      </Card>
      <Card title="听不到声音">
        <ol style={{ marginLeft: '16px' }}>
          <li>确认说话一方的的麦克风是否正常，保证可以正常录声音。</li>
          <li>确认听声音一方的电脑音量外放足够大，以及网络是否通畅。</li>
          <li>可尝试双方刷新重进一下。</li>
          <li>如不能解决，请换用其他浏览器（Chrome或Firefox）。</li>
        </ol>
      </Card>
      <Card title="噪音/回声问题">
        <ol style={{ marginLeft: '16px' }}>
          <li>
            如果您旁边有其他人接入，或者同时使用电脑和手机接入，请只保留一个接入。
          </li>
          <li>请戴耳机（带有耳麦）接入。</li>
          <li>可尝试刷新重进一下。</li>
        </ol>
      </Card>
    </Stack>
  );
}

function RoomHelp() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDialog();

  return (
    <>
      <Button variant="text" color="default" onClick={onOpen}>
        {t('help')}
      </Button>
      <Dialog
        title="帮助中心"
        desc={<Content />}
        isOpen={isOpen}
        onClose={onClose}
        onOverlayClick={onClose}
        showCloseBtn
        size="large"
      />
    </>
  );
}

export default RoomHelp;
