import { Box, RightDrawer } from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';
import { isInterviewer } from '../../utils/localStorage';
import JoinedUserList from './JoinedUserList';
import ToJoinUserList from './ToJoinUserList';

function SidebarLayout() {
  const isUserListDrawerOpen = useAppSelector(
    (state) => state.room.isUserListDrawerOpen
  );
  const isRecordRoomType = useAppSelector(
    (state) => state.room.roomInfo.roomType === 1
  );

  if (isInterviewer()) {
    return (
      <Box sx={{ zIndex: 1 }}>
        <RightDrawer isOpen={isUserListDrawerOpen}>
          <JoinedUserList />
          {!isRecordRoomType && <ToJoinUserList />}
        </RightDrawer>
      </Box>
    );
  }

  return null;
}

export default SidebarLayout;
