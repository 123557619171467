import * as React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Controller, Control } from 'react-hook-form';
import { Stack, Checkbox, Text, Link } from '@avocadoui/components';
import { FormData } from './types';

const variants = {
  start: () => ({
    translateX: [-8, 8, 0],
    transition: {
      repeat: 1,
      duration: 0.2,
    },
  }),
};

type AgreementCheckboxProps = {
  control: Control<FormData>;
};

export type AgreementCheckboxHandle = {
  startAnimation: () => void;
};

export default React.forwardRef<
  AgreementCheckboxHandle,
  AgreementCheckboxProps
>(function AgreementCheckbox({ control }, ref) {
  const controls = useAnimation();

  React.useImperativeHandle(
    ref,
    () => ({
      startAnimation: () => controls.start('start'),
    }),
    [controls]
  );

  return (
    <motion.div variants={variants} animate={controls}>
      <Stack direction="row" alignItems="center">
        <Controller
          control={control}
          name="isAgreementChecked"
          render={({ field: { onChange, value } }) => (
            <Checkbox size="small" checked={value} onChange={onChange} />
          )}
        />
        <Text variant="caption" color="text.secondary">
          已阅读并同意
          <Link
            sx={{ color: '#4CAF50', textDecoration: 'none' }}
            href="//webcdn.fbmms.cn/data/public_policy.txt"
            target="_blank"
          >
            《用户协议》
          </Link>
          和
          <Link
            sx={{ color: '#4CAF50', textDecoration: 'none' }}
            href="//webcdn.fbmms.cn/data/public_privacy.txt"
            target="_blank"
          >
            《隐私条款》
          </Link>
        </Text>
      </Stack>
    </motion.div>
  );
});
