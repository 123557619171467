import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';

const WaterDropOutlineIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <defs>
      <clipPath id="water_drop_outline">
        <path d="M8,0 C13.33,4.55 16,8.48 16,11.8 C16,16.78 12.2,20 8,20 C3.8,20 0,16.78 0,11.8 C0,8.48 2.67,4.55 8,0 Z M8,2.66 C3.95,6.36 2,9.46 2,11.8 C2,15.43 4.65,18 8,18 C11.35,18 14,15.43 14,11.8 C14,9.45 12.05,6.36 8,2.66 Z M3.83,12 C4.2,12 4.5,12.26 4.57,12.62 C4.98,14.84 6.85,15.6 8.21,15.49 C8.64,15.47 9,15.81 9,16.24 C9,16.64 8.68,16.97 8.28,16.99 C6.15,17.12 3.66,15.9 3.09,12.87 C3.01,12.42 3.37,12 3.83,12 Z" />
      </clipPath>
    </defs>
    <g clip-path="url(#water_drop_outline)" transform="translate(4 2)">
      <polygon fill="currentColor" points="0 0 16 0 16 20 0 20 0 0" />
    </g>
  </SvgIcon>
);

export default WaterDropOutlineIcon;
