import * as React from 'react';
import { Text, FromNow } from '@avocadoui/components';
import { dayjs } from '@avocadoui/utils';

function computeDiff(timestamp: number) {
  const now = dayjs();
  const then = dayjs(timestamp);
  return now.diff(then, 'second');
}

interface IProps {
  joinTime: number;
}

function JoinTimeText({ joinTime }: IProps) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const id = setInterval(() => {
      const diff = computeDiff(joinTime * 1000);
      if (diff > 60) {
        setShow(true);
        id && clearInterval(id);
      }
    }, 10000);

    return () => id && clearInterval(id);
  }, [joinTime]);

  if (show) {
    return (
      <Text variant="body2" color="text.secondary">
        已等待 <FromNow timestamp={joinTime * 1000} withoutSuffix />
      </Text>
    );
  }

  return null;
}

export default JoinTimeText;
