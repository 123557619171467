// credit: https://github.com/Swizec/useDimensions
import * as React from 'react';

interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

type UseDimensionsHook = [
  React.LegacyRef<HTMLDivElement>,
  // Record<string, unknown> | DimensionObject
  DimensionObject
];

interface UseDimensionsArgs {
  liveMeasure?: boolean;
  deps?: (string | number | boolean)[];
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: rect.x ? rect.x : rect.top,
    left: rect.y ? rect.y : rect.left,
    x: rect.x ? rect.x : rect.left,
    y: rect.y ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

const defaultDimension: DimensionObject = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  x: 0,
  y: 0,
  right: 0,
  bottom: 0,
};

function useDimensions({
  liveMeasure = true,
  deps = [],
}: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] =
    React.useState<DimensionObject>(defaultDimension);
  const [node, setNode] = React.useState<HTMLElement>();

  const ref = React.useCallback((node) => {
    setNode(node);
  }, []);

  React.useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, liveMeasure, JSON.stringify(deps)]);

  return [ref, dimensions];
}

export default useDimensions;
