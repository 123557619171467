import * as React from 'react';
import { useAppSelector } from '../../redux/hooks';
import RoomModel from './RoomModel';

function useEffectVirtualBackground(initialed?: boolean) {
  const virtualOpen = useAppSelector((state) => state.account.virtualOpen);
  const enterpriseImage = useAppSelector(
    (state) => state.account.enterpriseImage
  );
  const backgroundImages = useAppSelector(
    (state) => state.account.backgroundImages
  );

  const vbImage = React.useMemo(() => {
    const foundInUse = backgroundImages.find((item) => item.isUse === 1);
    if (foundInUse) return foundInUse?.imageUrl;
    return enterpriseImage;
  }, [enterpriseImage, backgroundImages]);

  React.useEffect(() => {
    if (virtualOpen === 0) {
      RoomModel.disableVbProcessor();
    } else if (virtualOpen === 1) {
      RoomModel.enableBlurVbProcessor();
    } else if (virtualOpen === 2) {
      if (vbImage) {
        console.log('vbImage:', vbImage);
        RoomModel.enableVbProcessor(vbImage);
      }
    }
  }, [virtualOpen, vbImage, initialed]);
}

export default useEffectVirtualBackground;
