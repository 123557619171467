import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { Button } from '..';
import LoadingDots from '../Loading/LoadingDots';

interface IProps {
  variant?: 'confirm' | 'alert';
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  desc: string;
  showCancelBtn?: boolean;
  confirmTxt: string;
  onConfirm: () => void;
  isConfirming?: boolean;
}

function ConfirmDialog({
  variant = 'confirm',
  isOpen,
  onClose,
  title,
  desc,
  showCancelBtn = true,
  confirmTxt,
  onConfirm,
  isConfirming = false,
}: IProps) {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          width: '360px',
          padding: '16px 16px 12px 24px',
        },
      }}
    >
      {!!title && (
        <DialogTitle
          id="confirm-dialog-title"
          sx={{
            p: 0,
            mb: 2,
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.86)',
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ p: 0, '&:first-of-type': { pt: 1 } }}>
        <DialogContentText
          id="confirm-dialog-description"
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            color: 'rgba(0, 0, 0, 0.56)',
          }}
        >
          {desc}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 0, mt: 3 }}>
        {showCancelBtn && <Button onClick={onClose}>取消</Button>}
        <Button
          variant="contained"
          color={variant === 'confirm' ? 'primary' : 'secondary'}
          onClick={async () => {
            await onConfirm();
            onClose();
          }}
          autoFocus
          disabled={isConfirming}
        >
          {confirmTxt}
          {isConfirming && <LoadingDots />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
