import IntviewAPI from '../../../api/Intview';

export const sync = (roomToken, params) => {
  return IntviewAPI.sync(roomToken, params);
};

export const makeParams = ({
  stepToken,
  isOpenAIFollowUp,
  streamName,
  status,
  prepareTime,
  selected = [],
}) => {
  return {
    stepToken: stepToken,
    status: status,
    hasFollowUp: isOpenAIFollowUp,
    selected,
    media: {
      name: streamName,
      type: 4,
      prepareTime: prepareTime,
      vodProvider: 1,
    },
  };
};

export const syncStartInterview = async ({
  stepToken,
  isOpenAIFollowUp,
  streamName,
  prepareTime,
}) => {
  const params = makeParams({
    stepToken: stepToken,
    isOpenAIFollowUp: isOpenAIFollowUp,
    streamName: streamName,
    status: 6,
    prepareTime: prepareTime,
  });

  return sync('start', params);
};

export const syncEndInterview = async ({
  stepToken,
  isOpenAIFollowUp,
  streamName,
  prepareTime,
  selected,
}) => {
  const params = makeParams({
    stepToken: stepToken,
    isOpenAIFollowUp: isOpenAIFollowUp,
    streamName: streamName,
    prepareTime: prepareTime,
    selected,
    status: 4,
  });

  return sync('end', params);
};

const TYPE_MAP = {
  1: 'normal',
  2: {
    1: 'robot',
    2: 'virtualHuman',
  },
};

export const INTERVIEW_TYPE = (interactType, humanType) => {
  return (
    (TYPE_MAP[interactType] &&
      (typeof TYPE_MAP[interactType] === 'object'
        ? TYPE_MAP[interactType][humanType]
        : TYPE_MAP[interactType])) ||
    'normal'
  );
};

export const IS_NORMAL = (interactType, humanType) =>
  INTERVIEW_TYPE(interactType, humanType) === 'normal';

export const IS_ROBOT = (interactType, humanType) =>
  INTERVIEW_TYPE(interactType, humanType) === 'robot';

export const IS_VIRTUAL_HUMAN = (interactType, humanType) =>
  INTERVIEW_TYPE(interactType, humanType) === 'virtualHuman';
