import { useEffectOnce } from 'usehooks-ts';

import { useAppDispatch } from '../../redux/hooks';
import { commentActions } from './slice';

function useEffectSetCanComment() {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(commentActions.setCanComment(true));

    return () => {
      dispatch(commentActions.setCanComment(false));
    };
  });
}

export default useEffectSetCanComment;
