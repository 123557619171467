import * as React from 'react';
import { isMobile, log } from '@avocadoui/utils';
import { useAppSelector } from '../../redux/hooks';
import { isInterviewer } from '../../utils/localStorage';

function useEffectRedirectToMobile() {
  const mobileLinkUrl = useAppSelector((state) => state.room.mobileLinkUrl);

  React.useEffect(() => {
    if (mobileLinkUrl) {
      if (isMobile()) {
        log.capture(`跳转到小程序(${isInterviewer() ? 'B' : 'C'}端)`);
        setTimeout(() => {
          window.open(mobileLinkUrl, '_self');
        }, 2000);
      } else {
        log.capture('不跳转到小程序');
      }
    }
  }, [mobileLinkUrl]);

  return null;
}

export default useEffectRedirectToMobile;
