import { RtmMessage, RtmStatusCode } from 'agora-rtm-sdk';
import * as React from 'react';
import { toast } from '@avocadoui/components';
import { log } from '@avocadoui/utils';
import { useAppDispatch } from '../../redux/hooks';
import { commentActions } from '../Comment/slice';
import RoomModel from './RoomModel';
import { roomActions } from './slice';
import RoomAPI from '../../api/room';
import useRoomToken from './useRoomToken';
import { isInterviewee } from '../../utils/localStorage';
import useQueryCurrentUserInfo from './useQueryCurrentUserInfo';
import useCallbackRenewAgoraToken from './useCallbackRenewAgoraToken';
import { useRoomSend } from './useMachineContext';
import { Button, Typography } from 'fbm-ui';
import { Stack } from '@mui/material';

function useRTM() {
  const send = useRoomSend();
  const roomToken = useRoomToken();
  const dispatch = useAppDispatch();

  const {
    data: { agoraCname: channelName, agoraUid, agoraRTMToken: token } = {},
  } = useQueryCurrentUserInfo();
  const uid = agoraUid?.toString();

  const handleConnectionStateChanged = React.useCallback(
    (
      state: RtmStatusCode.ConnectionState,
      reason: RtmStatusCode.ConnectionChangeReason
    ) => {
      dispatch(roomActions.setRtmConnectionState(state));
      log.capture(`RTM: ${state}`, { state, reason });
      if (state === 'ABORTED' && reason === 'REMOTE_LOGIN') {
        dispatch(roomActions.leaveRoomDispatched(3));
        toast.error('同账号在其他设备登录，被迫下线');
        log.capture('RTM: 同账号在其他设备登录，被迫下线');
      }
    },
    [dispatch]
  );

  const handleMessageFromPeer = React.useCallback(
    (message: RtmMessage, peerId: string) => {
      console.log('=== MessageFromPeer:', message, peerId);
      if (message.messageType === 'TEXT') {
        const text = JSON.parse(message.text);
        if (text.type === 'LEAVE_ROOM') {
          dispatch(roomActions.leaveRoomDispatched(1));
        }
        if (text.type === 'DESTROY_ROOM') {
          dispatch(roomActions.leaveRoomDispatched(2));
        }
      }
    },
    [dispatch]
  );

  const handleChannelMessage = React.useCallback(
    (message: RtmMessage, memberId: string) => {
      console.log('=== ChannelMessage:', message, memberId);
      if (message.messageType === 'TEXT') {
        try {
          const text = JSON.parse(message.text);
          if (text.type === 'LEAVE_WAITING_ROOM') {
            dispatch(roomActions.leftBeforeMeWaitingUserList(memberId));
          } else if (text.type === 'JOIN_WAITING_ROOM') {
            dispatch(roomActions.setJoinWaitingRoomUserId(memberId));
          } else if (text.type === 'ALERT') {
            if (text.message) {
              toast.loading((t) => (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2">{text.message}</Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => toast.dismiss(t.id)}
                  >
                    知道了
                  </Button>
                </Stack>
              ));
            }
          } else {
            dispatch(commentActions.addComment({ uid: memberId, ...text }));
          }
        } catch (error) {
          console.log('handleChannelMessage error:', error);
        }
      }
    },
    [dispatch]
  );

  const handleMemberJoined = React.useCallback(
    (memberId: string) => {
      console.log('=== RTM MemberJoined:', memberId);
      dispatch(roomActions.addToUserIdsAtRooms(memberId));
    },
    [dispatch]
  );

  const handleMemberLeft = React.useCallback(
    (memberId: string) => {
      console.log('=== RTM MemberLeft:', memberId);
      dispatch(roomActions.removeFromUserIdsAtRooms(memberId));
      dispatch(roomActions.leftBeforeMeWaitingUserList(memberId));
    },
    [dispatch]
  );

  const onRenewAgoraToken = useCallbackRenewAgoraToken();
  const handleRenewAgoraToken = React.useCallback(
    () => onRenewAgoraToken('RTM'),
    [onRenewAgoraToken]
  );
  const join = React.useCallback(async () => {
    try {
      RoomModel.createRtmClient();
      RoomModel.rtmClient.on(
        'ConnectionStateChanged',
        handleConnectionStateChanged
      );
      RoomModel.rtmClient.on('MessageFromPeer', handleMessageFromPeer);
      RoomModel.rtmClient.on('TokenExpired', handleRenewAgoraToken);
      await RoomModel.rtmClient.login({ uid, token });

      RoomModel.createRtmChannel(channelName);
      RoomModel.rtmChannel.on('ChannelMessage', handleChannelMessage);
      RoomModel.rtmChannel.on('MemberJoined', handleMemberJoined);
      RoomModel.rtmChannel.on('MemberLeft', handleMemberLeft);
      await RoomModel.rtmChannel?.join();
      const uids = await RoomModel.rtmChannel?.getMembers();
      dispatch(roomActions.setUserIdsAtRooms(uids));
      dispatch(
        roomActions.setBeforeMeWaitingUserList(uids.filter((id) => id !== uid))
      );
      if (isInterviewee()) {
        RoomAPI.joinRTMInterviewee(roomToken);
      }
      log.capture('SUCCESS:RTM加入频道', { uid, token, channelName });
    } catch (error) {
      if (['RtmTimeoutError', 'RtmUnavailableError'].includes(error.name)) {
        toast.error('连接超时，请检查网络防火墙', { id: 'join_room_error' });
      } else {
        toast.error('连接失败', { id: 'join_room_error' });
      }
      send('LEAVE');
      log.capture('FAIL:RTM加入频道', { error, uid, token, channelName });
    }
  }, [
    channelName,
    uid,
    token,
    handleConnectionStateChanged,
    handleMessageFromPeer,
    handleChannelMessage,
    handleMemberJoined,
    handleMemberLeft,
    dispatch,
    roomToken,
    handleRenewAgoraToken,
    send,
  ]);

  const leave = React.useCallback(async () => {
    try {
      if (RoomModel.rtmChannel) {
        await RoomModel.rtmChannel.leave();
      }

      if (RoomModel.rtmClient) {
        await RoomModel.rtmClient.logout();
        RoomModel.createRtmClient();
      }
    } catch (error) {
      console.error('useRTM leave:', error);
      log.capture('FAIL:RTM离开频道', { error });
    }
  }, []);

  const sendInvitation = React.useCallback(
    (uid: string) => {
      const localInvitation = RoomModel.rtmClient.createLocalInvitation(uid);
      RoomModel.setRTMLocalInvitations(uid, localInvitation);
      localInvitation.send();
      toast.success('已发送邀请');
      log.capture('SUCCESS:发送呼叫邀请', { uid });

      localInvitation.on('LocalInvitationReceivedByPeer', () => {
        console.log('LocalInvitationReceivedByPeer');
        log.capture('SUCCESS:被叫收到呼叫邀请', { uid });
      });
      localInvitation.on('LocalInvitationAccepted', (response) => {
        console.log('LocalInvitationAccepted:', response);
        toast.success('对方已接受');
        dispatch(roomActions.removeFromUserIdsAtInviting(uid));
        log.capture('SUCCESS:被叫接受呼叫邀请', { uid, response });
      });
      localInvitation.on('LocalInvitationRefused', (response) => {
        console.log('LocalInvitationRefused:', response);
        toast.success('对方已拒绝');
        dispatch(roomActions.removeFromUserIdsAtInviting(uid));
        log.capture('SUCCESS:被叫拒绝呼叫邀请', { uid, response });
      });
      localInvitation.on('LocalInvitationCanceled', () => {
        console.log('LocalInvitationCanceled');
        dispatch(roomActions.removeFromUserIdsAtInviting(uid));
        log.capture('SUCCESS:取消呼叫邀请', { uid });
      });
      localInvitation.on('LocalInvitationFailure', (reason) => {
        console.log('LocalInvitationFailure:', reason);
        dispatch(roomActions.removeFromUserIdsAtInviting(uid));
        log.capture('FAIL:呼叫邀请', { uid, reason });
      });
    },
    [dispatch]
  );

  const cancelInvitation = React.useCallback((uid: string) => {
    if (RoomModel.rtmLocalInvitations[uid]) {
      RoomModel.rtmLocalInvitations[uid]?.cancel?.();
      RoomModel.removeRTMLocalInvitations(uid);
      toast.success('已取消邀请');
    }
  }, []);

  const kickUser = React.useCallback(
    async (uid: string) => {
      try {
        const text = JSON.stringify({ type: 'LEAVE_ROOM' });
        RoomModel.rtmClient.sendMessageToPeer({ text }, uid);
        log.capture('SUCCESS:请求踢出房间', { uid, text });
        await RoomAPI.kickRtcUser(roomToken, uid);
        toast.success('移出成功');
      } catch (e) {
        console.log('kickUser error:', e);
      }
    },
    [roomToken]
  );

  const kickAllUsers = React.useCallback(async () => {
    const allUsers = await RoomModel.rtmChannel.getMembers();
    const allUsersWithoutMe = allUsers.filter((id) => id !== uid);
    const allPromises: Promise<{ hasPeerReceived: boolean }>[] = [];
    allUsersWithoutMe.forEach((uid) => {
      const text = JSON.stringify({ type: 'DESTROY_ROOM' });
      const p = RoomModel.rtmClient.sendMessageToPeer({ text }, uid);
      allPromises.push(p);
    });
    await Promise.all(allPromises);
  }, [uid]);

  return {
    join,
    leave,
    sendInvitation,
    cancelInvitation,
    kickUser,
    kickAllUsers,
  };
}

export default useRTM;
