import { MicOffIcon } from 'fbm-ui';
import {
  Box,
  Stack,
  Text,
  LoadingDots,
  PulsingAvatar,
  NetworkQualityIcon,
} from '@avocadoui/components';
import RoomModel from './RoomModel';
import { useAppSelector } from '../../redux/hooks';
import { COLOR } from '../../utils/const';

function RemotePlayerContainer({ uid }: { uid: string }) {
  const userIdsAtInviting = useAppSelector(
    (state) => state.room.userIdsAtInviting
  );
  const roomUsers = useAppSelector((state) => state.room.roomUsers);
  const remoteNetworkScores = useAppSelector(
    (state) => state.room.remoteNetworkScores
  );
  const maxAudioVolumeUserId = useAppSelector(
    (state) => state.room.maxAudioVolumeUserId
  );

  const id = `remote-player-container-${uid}`;
  const playerId = `remote-player-${uid}`;
  const user = RoomModel.rtcClient.remoteUsers.find((u) => u.uid === +uid);
  const isAudioMuted = !user?.hasAudio;
  const isVideoPlaying = user?.hasVideo;
  const isInviting = userIdsAtInviting.includes(uid);
  const userInfo = roomUsers.find((u) => u.agoraUid.toString() === uid);
  const isInterviewer = userInfo?.userType === 1;
  const username = userInfo?.userName;
  const avatar = userInfo?.avatar;
  const networkScore = remoteNetworkScores[uid];

  return (
    <Box
      key={id}
      id={id}
      sx={{ position: 'relative', backgroundColor: 'black' }}
    >
      <Box id={playerId} />
      {!isVideoPlaying && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: '#D8D8D8',
          }}
        >
          <PulsingAvatar
            isPulsing={isInviting}
            size="extraLarge"
            name={username}
            src={avatar}
          />
        </Box>
      )}
      {uid === maxAudioVolumeUserId && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            border: '4px solid #4CAF50',
            zIndex: 1,
          }}
        />
      )}
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        m={0.5}
        px={1}
        py={0.5}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '4px',
          zIndex: 1,
        }}
      >
        <Box
          display="flex"
          px={1}
          sx={{
            backgroundColor: 'white',
            border: '1px solid #D8D8D8',
            borderRadius: '8px',
          }}
        >
          <Text variant="caption" color="text.primary">
            {isInterviewer ? '企业成员' : '候选人'}
          </Text>
        </Box>
        {(networkScore === 1 || networkScore === -1) && (
          <Box display="flex">
            <NetworkQualityIcon score={networkScore} />
          </Box>
        )}
        {isAudioMuted && !isInviting && (
          <MicOffIcon
            style={{ width: '16px', height: '16px', color: COLOR.SECONDARY }}
          />
        )}
        <Box display="flex">
          <Text variant="caption" color="text.primary">
            {username}
            {isInviting && (
              <>
                (呼叫中
                <LoadingDots />)
              </>
            )}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
}

export default RemotePlayerContainer;
