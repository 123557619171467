import { Box, Stack, Text } from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';
import useEffectFetchPositionInfoForCandidate from './useEffectFetchPositionInfoForCandidate';

function PositionInfoTab() {
  const positionInfoForCandidate = useAppSelector(
    (state) => state.room.positionInfoForCandidate
  );
  const { positionDesc, treatments, requirement, cover } =
    positionInfoForCandidate;
  const { videoUrl } = cover;

  useEffectFetchPositionInfoForCandidate();

  return (
    <Stack direction="row" spacing={3}>
      <Stack spacing={3} flex={1}>
        {treatments.length > 0 && (
          <Box>
            <Text>福利待遇：</Text>
            <Text mt={1}>{treatments.join('、')}</Text>
          </Box>
        )}
        {positionDesc && (
          <Box>
            <Text>职位描述：</Text>
            <Text
              mt={1}
              dangerouslySetInnerHTML={{
                __html: positionDesc,
              }}
            ></Text>
          </Box>
        )}
        {requirement && (
          <Box>
            <Text>任职资格：</Text>
            <Text
              mt={1}
              dangerouslySetInnerHTML={{ __html: requirement }}
            ></Text>
          </Box>
        )}
      </Stack>
      {videoUrl && (
        <Stack maxWidth="180px">
          <video src={videoUrl} controls />
        </Stack>
      )}
    </Stack>
  );
}

export default PositionInfoTab;
