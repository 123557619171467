import { Box, Typography } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import Layout from './Layout';
import { useNavigate, useParams } from 'react-router-dom';
import SimulateAPI from '../../api/simulate';
import { useEffect, useState } from 'react';
import { showErrorMessage } from '../../utils/apiCheck';
import { useAppDispatch } from '../../redux/hooks';
import { interviewActions } from './slice';
import { canMobileLogin, CARD_BACKGROUNDS, hashFunction } from './constant';

// http://localhost:3000/enterprise/simulate/oF9u6Mhb7URsLRmB9z67R03EHX/0

const EnterpriseSimulate = () => {
  const navigate = useNavigate();
  const { token, only } = useParams();
  const dispatch = useAppDispatch();

  const [simulatePositions, setSimulatePositions] = useState([]);

  const handleSimulatePostion = (positionToken) => {
    const id_token = localStorage.getItem('id_token');
    if (id_token) {
      navigate(
        `/${canMobileLogin() ? 'login' : 'studentlogin'}/${positionToken}`
      );
      return;
    } else {
      navigate(
        `/${canMobileLogin() ? 'login' : 'studentlogin'}?redirect=${
          window.location.pathname
        }`
      );
    }
  };

  useEffect(() => {
    SimulateAPI.simulateDepartmentPositions(token, only)
      .then((res) => {
        setSimulatePositions(res.list);
        localStorage.setItem('mobileLoginOpen', res.mobileLoginOpen);
        dispatch(
          interviewActions.setEnterprise({
            name: res.enterpriseName,
            logo: res.enterpriseLogo,
          })
        );
      })
      .catch(showErrorMessage);
  }, [token, only, dispatch]);

  return (
    <Layout>
      <Stack width={1096}>
        <Stack spacing={4}>
          {simulatePositions.map((item, i) => (
            <Stack spacing={3} key={item.categoryToken || `category-key${i}`}>
              <Typography variant="h6" fontWeight="medium">
                {item.category}
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ' repeat(4, 1fr)',
                  gap: 3,
                  width: '100%',
                  padding: 0,
                }}
              >
                {item.positions.map((position) => (
                  <Stack
                    onClick={() =>
                      handleSimulatePostion(position.positionToken)
                    }
                    sx={{
                      px: 3,
                      pt: 3,
                      pb: 2,
                      cursor: 'pointer',
                      justifyContent: 'space-between',
                      height: 144,
                      borderRadius: 2,
                      background: `url(${
                        CARD_BACKGROUNDS[hashFunction(position.positionToken)]
                      }) no-repeat center center`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <Typography variant="body1" fontWeight="medium">
                      {position.positionName}
                    </Typography>
                    <Typography variant="body2">
                      {position.categoryName}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Layout>
  );
};

export default EnterpriseSimulate;
