import * as React from 'react';
import useRoomToken from './useRoomToken';

function useEffectRememberRoomToken() {
  const roomToken = useRoomToken();

  React.useEffect(() => {
    sessionStorage.setItem('roomToken', roomToken);
    localStorage.setItem('deviceIsReady', 'ready');
  }, [roomToken]);

  return null;
}

export default useEffectRememberRoomToken;
