import { useQuery } from 'react-query';
import { log } from '@avocadoui/utils';
import AccountAPI from '../../api/account';
import { getUserType } from '../../utils/localStorage';
import useAccount from '../Account/useAccount';
import useRoomToken from './useRoomToken';
import { useAppDispatch } from '../../redux/hooks';
import { accountActions } from '../Account/slice';
import { roomActions } from './slice';
import useQueryPositionInfo from './useQueryPositionInfo';

const placeholderData = {
  agoraUid: 0,
  agoraCname: '',
  agoraRTCToken: '',
  agoraRTMToken: '',
};

function useQueryCurrentUserInfo() {
  const roomToken = useRoomToken();
  const dispatch = useAppDispatch();
  const { logout } = useAccount();
  const { data: { enterpriseName } = {} } = useQueryPositionInfo();
  return useQuery(
    'currentUserInfo',
    async () => {
      const type = getUserType();
      if (type === 1 || type === 2) {
        const [result, result2] = await Promise.all([
          AccountAPI.userInfo(type, roomToken),
          type === 1
            ? AccountAPI.getUserVirtualBackground()
            : Promise.resolve(),
        ]);
        if (result.code === 0) {
          dispatch(accountActions.setAccount(result.data));

          if (type === 1 && result2.code === 0) {
            dispatch(accountActions.setVirtualBackgroundInfo(result2.data));
          }

          if (result.data?.roomInfo?.mobileLinkUrl) {
            dispatch(
              roomActions.setMobileLinkUrl(result.data?.roomInfo?.mobileLinkUrl)
            );
          }
          if (result.data?.agoraUid) {
            log.identify(result.data?.agoraUid, {
              username: result.data?.userName,
              role: type,
              enterpriseName,
            });
          }
          return result.data;
        } else {
          logout();
        }
      }

      return placeholderData;
    },
    {
      placeholderData,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onError: () => {
        logout();
      },
    }
  );
}

export default useQueryCurrentUserInfo;
