import * as React from 'react';
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
} from '@mui/material';
import {
  Box,
  IconButton,
  KeyboardArrowDownIcon,
  Popover,
  Typography,
} from 'fbm-ui';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { roomActions } from './slice';
import TestDeviceDialog from './TestDeviceDialog';
import { formatDeviceLabel } from './helpers';
import { useTranslation } from 'react-i18next';

function Content({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const micsInfo = useAppSelector((state) => state.room.micsInfo);
  const selectedMicId = useAppSelector((state) => state.room.selectedMicId);
  const speakersInfo = useAppSelector((state) => state.room.speakersInfo);
  const selectedSpeakerId = useAppSelector(
    (state) => state.room.selectedSpeakerId
  );

  const handleChangeMic = (event: React.ChangeEvent<HTMLInputElement>) => {
    const deviceId = event.target.value;
    dispatch(roomActions.setSelectedMicId(deviceId));
  };

  const handleChangeSpeaker = (event: React.ChangeEvent<HTMLInputElement>) => {
    const deviceId = event.target.value;
    dispatch(roomActions.setSelectedSpeakerId(deviceId));
  };

  const handleOpenTestDeviceDialog = () => {
    onClose();
    dispatch(roomActions.setTestDeviceDialogIsOpen(true));
  };

  return (
    <Box width="360px" pt={2} px={3}>
      <Typography variant="body1">{t('Microphone')}</Typography>
      <RadioGroup
        value={selectedMicId}
        onChange={handleChangeMic}
        sx={{ mt: 1, gap: 1 }}
      >
        {micsInfo.map((option) => (
          <FormControlLabel
            key={option.deviceId}
            value={option.deviceId}
            control={<Radio />}
            label={formatDeviceLabel(
              option.label,
              option.deviceId === 'default'
            )}
          />
        ))}
      </RadioGroup>
      <Divider sx={{ margin: '16px 0px' }} />
      <Typography variant="body1">{t('Speaker')}</Typography>
      <RadioGroup
        value={selectedSpeakerId}
        onChange={handleChangeSpeaker}
        sx={{ mt: 1 }}
      >
        {speakersInfo.map((option) => (
          <FormControlLabel
            key={option.deviceId}
            value={option.deviceId}
            control={<Radio />}
            label={formatDeviceLabel(
              option.label,
              option.deviceId === 'default'
            )}
          />
        ))}
      </RadioGroup>
      <Divider sx={{ marginTop: '16px' }} />
      <MenuItem sx={{ p: 2 }} onClick={handleOpenTestDeviceDialog}>
        {t('Test Audio.')}
      </MenuItem>
    </Box>
  );
}

function MicPopover() {
  const [open, setOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setOpen((o) => !o);
  }, []);

  return (
    <>
      <Popover
        open={open}
        content={<Content onClose={handleToggle} />}
        onClickAway={handleToggle}
      >
        <IconButton size="small" onClick={handleToggle}>
          <KeyboardArrowDownIcon
            style={{
              transform: `rotate(${open ? '180deg' : '0deg'})`,
              transition: 'transform 0.2s',
            }}
          />
        </IconButton>
      </Popover>

      <TestDeviceDialog />
    </>
  );
}

export default MicPopover;
