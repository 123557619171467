import { Dialog, Form, FormItem, useForm, Typography, Button } from 'fbm-ui';
import LoginAPI from '../../api/login';
import { showErrorMessage } from '../../utils/apiCheck';

export type NameDialogProps = {
  open: boolean;
  applicationToken: string;
  onClose: () => void;
  onSubmit: (name: string) => void;
};

const NameDialog = (props: NameDialogProps) => {
  const { open, applicationToken, onClose, onSubmit } = props;
  const form = useForm({
    initialValues: {
      name: '',
    },
    onSubmit: (values) => {
      LoginAPI.updateCndidateInfo(applicationToken, { name: values.name })
        .then(onSubmit)
        .catch(showErrorMessage);
    },
  });

  return (
    <Dialog open={open} onClose={onClose} footer={null} width={480}>
      <Form {...form}>
        <Typography variant="h6" fontWeight={700} my={4} textAlign={'center'}>
          请完善姓名
        </Typography>
        <FormItem required name="name" label="姓名" max={15} />
        <Button fullWidth sx={{ mt: 1 }} onClick={form.submitForm}>
          提交
        </Button>
      </Form>
    </Dialog>
  );
};

export default NameDialog;
