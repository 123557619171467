import { KeyboardArrowRightIcon } from 'fbm-ui';

import { Box } from '..';

interface IDrawerWithHandle {
  isOpen: boolean;
  toggle: () => void;
  width?: number;
  duration?: number;
}

const LeftDrawerWithHandle: React.FC<IDrawerWithHandle> = ({
  children,
  isOpen,
  toggle,
  width = 320,
  duration = 250,
}) => {
  return (
    <Box position="relative">
      <Box
        onClick={toggle}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '24px',
          height: '56px',
          position: 'absolute',
          top: '50%',
          left: '0px',
          backgroundColor: 'white',
          borderRadius: '0px 8px 8px 0px',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
          cursor: 'pointer',
          zIndex: 3,
          transition: `transform ${duration}ms ease-in-out`,
          ...(isOpen
            ? {
                transform: `translateY(-50%) translateX(${width}px)`,
              }
            : {
                transform: 'translateY(-50%) translateX(0px)',
              }),
        }}
      >
        <Box
          display="flex"
          sx={{
            transition: `transform ${duration}ms ease-in-out`,
            ...(isOpen
              ? {
                  transform: 'rotate(180deg)',
                }
              : {
                  transform: 'rotate(0deg) translateY(-2px) translateX(2px)',
                }),
          }}
        >
          <KeyboardArrowRightIcon />
        </Box>
      </Box>
      {isOpen && (
        <Box
          sx={{
            width: '4px',
            height: '56px',
            position: 'absolute',
            top: '50%',
            right: '0px',
            backgroundColor: 'white',
            zIndex: 3,
            transition: `transform ${duration}ms ease-in-out`,
            ...(isOpen
              ? {
                  transform: `translateY(-50%) translateX(0px)`,
                }
              : {
                  transform: 'translateY(-50%) translateX(-4px)',
                }),
          }}
        />
      )}
      <Box
        sx={{
          height: '100%',
          backgroundColor: 'white',
          overflowY: 'auto',
          transition: `transform ${duration}ms ease-in-out, width ${duration}ms ease-in-out`,
          ...(isOpen
            ? {
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
                width: `${width}px`,
                transform: 'none',
              }
            : {
                width: '0px',
                transform: `translateX(-${width}px)`,
              }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default LeftDrawerWithHandle;
