import MuiTab, { TabProps } from '@mui/material/Tab';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface ITab extends TabProps {
  index: number;
}

function Tab(props: ITab) {
  const { index, ...others } = props;
  return <MuiTab {...others} {...a11yProps(index)} />;
}

export default Tab;
