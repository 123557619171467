import { useMutation } from 'react-query';
import { toast } from '@avocadoui/components';
import AccountAPI from '../../api/account';
import { log } from '@avocadoui/utils';

function useMutationSendCodeToEmail() {
  const mutation = useMutation(
    async ({ email, roomToken }: { email: string; roomToken: string }) => {
      const result = await AccountAPI.sendCodeToEmail(email, roomToken);
      return result;
    },
    {
      onSuccess: (res, { email }) => {
        if (res.code === 0) {
          toast.success('验证码发送成功');
          log.capture('SUCCESS:发送验证码', { account: email });
        } else {
          toast.error(res.message);
          log.capture('FAIL:发送验证码', {
            account: email,
            error: res.message,
          });
        }
      },
    }
  );
  return mutation;
}

export default useMutationSendCodeToEmail;
