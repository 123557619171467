import * as React from 'react';
import { toast } from '@avocadoui/components';
import { log, uniq } from '@avocadoui/utils';
import RoomAPI from '../../api/room';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { roomActions } from './slice';
import { useRoomSend } from './useMachineContext';
import useRoomToken from './useRoomToken';
import useRTC from './useRTC';
import useRTM from './useRTM';
import useQueryPositionInfo from './useQueryPositionInfo';

function useRoom() {
  const send = useRoomSend();
  const dispatch = useAppDispatch();
  const roomToken = useRoomToken();
  const { join: joinRTM, leave: leaveRTM } = useRTM();
  const { join: joinRTC, leave: leaveRTC } = useRTC();

  const userIdsAtPublished = useAppSelector(
    (state) => state.room.userIdsAtPublished
  );
  const userIdsAtInviting = useAppSelector(
    (state) => state.room.userIdsAtInviting
  );
  const ingUserIds = React.useMemo(
    () => Array.from(uniq([...userIdsAtPublished, ...userIdsAtInviting])),
    [userIdsAtPublished, userIdsAtInviting]
  );

  const userIdsAtRooms = useAppSelector((state) => state.room.userIdsAtRooms);
  const localUserId = useAppSelector((state) => state.room.localUserId);
  const focusedUserId =
    useAppSelector((state) => state.room.focusedUserId) ||
    userIdsAtPublished[0];

  const focusedIsLocalUser = React.useMemo(
    () => focusedUserId === localUserId,
    [focusedUserId, localUserId]
  );
  const unfocusedUserIds = React.useMemo(
    () =>
      focusedIsLocalUser
        ? userIdsAtPublished
        : userIdsAtPublished.filter((id) => id !== focusedUserId),
    [focusedIsLocalUser, userIdsAtPublished, focusedUserId]
  );

  const roomUsers = useAppSelector((state) => state.room.roomUsers);
  const onlineIntervieweeNum = useAppSelector(
    (state) => state.room.onlineIntervieweeNum
  );
  const beforeMeWaitingUserList = useAppSelector(
    (state) => state.room.beforeMeWaitingUserList
  );
  const beforeMeWaitingUserLength = React.useMemo(() => {
    const intervieweeIds = roomUsers
      .filter((r) => r.userType === 2)
      .map((r) => r.agoraUid.toString());
    return (
      beforeMeWaitingUserList.filter((uid) => intervieweeIds.includes(uid))
        .length - onlineIntervieweeNum
    );
  }, [roomUsers, beforeMeWaitingUserList, onlineIntervieweeNum]);

  // const joinedUserIds = React.useMemo(() => {
  //   console.log('joinedUserIds refreshed:', remoteUsersChanged);
  //   return RoomModel.rtcClient?.remoteUsers.map((u) => u.uid.toString());
  // }, [remoteUsersChanged]);

  const roomType = useAppSelector((state) => state.room.roomInfo.roomType);
  const processType = roomType === 1 ? 11 : 4;

  const { data: positionInfo } = useQueryPositionInfo();
  const isWaitMode = positionInfo?.model === 2;

  const isAgoraConnected = useAppSelector(
    (state) =>
      state.room.rtcConnectionState === 'CONNECTED' &&
      state.room.rtmConnectionState === 'CONNECTED'
  );

  const join = React.useCallback(async () => {
    try {
      await joinRTM();
      await joinRTC();
      log.capture('SUCCESS:加入房间');
    } catch (error) {
      console.log('=== join error:', error);
      log.capture('FAIL:加入房间', { error });
      throw new Error(error);
    } finally {
      console.log('=== join done');
    }
  }, [joinRTM, joinRTC]);

  const leave = React.useCallback(
    async (closeCamera = true) => {
      send('LEAVE');
      try {
        await leaveRTM();
        await leaveRTC(closeCamera);
        log.capture('SUCCESS:离开房间');
      } catch (error) {
        console.log('=== leave error:', error);
        log.capture('FAIL:离开房间', { error });
      } finally {
        console.log('=== leave done');
      }
    },
    [send, leaveRTM, leaveRTC]
  );

  const toggleInvitationModel = React.useCallback(
    async (model: 1 | 2) => {
      dispatch(roomActions.toggleInvitationModel());
      const result = await RoomAPI.setRoomModel(roomToken, model);
      if (result.code === 0) {
        if (model === 1) {
          toast.success('设置成功，候选人可自动进入视频');
        } else {
          toast.success('设置成功，候选人需手动邀请才能进入视频');
        }
      } else {
        toast.error('叫号模式切换失败');
        dispatch(roomActions.toggleInvitationModel());
      }
    },
    [dispatch, roomToken]
  );

  return {
    join,
    leave,
    ingUserIds,
    joinedUserIds: userIdsAtRooms,
    localUserId,
    focusedUserId,
    focusedIsLocalUser,
    unfocusedUserIds,
    toggleInvitationModel,
    beforeMeWaitingUserLength,
    processType,
    isWaitMode,
    isAgoraConnected,
  };
}

export default useRoom;
