import styles from './LoadingDots.module.css';

function LoadingDots() {
  return (
    <span className={styles.loading}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
}

export default LoadingDots;
