import { useMemo } from 'react';
import { AddCircleOutlineIcon } from 'fbm-icons';
import { Box, Message, Tooltip, Upload } from 'fbm-ui';
import CommonAPI from '../../api/common';
import { useAppDispatch } from '../../redux/hooks';
import { accountActions } from '../Account/slice';
import { COLOR } from '../../utils/const';
import { log } from '@avocadoui/utils';

const MAX_IMAGE_SIZE = 1024 * 1024 * 30;
const IMAGE_FILE_TYPE = ['jpg', 'jpeg', 'png'];

export function getSuffix(name: string) {
  let str = name;
  const index = str.lastIndexOf('.');
  str = str.substring(index + 1, str.length);
  return str;
}

interface IProps {
  actionsWrapperRef: React.RefObject<HTMLDivElement>;
  disabled: boolean;
  max: number;
}

function UploadBackgroundImage({ actionsWrapperRef, disabled, max }: IProps) {
  const dispatch = useAppDispatch();

  const props: any = useMemo(
    () => ({
      type: 'custom',
      name: 'file',
      showUploadList: false,
      accept: '.png,.jpg,.jpeg',
      maxCount: 1,
      onChange: (fileList) => {
        const file = fileList.file;
        if (file?.status === 'done') {
          const fileUrl = file?.response?.data[0].url;
          if (fileUrl) {
            dispatch(accountActions.addUploadImageAsVirtualBackground(fileUrl));
            const left = actionsWrapperRef.current.scrollWidth;
            setTimeout(() => {
              actionsWrapperRef.current.scrollTo({
                top: 0,
                left,
                behavior: 'smooth',
              });
            }, 0);
            log.capture('SUCCESS:上传背景图片', { fileUrl });
          }
        }
      },
      customRequest: async (options: any) => {
        const { file, onError, onSuccess } = options;
        const formData = new FormData();
        formData.append('files', file);
        try {
          const ret = await CommonAPI.upload(formData);
          onSuccess?.(ret);
        } catch (error) {
          onError?.(error as never);
        }
      },
      beforeUpload: (file) => {
        const fileType = getSuffix(file.name).toLowerCase();
        if (MAX_IMAGE_SIZE < file.size) {
          Message.warning('图片大小超过30M');
          return false;
        } else if (IMAGE_FILE_TYPE.indexOf(fileType) === -1) {
          Message.warning('不支持该格式的图片');
          return false;
        }
        return true;
      },
    }),
    [dispatch, actionsWrapperRef]
  );

  const tip = disabled ? `最多上传${max}张背景图片` : '添加图片';
  const color = disabled ? COLOR.DEFAULT : COLOR.PRIMARY;

  if (disabled) {
    return (
      <Tooltip title={tip} placement="top">
        <span>
          <Box
            sx={{
              width: 96,
              height: 54,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <AddCircleOutlineIcon sx={{ color }} />
          </Box>
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tip} placement="top">
      <span>
        <Upload {...props}>
          <Box
            sx={{
              width: 96,
              height: 54,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <AddCircleOutlineIcon sx={{ color }} />
          </Box>
        </Upload>
      </span>
    </Tooltip>
  );
}

export default UploadBackgroundImage;
