import { useEffectOnce } from 'usehooks-ts';
import { MicOffIcon } from 'fbm-ui';
import {
  Avatar,
  Box,
  Stack,
  Text,
  NetworkQualityIcon,
} from '@avocadoui/components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { isInterviewer } from '../../utils/localStorage';
import RoomModel from './RoomModel';
import { roomActions } from './slice';
import { COLOR } from '../../utils/const';

function LocalPlayerContainer() {
  const dispatch = useAppDispatch();
  const localUserId = useAppSelector((state) => state.room.localUserId);
  const maxAudioVolumeUserId = useAppSelector(
    (state) => state.room.maxAudioVolumeUserId
  );
  const isLocalAudioMuted = useAppSelector(
    (state) => state.room.isLocalAudioMuted
  );
  const isLocalVideoMuted = useAppSelector(
    (state) => state.room.isLocalVideoMuted
  );
  const localUsername = useAppSelector((state) => state.account.username);
  const localAvatar = useAppSelector((state) => state.account.avatar);
  const networkQualityScore = useAppSelector(
    (state) => state.room.networkQualityScore
  );

  useEffectOnce(() => {
    if (!RoomModel.localTracks.audioTrack) {
      dispatch(roomActions.setIsLocalAudioMuted(true));
    }
    if (!RoomModel.localTracks.videoTrack) {
      dispatch(roomActions.setIsLocalVideoMuted(true));
    }
  });

  return (
    <Box
      id="local-player-container"
      sx={{
        position: 'relative',
      }}
    >
      {isLocalVideoMuted && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: '#D8D8D8',
            zIndex: 1,
          }}
        >
          <Avatar size="extraLarge" name={localUsername} src={localAvatar} />
        </Box>
      )}
      {localUserId === maxAudioVolumeUserId && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            border: '4px solid #4CAF50',
            zIndex: 1,
          }}
        />
      )}
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        m={0.5}
        px={1}
        py={0.5}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '4px',
          zIndex: 1,
        }}
      >
        <Box
          display="flex"
          px={1}
          sx={{
            backgroundColor: 'white',
            border: '1px solid #D8D8D8',
            borderRadius: '8px',
          }}
        >
          <Text variant="caption" color="text.primary">
            {isInterviewer() ? '企业成员' : '候选人'}
          </Text>
        </Box>
        {(networkQualityScore > 0 || networkQualityScore === -1) && (
          <Box display="flex">
            <NetworkQualityIcon score={networkQualityScore} />
          </Box>
        )}
        {isLocalAudioMuted && (
          <MicOffIcon
            style={{ width: '16px', height: '16px', color: COLOR.SECONDARY }}
          />
        )}
        <Box display="flex">
          <Text variant="caption" color="text.primary">
            {localUsername}(我)
          </Text>
        </Box>
      </Stack>
    </Box>
  );
}

export default LocalPlayerContainer;
