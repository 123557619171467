import { Text } from '@avocadoui/components';
import useQueryPositionInfo from './useQueryPositionInfo';

function RoomPositionInfo() {
  const { data: positionInfoData } = useQueryPositionInfo();
  const { positionName, enterpriseName } = positionInfoData || {};

  if (!positionName) {
    return null;
  }

  return (
    <Text variant="body2" color="text.secondary">
      {enterpriseName} - {positionName}
    </Text>
  );
}

export default RoomPositionInfo;
