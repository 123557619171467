import { ArrowLeftIcon } from 'fbm-icons';
import { Box, Button, IconButton, Typography } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import Layout from './Layout';
import SimulateAPI from '../../api/simulate';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { showErrorMessage } from '../../utils/apiCheck';
import { Provider as ReduxProvider } from 'react-redux';
import store from '../../redux/store';
import { useTranslation } from 'react-i18next';

// status: 0, 未开始; 1, 进行中; 2, AI 生成中; 3, 已完成
// 如果status > 1 则显示报告列表 2
// 如果 流程中所有status > 1 并且 status ==== 3 并且isend === true 则显示再练一次

function timestampToDateAndTime(timestamp) {
  if (timestamp === '' || timestamp === 'NaN') {
    return '';
  }
  const now = new Date(parseInt(timestamp) * 1000);
  const y = now.getFullYear();
  const m = now.getMonth() + 1;
  const d = now.getDate();
  return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d} ${now
    .toTimeString()
    .substr(0, 5)}`;
}

function some(arr, testFunc) {
  for (let i = 0; i < arr.length; i++) {
    if (testFunc(arr[i])) {
      return true;
    }
  }
  return false;
}

function buttonType(isEnd, hasAIInterview, isAiInterveiwfinished) {
  if (isEnd) {
    if (!hasAIInterview) return 3;
    if (!isAiInterveiwfinished) return 1;
    if (hasAIInterview && isAiInterveiwfinished) return 3;
  }
  return 2;
}

const Simulate = () => {
  const loading = useRef(false);
  const { t } = useTranslation();
  const { type, token } = useParams();
  const navigate = useNavigate();

  function title(item) {
    if (item.status === 2) return t('Processing (Approx. 10 mins)');
    if (
      some(item.steps, function (v) {
        return v.scoreInfo.abilities.length > 0;
      })
    )
      return item.aiScore + t('Score');
    return '-';
  }
  function time(item) {
    if (item.status !== 3) return `${t('Report Time')}：-`;
    return `${t('Report Time')}：` + item.completeTimeFormat;
  }
  function formatDuration(item) {
    // const du = item.duration;
    // const status = item.status;
    // if (du === 0 || status !== 3) return '-';
    // const minutes = Math.floor(du / 60);
    // const seconds = du % 60;
    // const duStr =
    //   minutes > 0 ? minutes + '分' + seconds + '秒' : seconds + '秒';

    const du = item.duration;
    const status = item.status;
    if (du === 0 || status !== 3) return '-';

    return t('mmmsss', { duration: du });
  }

  const [processInfo, setProcessInfo] = useState({
    positionName: '',
    positionToken: '',
    postTime: '',
    enterpriseName: '',
    isEnd: false,
    hasAIInterview: false,
    isAiInterveiwfinished: false,
    list: [],
  });

  const { positionToken } = processInfo;

  const handleContinue = useCallback(() => {
    console.log(`/login/${positionToken}`);
    navigate(`/login/${positionToken}`);
  }, [navigate, positionToken]);

  const handleRetry = useCallback(() => {
    if (loading.current) return;
    loading.current = true;
    SimulateAPI.simulateRetry(token)
      .then((res) => {
        console.log(res);
        navigate(`/login/${positionToken}`);
      })
      .catch(showErrorMessage)
      .finally(() => {
        loading.current = false;
      });
  }, [navigate, positionToken, token]);

  useEffect(() => {
    SimulateAPI.simulateApplicationProcess(token)
      .then((res) => {
        const { list } = res;
        const filteredProcess = list?.filter((v) => v.status > 1) || [];
        setProcessInfo({
          ...res,
          list: filteredProcess,
          isAiInterveiwfinished:
            filteredProcess
              .filter((v) => !v.isHistory)
              .filter((v) => v.status === 3).length ===
            list.filter((v) => !v.isHistory).length,
        });
      })
      .catch(showErrorMessage);
  }, [token]);

  const handleBack = () => {
    navigate('/simulate/record');
  };

  const handleReport = (applicationProcessToken) => {
    navigate(`/report/${type}/${applicationProcessToken}`);
  };

  return (
    <ReduxProvider store={store}>
      <Layout>
        <Stack width={720}>
          <Box mb={2}>
            <IconButton onClick={handleBack}>
              <ArrowLeftIcon sx={{ color: 'rgba(0,0,0,0.86)' }} />
            </IconButton>
          </Box>
          <Stack
            spacing={1}
            sx={{
              p: 3,
              height: 102,
              borderRadius: '16px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
              backgroundImage:
                'linear-gradient(166deg, #C6E9F7 0%, #C2D5F2 100%)',
            }}
          >
            <Typography variant="h6" fontWeight="medium">
              {processInfo.positionName}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" color="textSecondary">
                {t('Start Tim')}：{timestampToDateAndTime(processInfo.postTime)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {processInfo.enterpriseName}
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="h6" fontWeight="medium" mt={4} mb={2}>
            {t('AI Mock Interview Report')}
          </Typography>
          {(!processInfo?.list || processInfo?.list?.length === 0) && (
            <Typography variant="body2" color="textSecondary">
              {t(
                'No Report Available, AI Mock Interview has not been completed yet.'
              )}
            </Typography>
          )}
          <Box
            sx={{
              minHeight: 400,
              display: 'grid',
              gridTemplateColumns: ' repeat(2, 1fr)',
              gap: 3,
              width: '100%',
              padding: 0,
            }}
          >
            {processInfo.list.map((item) => (
              <Stack
                key={item.applicationProcessToken}
                sx={{
                  p: 3,
                  pb: 2,
                  borderRadius: '16px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  height: 186,
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="medium"
                  mb={3}
                  color="rgb(76, 175, 80)"
                >
                  {title(item)}
                </Typography>
                <Typography variant="body2" mb={2}>
                  {time(item)}
                </Typography>
                <Typography variant="body2">
                  {t('Interview Duration')}：{formatDuration(item)}
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                  {item.status === 3 && (
                    <Button
                      color="inherit"
                      variant="outlined"
                      sx={{ fontWeight: 'medium' }}
                      onClick={() => handleReport(item.applicationProcessToken)}
                    >
                      {t('View Report')}
                    </Button>
                  )}
                </Box>
              </Stack>
            ))}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={4}
          >
            {buttonType(
              processInfo.isEnd,
              processInfo.hasAIInterview,
              processInfo.isAiInterveiwfinished
            ) === 3 && <Button onClick={handleRetry}>{t('Once More')}</Button>}
            {buttonType(
              processInfo.isEnd,
              processInfo.hasAIInterview,
              processInfo.isAiInterveiwfinished
            ) === 2 && (
              <Button onClick={handleContinue}>{t('Continue')}</Button>
            )}
          </Box>
        </Stack>
      </Layout>
    </ReduxProvider>
  );
};

export default Simulate;
