import {
  Box,
  Paper,
  Typography,
  Button,
  Autocomplete,
  FormItem,
  Message,
} from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import LoginAPI from '../../api/login';
import { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@avocadoui/components';
import * as z from 'zod';
import { showErrorMessage } from '../../utils/apiCheck';
import { useNavigate, useParams } from 'react-router-dom';
import NameDialog from './NameDialog';
import { canMobileLogin } from '../Interview/constant';

export type FormData = {
  schoolToken: string;
  code: string;
  name: string;
  password: string;
};

export const schema = z.object({
  schoolToken: z.string().nonempty({ message: '学校为必填项' }),
  code: z.string().nonempty({ message: '学号为必填项' }),
  name: z.string().nonempty({ message: '姓名为必填项' }),
  password: z.string().nonempty({ message: '密码为必填项' }),
});

const Student = () => {
  const token = localStorage.getItem('id_token');
  const { interviewToken = '' } = useParams();
  const [shooclList, setSchoolList] = useState([]);
  const [open, setOpen] = useState(false);
  const [tempApplicationToken, setTempApplicationToken] = useState('');
  const navigate = useNavigate();
  const resolveRef = useRef<() => void>();

  const { control, getValues, setValue, handleSubmit } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      code: '',
      schoolToken: '',
      name: '',
      password: '',
    },
    resolver: zodResolver(schema),
  });

  const handleLogin = () => {
    const params = getValues();
    LoginAPI.studentLogin(params)
      .then(({ token }) => {
        localStorage.setItem('id_token', token);
        return new Promise((resolve, reject) => {
          LoginAPI.getCndidateNextPrepare(interviewToken)
            .then(({ applicationToken }) => applicationToken)
            .then(LoginAPI.getCndidateInfo)
            .then(({ name, applicationToken }) => {
              if (!name) {
                setOpen(true);
                setTempApplicationToken(applicationToken);
                resolveRef.current = () => resolve({ applicationToken });
              } else {
                resolve({ applicationToken });
              }
            })
            .catch(reject);
        });
      })
      .then(({ applicationToken }) =>
        navigate(`/process/${applicationToken}`, { replace: true })
      )
      .catch(showErrorMessage);
  };

  useEffect(() => {
    const token = localStorage.getItem('id_token');
    if (token) {
      new Promise((resolve) => {
        LoginAPI.getCndidateNextPrepare(interviewToken)
          .then(({ applicationToken }) => applicationToken)
          .then(LoginAPI.getCndidateInfo)
          .then(({ name, applicationToken }) => {
            if (!name) {
              setOpen(true);
              resolveRef.current = () => resolve({ applicationToken });
            } else {
              resolve({ applicationToken });
            }
          })
          .catch(() => {
            localStorage.clear();
            window.location.reload();
          });
      })
        .then(({ applicationToken }) =>
          navigate(`/process/${applicationToken}`, { replace: true })
        )
        .catch(showErrorMessage);
    }
  }, [interviewToken, navigate]);

  useEffect(() => {
    LoginAPI.getSimulateSchools()
      .then(({ list }) => {
        setSchoolList(
          list.map((item) => ({ label: item.name, value: item.token }))
        );
      })
      .catch(showErrorMessage);
  }, []);

  if (token) {
    return (
      <NameDialog
        applicationToken={tempApplicationToken}
        open={open}
        onClose={() => ''}
        onSubmit={() => {
          setOpen(false);
          resolveRef.current?.();
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        background: '#F9FAF9',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <form>
        <Stack
          p={4}
          spacing={4}
          component={Paper}
          sx={{ width: 480 }}
          variant="outlined"
        >
          <Typography variant="h5" fontWeight={500}>
            学号登录
          </Typography>
          <Stack spacing={3}>
            <Controller
              control={control}
              name="schoolToken"
              render={({ field }) => (
                <FormItem {...field} label="学校" sx={{ height: 'auto' }}>
                  <Autocomplete
                    options={shooclList}
                    onChange={(_, option) => {
                      const value = option?.value || null;
                      setValue('schoolToken', value);
                    }}
                  />
                </FormItem>
              )}
            />
            <Controller
              control={control}
              name="name"
              render={({ field }) => <TextField {...field} label="姓名" />}
            />
            <Controller
              control={control}
              name="code"
              render={({ field }) => <TextField {...field} label="学号" />}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField {...field} label="密码" type="password" />
              )}
            />
          </Stack>
          <Stack spacing={3}>
            <Button
              size="large"
              onClick={handleSubmit(handleLogin, (errors) => {
                const errorOrder = ['schoolToken', 'code', 'name', 'password'];

                const firstErrorKey = errorOrder.find((key) => errors[key]);
                const firstErrorMessage = errors[firstErrorKey]?.message;
                Message.error(firstErrorMessage);
              })}
              variant="contained"
            >
              登录
            </Button>
            {canMobileLogin() && (
              <Button
                size="large"
                color="inherit"
                onClick={() => {
                  navigate(`/login/${interviewToken}`);
                }}
                variant="outlined"
              >
                手机号登录
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
      <NameDialog
        applicationToken={tempApplicationToken}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSubmit={() => {
          setOpen(false);
          resolveRef.current?.();
        }}
      />
    </Box>
  );
};

export default Student;
