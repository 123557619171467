import * as React from 'react';
import { useIsMounted } from 'usehooks-ts';
import {
  MicIcon,
  MicOffIcon,
  VideocamIcon,
  VideocamOffIcon,
  BeautyIcon,
  LeaveOutlineIcon,
  CallEndIcon,
  CommentIcon,
  PeopleIcon,
  VideoStopIcon,
  VideoRecordIcon,
  VideoPauseIcon,
  BeautyOffIcon,
  Box,
  InfoIcon,
} from 'fbm-ui';
import {
  Badge,
  Stack,
  ConfirmDialog,
  useDialog,
  toast,
  Tooltip,
} from '@avocadoui/components';
import { sleep, log } from '@avocadoui/utils';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useRTC from './useRTC';
import ToggleButton from './ToggleButton';
import { commentActions } from '../Comment/slice';
import { isInterviewee, isInterviewer } from '../../utils/localStorage';
import useRTM from './useRTM';
import RoomAPI from '../../api/room';
import useRoomToken from './useRoomToken';
import { roomActions } from './slice';
import { COLOR } from '../../utils/const';
import { getPermissionTip } from '../../utils/tips';
import VirtualBackgroundPopover from './VirtualBackgroundPopover';
import { WaterDropIcon } from 'fbm-icons';

function ControllerLayout() {
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const { kickAllUsers } = useRTM();
  const {
    toggleLocalAudio,
    toggleLocalVideo,
    toggleBeautyEffect,
    toggleBlurEffect,
  } = useRTC();
  const roomToken = useRoomToken();
  const {
    isOpen: isDestroyRoomOpen,
    onOpen: onDestroyRoomOpen,
    onClose: onDestroyRoomClose,
  } = useDialog();
  const {
    isOpen: isLeaveRoomOpen,
    onOpen: onLeaveRoomOpen,
    onClose: onLeaveRoomClose,
  } = useDialog();
  const {
    isOpen: isStopRecordingOpen,
    onOpen: onStopRecordingOpen,
    onClose: onStopRecordingClose,
  } = useDialog();

  const isLocalAudioMuted = useAppSelector(
    (state) => state.room.isLocalAudioMuted
  );
  const isLocalVideoMuted = useAppSelector(
    (state) => state.room.isLocalVideoMuted
  );
  const isLocalAudioPermissionDenied = useAppSelector(
    (state) => state.room.isLocalAudioPermissionDenied
  );
  const isLocalVideoPermissionDenied = useAppSelector(
    (state) => state.room.isLocalVideoPermissionDenied
  );
  const isBeautyEffectEnabled = useAppSelector(
    (state) => state.room.isBeautyEffectEnabled
  );
  const isBlurEffectEnabled = useAppSelector(
    (state) => state.account.virtualOpen === 1
  );
  const isUserListDrawerOpen = useAppSelector(
    (state) => state.room.isUserListDrawerOpen
  );
  const isCommentOpen = useAppSelector((state) => state.comment.isCommentOpen);
  const newCommentCount = useAppSelector(
    (state) => state.comment.newCommentCount
  );
  const isRecordRoomType = useAppSelector(
    (state) => state.room.roomInfo.roomType === 1
  );
  const isMeetingRoomType = useAppSelector((state) =>
    [0, 2].includes(state.room.roomInfo.roomType)
  );
  const channelStatus = useAppSelector(
    (state) => state.room.roomInfo.channelStatus
  );
  const [isDestroying, setIsDestroying] = React.useState(false);

  // const disabledStopRecording = React.useMemo(
  //   () => channelStatus === 0,
  //   [channelStatus]
  // );

  const handleToggleComment = React.useCallback(() => {
    dispatch(commentActions.toggleComment());
  }, [dispatch]);

  const handleToggleUserListDrawer = React.useCallback(() => {
    dispatch(roomActions.toggleUserListDrawer());
  }, [dispatch]);

  const handleLeave = React.useCallback(() => {
    dispatch(roomActions.leaveRoomDispatched(1));
  }, [dispatch]);

  const handleDestroy = React.useCallback(async () => {
    try {
      setIsDestroying(true);
      await kickAllUsers();
      RoomAPI.destroyRoom(roomToken);
      dispatch(roomActions.leaveRoomDispatched(2));
      log.capture('SUCCESS:结束房间');
    } catch (error) {
      console.error('handleDestroy:', error);
    } finally {
      setTimeout(() => {
        if (isMounted()) {
          setIsDestroying(false);
        }
      }, 0);
    }
  }, [kickAllUsers, roomToken, dispatch, isMounted]);

  const [disabledToggleRecording, setDisabledToggleRecording] =
    React.useState(false);
  const handleToggleRecording = React.useCallback(async () => {
    try {
      setDisabledToggleRecording(true);
      if (channelStatus === 0) {
        await RoomAPI.startSingleRecord(roomToken, 1);
        dispatch(roomActions.toggleRecording(1));
        toast.success('录制已开始');
        log.capture('SUCCESS:开始录制');
      } else if ([1, 3].includes(channelStatus)) {
        await RoomAPI.stopSingleRecord(roomToken, 1);
        dispatch(roomActions.toggleRecording(2));
        toast.success('录制已暂停');
        log.capture('SUCCESS:暂停录制');
      } else if (channelStatus === 2) {
        await RoomAPI.startSingleRecord(roomToken, 2);
        dispatch(roomActions.toggleRecording(3));
        toast.success('录制已继续');
        log.capture('SUCCESS:继续录制');
      }
    } catch (error) {
      console.error('handleToggleRecording:', error);
    } finally {
      await sleep(5000); // 防止短时间内暂停或停止录制
      setDisabledToggleRecording(false);
    }
  }, [dispatch, channelStatus, roomToken]);

  const handleStopRecording = React.useCallback(async () => {
    setDisabledToggleRecording(true);
    try {
      setIsDestroying(true);
      await kickAllUsers();
      await RoomAPI.stopSingleRecord(roomToken, 2);
      dispatch(roomActions.leaveRoomDispatched(4));
      log.capture('SUCCESS:结束录制');
    } catch (error) {
      console.error('handleStopRecording:', error);
    } finally {
      setTimeout(() => {
        if (isMounted()) {
          setIsDestroying(false);
          setDisabledToggleRecording(false);
        }
      }, 0);
    }
  }, [kickAllUsers, roomToken, dispatch, isMounted]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        sx={{
          height: '72px',
          backgroundColor: 'white',
          borderTop: '1px solid rgba(0, 0, 0, 0.08)',
          zIndex: 1,
        }}
      >
        <Stack direction="row" spacing={1}>
          {isLocalAudioPermissionDenied ? (
            <Tooltip title={getPermissionTip('audio')} placement="top">
              <span>
                <Box sx={{ position: 'relative' }}>
                  <ToggleButton
                    icon={<MicOffIcon style={{ color: COLOR.DISABLED }} />}
                  >
                    麦克风
                    <Box sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                      <InfoIcon sx={{ width: 16, color: '#F5C441' }} />
                    </Box>
                  </ToggleButton>
                </Box>
              </span>
            </Tooltip>
          ) : (
            <ToggleButton
              icon={
                isLocalAudioMuted ? (
                  <MicOffIcon style={{ color: COLOR.SECONDARY }} />
                ) : (
                  <MicIcon style={{ color: COLOR.DEFAULT }} />
                )
              }
              onClick={toggleLocalAudio}
            >
              麦克风
            </ToggleButton>
          )}
          {isLocalVideoPermissionDenied ? (
            <Tooltip title={getPermissionTip('video')} placement="top">
              <span>
                <Box sx={{ position: 'relative' }}>
                  <ToggleButton
                    icon={<VideocamOffIcon style={{ color: COLOR.DISABLED }} />}
                  >
                    摄像头
                    <Box sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                      <InfoIcon sx={{ width: 16, color: '#F5C441' }} />
                    </Box>
                  </ToggleButton>
                </Box>
              </span>
            </Tooltip>
          ) : (
            <ToggleButton
              icon={
                isLocalVideoMuted ? (
                  <VideocamOffIcon style={{ color: COLOR.SECONDARY }} />
                ) : (
                  <VideocamIcon style={{ color: COLOR.DEFAULT }} />
                )
              }
              onClick={toggleLocalVideo}
            >
              摄像头
            </ToggleButton>
          )}
          <ToggleButton
            icon={
              isBeautyEffectEnabled ? (
                <BeautyIcon style={{ color: COLOR.PRIMARY }} />
              ) : (
                <BeautyOffIcon style={{ color: COLOR.DEFAULT }} />
              )
            }
            onClick={toggleBeautyEffect}
            disabled={isLocalVideoMuted}
          >
            美颜
          </ToggleButton>
          {isInterviewer() && <VirtualBackgroundPopover />}
          {isInterviewee() && (
            <ToggleButton
              icon={
                isBlurEffectEnabled ? (
                  <WaterDropIcon style={{ color: COLOR.PRIMARY }} />
                ) : (
                  <WaterDropIcon style={{ color: COLOR.DEFAULT }} />
                )
              }
              onClick={toggleBlurEffect}
              disabled={isLocalVideoMuted}
            >
              背景虚化
            </ToggleButton>
          )}
          <Badge
            badgeContent={newCommentCount}
            color="secondary"
            sx={{
              cursor: 'pointer',
              '& .MuiBadge-badge': {
                top: '8px',
                right: '8px',
              },
            }}
            onClick={handleToggleComment}
          >
            <ToggleButton
              icon={<CommentIcon style={{ color: COLOR.DEFAULT }} />}
              onClick={handleToggleComment}
              isOpen={isCommentOpen}
            >
              评论
            </ToggleButton>
          </Badge>
          {isInterviewer() && (
            <ToggleButton
              icon={<PeopleIcon style={{ color: COLOR.DEFAULT }} />}
              onClick={handleToggleUserListDrawer}
              isOpen={isUserListDrawerOpen}
            >
              成员
            </ToggleButton>
          )}
        </Stack>
        {isRecordRoomType && isInterviewer() && (
          <Stack direction="row" spacing={1}>
            <ToggleButton
              icon={
                [0, 2].includes(channelStatus) ? (
                  <VideoRecordIcon style={{ color: COLOR.SECONDARY }} />
                ) : (
                  <VideoPauseIcon style={{ color: COLOR.SECONDARY }} />
                )
              }
              onClick={handleToggleRecording}
              disabled={disabledToggleRecording}
            >
              {channelStatus === 0 && '开始录制'}
              {[1, 3].includes(channelStatus) && '暂停录制'}
              {channelStatus === 2 && '继续录制'}
            </ToggleButton>
            {channelStatus !== 0 && (
              <ToggleButton
                icon={<VideoStopIcon style={{ color: COLOR.SECONDARY }} />}
                onClick={onStopRecordingOpen}
                disabled={disabledToggleRecording}
              >
                停止录制
              </ToggleButton>
            )}
          </Stack>
        )}
        {isMeetingRoomType && (
          <Stack direction="row" spacing={1}>
            <ToggleButton
              icon={<LeaveOutlineIcon style={{ color: COLOR.SECONDARY }} />}
              onClick={onLeaveRoomOpen}
            >
              离开
            </ToggleButton>
            {isInterviewer() && (
              <ToggleButton
                icon={<CallEndIcon style={{ color: COLOR.SECONDARY }} />}
                onClick={onDestroyRoomOpen}
              >
                结束
              </ToggleButton>
            )}
          </Stack>
        )}
      </Stack>
      <ConfirmDialog
        isOpen={isDestroyRoomOpen}
        onClose={onDestroyRoomClose}
        title="确定要结束面试吗？"
        desc="结束面试将解散所有人且无法再次进入面试间，请谨慎操作。"
        confirmTxt="结束"
        variant="alert"
        onConfirm={handleDestroy}
        isConfirming={isDestroying}
      />
      <ConfirmDialog
        isOpen={isLeaveRoomOpen}
        onClose={onLeaveRoomClose}
        desc="确认离开？"
        confirmTxt="离开"
        variant="alert"
        onConfirm={handleLeave}
      />
      <ConfirmDialog
        isOpen={isStopRecordingOpen}
        onClose={onStopRecordingClose}
        desc="确认结束录制？"
        confirmTxt="结束"
        variant="alert"
        onConfirm={handleStopRecording}
        isConfirming={isDestroying}
      />
    </>
  );
}

export default ControllerLayout;
