function getUserType() {
  const typeString = localStorage.getItem('user_type') ?? '0';
  const type = +typeString;
  return type;
}

function isInterviewer() {
  return getUserType() === 1;
}

function isInterviewee() {
  return getUserType() === 2;
}

export { getUserType, isInterviewer, isInterviewee };
