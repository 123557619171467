import { useEffect } from 'react';
// import { isEmpty } from 'lodash';
// import AccountAPI from '../api/account';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { loginActions } from '../modules/LoginForm/slice';
import codes from './codes.json';
import en from './en.json';
import { useLocation } from 'react-router-dom';

function formatMobileCode(n: any, lang) {
  return {
    ...n,
    label: lang === 'en' ? en[n?.area] : n?.area,
    value: `+${n?.val}`,
    group: n?.group === 'Hot' && lang !== 'en' ? '热门' : n?.group,
  };
}

function useMobileCodes() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang');
  const mobileCodes = useAppSelector(({ login }) => login.mobileCodes);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (isEmpty(mobileCodes)) {
    // console.log('codes', lang);
    const formatted = codes.map((code) => formatMobileCode(code, lang));
    dispatch(loginActions.setMobileCodes(formatted));
    // AccountAPI.getMobileCodes()
    //   .then((res) => {
    //     if (res.code === 0) {
    //       const formatted = (res.data?.list || [])?.map(formatMobileCode);
    //       dispatch(loginActions.setMobileCodes(formatted));
    //     }
    //   })
    //   .catch((e) => console.log(e));
    // }
  }, [dispatch, lang]);

  return {
    mobileCodes,
  };
}

export default useMobileCodes;
