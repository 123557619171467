import clsx from 'clsx';
import styles from './SoundIsPlayingIcon.module.css';

interface IProps {
  isPlaying: boolean;
}

function SoundIsPlayingIcon({ isPlaying }: IProps) {
  return (
    <div className={styles.symbol}>
      <div
        className={clsx(styles.circle, styles.first, {
          [styles.play]: isPlaying,
        })}
      />
      <div
        className={clsx(styles.circle, styles.second, {
          [styles.play]: isPlaying,
        })}
      />
      <div
        className={clsx(styles.circle, styles.third, {
          [styles.play]: isPlaying,
        })}
      />
    </div>
  );
}

export default SoundIsPlayingIcon;
