import * as React from 'react';
import {
  // Divider,
  IconButton,
  // ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { Avatar } from '@avocadoui/components';

import useAccount from './useAccount';
// import { useAppSelector } from '../../redux/hooks';
import useQueryCurrentUserInfo from '../Room/useQueryCurrentUserInfo';

function AccountMenu() {
  const { logout } = useAccount();
  // const { username, avatar } = useAppSelector((state) => state.account);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: currentUserInfo } = useQueryCurrentUserInfo();
  const { userName: username, avatar } = currentUserInfo || {};

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  if (!username) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar src={avatar} name={username} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleLogout}>
          {/* <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon> */}
          退出登录
        </MenuItem>
      </Menu>
    </>
  );
}

export default AccountMenu;
