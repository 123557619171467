import { render, version } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { log } from '@avocadoui/utils';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';
import store from './redux/store';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Toaster } from '@avocadoui/components';
// import startMockWorker from './startMockWorker';
import './i18n';
import ErrorBoundary from './ErrorBoundary';
import React from 'react';
import { Helmet } from 'react-helmet';
import { IS_OFFICIAL, OEM } from './oem';

log.initPostHog('phc_yjgl36XoPiTEQm5SjzJM11wVfvEO4tQq8V8fIbP7mtU', {
  api_host: 'https://app.posthog.com',
  disable_session_recording: true,
});
log.initEliTracker('app-rtc');

// log.onFeatureFlags(() => {
//   if (log.isFeatureEnabled('session-recording')) {
//     console.log('session-recording is enabled');
//     log.startSessionRecording();
//   } else {
//     console.log('session-recording is disabled');
//     log.stopSessionRecording();
//   }
// });

function prepareMockWorker() {
  if (process.env.NODE_ENV === 'development') {
    // return startMockWorker();
  }
  return Promise.resolve();
}

console.log('ReactDOM version:', version);
console.log('createRoot:', !!createRoot);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20,
      notifyOnChangeProps: 'tracked',
    },
  },
});

const Main = () => (
  // 暂不使用 StrictMode
  // via https://github.com/reactwg/react-18/discussions/19
  // <React.StrictMode>
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toaster />
          <Helmet>
            <meta charSet="utf-8" />
            <title>{IS_OFFICIAL ? '方便面招聘' : OEM.name}</title>
            <meta
              name="description"
              content={IS_OFFICIAL ? '方便面面试 让面试更方便' : OEM.name}
            />
            <link rel="shortcut icon" href={OEM.shortcut} />
          </Helmet>
          <App />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ReduxProvider>
  </ErrorBoundary>
  // </React.StrictMode>
);

prepareMockWorker().then(() => {
  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('Failed to find the root element');

  if (createRoot !== undefined) {
    const root = createRoot(rootElement);
    root.render(<Main />);
  } else {
    render(<Main />, rootElement);
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
