import * as z from 'zod';

// source: https://github.com/VincentSit/ChinaMobilePhoneNumberRegex
export const mobileRegex =
  /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[0-35-9]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|6[2567]\d{2}|4(?:(?:10|4[01])\d{3}|[68]\d{4}|[579]\d{2}))\d{6}$/;

export const mobileSchema = z.object({
  mobile: z.object({
    mobile: z.string().regex(mobileRegex),
    mobileAreaCode: z.string(),
  }),
  isAgreementChecked: z.boolean(),
});
export const anyMobileSchema = z.object({
  mobile: z.object({
    mobile: z.string().min(1),
    mobileAreaCode: z.string(),
  }),
  isAgreementChecked: z.boolean(),
});
export const emailSchema = z.object({
  email: z.string().email(),
  isAgreementChecked: z.boolean(),
});
