import { apiCheck } from '../utils/apiCheck';
import client from './client';

function upload(formData: any) {
  return client(
    'common/upload',
    {
      body: formData,
    },
    true
  );
}

const getReportForm = (token) =>
  client(`aiInterview/report/web/form/${token}`).then(apiCheck);
const getSimulateSettings = () => client(`simulate/settings`).then(apiCheck);

const CommonAPI = {
  upload,
  getReportForm,
  getSimulateSettings,
};

export default CommonAPI;
