import * as React from 'react';
import useRoom from './useRoom';

const useMemoVideoColumns = () => {
  const { ingUserIds } = useRoom();

  const columns = React.useMemo(() => {
    const userLength = ingUserIds.length;
    if (userLength === 0) return 1;
    else if ([1, 2, 3].includes(userLength)) return 2;
    else return 3;
  }, [ingUserIds]);

  return columns;
};

export default useMemoVideoColumns;
