import * as React from 'react';

import { Stack, Text } from '@avocadoui/components';

interface IProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  onClick?: () => void;
  isOpen?: boolean;
  disabled?: boolean;
}

function ToggleButton({
  children,
  icon,
  onClick,
  isOpen = false,
  disabled = false,
}: IProps) {
  const backgroundColor = isOpen
    ? { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
    : {};

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (disabled) return;
      onClick?.();
    },
    [onClick, disabled]
  );

  return (
    <Stack
      py={0.5}
      px={1}
      spacing={0.5}
      alignItems="center"
      minWidth="54px"
      sx={
        disabled
          ? { opacity: 0.5 }
          : {
              cursor: 'pointer',
              borderRadius: '4px',
              ...backgroundColor,
              ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.06)',
              },
            }
      }
      onClick={handleClick}
    >
      {icon}
      <Text variant="caption" color="text.secondary">
        {children}
      </Text>
    </Stack>
  );
}

export default ToggleButton;
