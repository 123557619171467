import Avatar, { IAvatar } from './Avatar';
import styles from './PulsingAvatar.module.css';

interface IProps extends IAvatar {
  isPulsing: boolean;
}

function PulsingAvatar({ isPulsing, ...others }: IProps) {
  if (isPulsing) {
    return (
      <div className={styles.avatar}>
        <Avatar {...others} />
      </div>
    );
  }

  return <Avatar {...others} />;
}

export default PulsingAvatar;
