import { AIInterview, AIPreviewCheck, AIWelcome } from '../modules/Interview';
import { Stack } from 'fbm-ui/lib/mui';
import { Provider as ReduxProvider } from 'react-redux';
import store from '../redux/store';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useEffect } from 'react';
import { interviewActions } from '../modules/Interview/slice';
import Layout from '../modules/Interview/Layout';

import { RoomContextProvider } from '../modules/Room/useMachineContext';

function Views() {
  const dispatch = useAppDispatch();
  const { page } = useAppSelector((state) => state.interview);

  useEffect(() => {
    return () => {
      dispatch(interviewActions.setInitialState());
    };
  }, [dispatch]);

  return (
    <Layout showPosition={page === 2}>
      <Stack width={1} height={1} display="flex" alignItems="center">
        {page === 0 && <AIPreviewCheck />}
        {page === 1 && <AIWelcome />}
        {page === 2 && <AIInterview />}
      </Stack>
    </Layout>
  );
}
export default function Home() {
  return (
    <RoomContextProvider>
      <ReduxProvider store={store}>
        <Views />
      </ReduxProvider>
    </RoomContextProvider>
  );
}
