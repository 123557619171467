// import { useEffectOnce } from 'usehooks-ts';

import { Box } from '@mui/material';

// const HOMEPAGE_URL = 'https://fbmms.cn/';

export default function Home() {
  // useEffectOnce(() => {
  //   window.location.replace(HOMEPAGE_URL);
  // });

  return <Box>success</Box>;
}
