import { toast } from '@avocadoui/components';
import { log } from '@avocadoui/utils';
import { useMutation, useQueryClient } from 'react-query';
import RoomAPI from '../../api/room';
import useRoomToken from './useRoomToken';

interface IPositionInfo {
  enterpriseName: string;
  positionName: string;
  status: 0 | 1 | 2 | 3;
  model: 1 | 2; // 1:自动进入，无需邀请 2:叫号模式
}

function useMutationRoomModel() {
  const roomToken = useRoomToken();
  const queryClient = useQueryClient();
  const queryKey = ['positionInfo', roomToken];

  const mutation = useMutation(
    async ({ model }: { model: IPositionInfo['model'] }) => {
      return await RoomAPI.setRoomModel(roomToken, model);
    },
    {
      onMutate: async ({ model }) => {
        await queryClient.cancelQueries(queryKey);

        const previous = queryClient.getQueryData<IPositionInfo>(queryKey);
        if (previous) {
          queryClient.setQueryData<IPositionInfo>(queryKey, {
            ...previous,
            model,
          });
        }
        return { previous };
      },
      onSuccess: (res, { model }) => {
        if (res.code === 0) {
          if (model === 1) {
            toast.success('设置成功，候选人可自动进入视频');
            log.capture('SUCCESS:关闭叫号模式');
          } else {
            toast.success('设置成功，候选人需手动邀请才能进入视频');
            log.capture('SUCCESS:开启叫号模式');
          }
        } else {
          toast.error('叫号模式切换失败');
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
  return mutation;
}

export default useMutationRoomModel;
