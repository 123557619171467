import { log } from '@avocadoui/utils';
import { useMutation } from 'react-query';
import AccountAPI from '../../api/account';
import { useAppDispatch } from '../../redux/hooks';
import { roomActions } from './slice';
import useRoomToken from './useRoomToken';

function useMutationLogin() {
  const roomToken = useRoomToken();
  const dispatch = useAppDispatch();

  const mutation = useMutation(
    async ({
      mode,
      mobile,
      mobileAreaCode,
      email,
      code,
    }: {
      mode: number;
      mobile: string;
      mobileAreaCode: string;
      email: string;
      code: string;
    }) => {
      if (mode === 0)
        return await AccountAPI.loginByMobile(
          roomToken,
          mobile,
          mobileAreaCode,
          code
        );
      if (mode === 1)
        return await AccountAPI.loginByEmail(roomToken, email, code);
    },
    {
      onSuccess: (res, { mode, mobile, mobileAreaCode, email, code }) => {
        const account = mode === 0 ? { mobile, mobileAreaCode } : email;
        if (res.code === 0) {
          log.capture('SUCCESS:登录', { account, code });
          const user = res.data;
          localStorage.setItem('id_token', user.token);
          localStorage.setItem('user_type', user.type);
          if (user.mobileLinkUrl) {
            dispatch(roomActions.setMobileLinkUrl(user.mobileLinkUrl));
          }
        } else {
          log.capture('FAIL:登录', { account, code, error: res.message });
        }
      },
    }
  );
  return mutation;
}

export default useMutationLogin;
