import { useEffect } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const useInvterviewStatus = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const room = useAppSelector((state) => state.interview);
  const { isAllFinished } = room;

  useEffect(() => {
    if (isAllFinished) {
      navigate(`/process/${token}`);
    }
  }, [isAllFinished, navigate, token]);
};

export default useInvterviewStatus;
