import { Box, Button } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { interviewActions } from './slice';
import { useCallback, useMemo } from 'react';
import { XiaomianPlayer } from './Xiaomian';
import { INTERVIEW_TYPE } from './utils/Interview';
import BackTitle from './BackTitle';
import { useTranslation } from 'react-i18next';

const PreviewCheck = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { interviewInfo, nextProcess } = useAppSelector(
    (state) => state.interview
  );
  const { interaction, interactType, xmSoundUrl } = interviewInfo || {};
  const {
    guidanceText = '',
    guidanceVideo = '',
    aiInterviewerProfile,
    guidanceVideoWide = '',
  } = interaction || {};
  console.log(interviewInfo, interactType, interaction, aiInterviewerProfile);

  console.log(
    '>>>>',
    INTERVIEW_TYPE(interactType, interaction?.interviewerType)
  );

  const isShowVideo = guidanceVideoWide || guidanceVideo;

  const interviewType = INTERVIEW_TYPE(
    interactType,
    interaction?.interviewerType
  );
  const video = useMemo(
    () => nextProcess.process.videos.find((ele) => ele.type === 2),
    [nextProcess]
  );

  const handleNext = useCallback(() => {
    dispatch(interviewActions.setPage(2));
  }, [dispatch]);

  const videos = {
    normal: guidanceVideoWide || guidanceVideo || video?.pcUrl,
    robot: guidanceVideoWide || guidanceVideo || xmSoundUrl,
    virtualHuman:
      interviewInfo?.virtualManMediaWide || interviewInfo?.virtualManMedia,
  };

  return (
    <Box
      width={1}
      height="100vh"
      sx={{
        background: '#F9FAF9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        width={1087}
        height={612}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <video
          src={videos[interviewType]}
          width="100%"
          height="100%"
          autoPlay
          style={{
            display:
              interviewType !== 'robot' || isShowVideo ? 'block' : 'none',
          }}
        />
        {interviewType === 'robot' && !isShowVideo && (
          <XiaomianPlayer action="taking" />
        )}
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 1,
            width: 1,
            alignItems: 'center',
            top: 0,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignSelf: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box p={2}>
              <BackTitle title={nextProcess?.position?.name} />
            </Box>
          </Stack>
          <Box width={1}>
            {guidanceText && (
              <Box
                width="calc(100% - 48px)"
                borderRadius={2}
                bgcolor="rgba(255, 255, 255, 0.9)"
                p={1.5}
                fontSize="body1"
                fontWeight={500}
                color="textSecondary"
                mx={3}
                pb={6}
                sx={{ position: 'absolute' }}
                bottom={16}
              >
                {guidanceText}
              </Box>
            )}
            <Stack flexDirection="row" my={3} justifyContent="center">
              <Button onClick={handleNext}>{t("Let's GO!")}</Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewCheck;
