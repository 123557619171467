import type { InputImage } from '@mediapipe/face_detection';
import { FaceDetection, Results } from '@mediapipe/face_detection';
import { report, upload } from './api';
import type { CheatParams, MateData } from './api';

const isEmptyArray = (a: any[]) => a && a.length === 0;

const setCheatValue =
  (cheat: CheatParams, key: keyof CheatParams) => (value: string | number) => ({
    ...cheat,
    [key]: value,
  });

const sceneRules = (
  last: CheatParams,
  current: CheatParams,
  all: CheatParams[]
) => {
  if (isEmptyArray(all) || !last) {
    return 1;
  } else if (last.faces !== current.faces) {
    if (last.faces === 0) {
      return 2;
    } else if (last.faces > current.faces) {
      return 4;
    } else {
      return 3;
    }
  }
  return 0;
};

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  if (arr.length > 0) {
    const mime = arr[0]?.match(/:(.*?);/)?.[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}

const last = (arr: any[]) => {
  if (arr.length === 0) return;
  return arr[arr.length - 1];
};

class FaceAnti {
  private cheats: CheatParams[] = [];
  private mateData = {};
  private cheatKey = '';
  private authorization = '';
  private faceDetection?: FaceDetection;

  constructor(cheatKey: string, mateData: MateData, authorization: string) {
    this.cheatKey = cheatKey;
    this.mateData = mateData;
    this.authorization = authorization;
    this.initialize();
  }
  private initialize() {
    const faceDetection = new FaceDetection({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${file}`;
      },
    });
    faceDetection.setOptions({
      selfieMode: true,
      model: 'short',
      minDetectionConfidence: 0.5,
    });
    faceDetection.onResults(this.onResults.bind(this));
    this.faceDetection = faceDetection;
  }
  onResults(results: Results) {
    if (results) {
      const { detections, image } = results;
      const cheat = {
        cheatKey: this.cheatKey,
        faces: detections.length,
        scene: 1,
        photo: '',
        metaData: this.mateData,
      };

      const setCheatImage = setCheatValue(cheat, 'photo');
      const lastCheat = last(this.cheats);

      if (isEmptyArray(this.cheats) || lastCheat.faces !== cheat.faces) {
        this.cheats.push(cheat);
        if (cheat.faces !== 0) {
          const imageData = (image as HTMLCanvasElement).toDataURL();
          const file = dataURLtoFile(imageData, 'image');
          if (file) {
            upload(file, this.authorization)
              .then((data) => {
                if (Array.isArray(data)) {
                  return data.at(0);
                } else {
                  throw new Error('upload image fail');
                }
              })
              .then((item) => item?.url)
              .then(setCheatImage)
              .then((c) =>
                setCheatValue(c, 'scene')(sceneRules(lastCheat, c, this.cheats))
              )
              .then((c) => report(c, this.authorization))
              .catch(console.log);
          }
        }
      }
    }
  }
  async check(input: InputImage) {
    await this.faceDetection?.send({ image: input });
  }
}

export default FaceAnti;
