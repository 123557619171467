import { CloseIcon } from 'fbm-ui';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@mui/material';

import { Box, Text } from '../';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onOverlayClick?: () => void;
  title?: React.ReactNode;
  desc?: React.ReactNode;
  actions?: React.ReactNode;
  showCloseBtn?: boolean;
  size?: keyof typeof sizes;
}

const sizes = {
  small: '360px',
  large: '100%',
};

function Dialog({
  isOpen,
  onClose,
  onOverlayClick,
  title,
  desc,
  actions,
  showCloseBtn = false,
  size = 'small',
}: IProps) {
  return (
    <MuiDialog
      open={isOpen}
      onClose={onOverlayClick}
      aria-labelledby="custom-dialog-title"
      aria-describedby="custom-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          width: sizes[size],
          padding: '16px 16px 12px 24px',
        },
      }}
    >
      {!!title && (
        <>
          {typeof title === 'string' ? (
            <Text
              id="custom-dialog-title"
              variant="body1"
              color="text.primary"
              fontWeight="fontWeightBold"
              mb={2}
            >
              {title}
            </Text>
          ) : (
            title
          )}
        </>
      )}
      {!!desc && (
        <DialogContent sx={{ p: 0, '&:first-of-type': { pt: 1 } }}>
          {typeof desc === 'string' ? (
            <DialogContentText
              id="custom-dialog-description"
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                color: 'rgba(0, 0, 0, 0.56)',
              }}
            >
              {desc}
            </DialogContentText>
          ) : (
            <Box
              mb={1.5}
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                color: 'rgba(0, 0, 0, 0.56)',
              }}
            >
              {desc}
            </Box>
          )}
        </DialogContent>
      )}
      {!!actions && <DialogActions sx={{ p: 0 }}>{actions}</DialogActions>}
      {showCloseBtn && (
        <IconButton
          aria-label="close"
          size="small"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 14,
            color: (theme) => theme.palette.text.primary,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialog>
  );
}

export default Dialog;
