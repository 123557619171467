import mobile from 'is-mobile';

function getWindowWidth() {
  return window.screen?.width < window.outerWidth
    ? window.screen?.width
    : window.outerWidth;
}

export default function isMobile(): boolean {
  return mobile() || getWindowWidth() < 768;
}
