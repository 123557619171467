import { Box, Typography } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import Layout from './Layout';
import { useNavigate, useParams } from 'react-router-dom';
import SimulateAPI from '../../api/simulate';
import { useEffect, useState } from 'react';
import { showErrorMessage } from '../../utils/apiCheck';
import { useAppDispatch } from '../../redux/hooks';
import { interviewActions } from './slice';
import { useTranslation } from 'react-i18next';

// http://localhost:3000/simulate

function hashFunction(t) {
  let hash = 0;
  if (t.length === 0) {
    return hash;
  }
  for (let i = 0; i < t.length; i++) {
    const char = t.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash % 6); // Ensure index is within 0-8
}

const BACKGROUNDS = [
  'https://webcdn.fbmms.cn/assets/HgRR/I8FFT4Ld1JusJIQC_W1vI',
  'https://webcdn.fbmms.cn/assets/vThz/1zS1wxcxf3z7egIHCYBIU',
  'https://webcdn.fbmms.cn/assets/LLPK/3AfXIfHrFUeCo4sF0tzo1',
  'https://webcdn.fbmms.cn/assets/0MSc/nyqD508GuNDWZOQQ2WLkM',
  'https://webcdn.fbmms.cn/assets/VKxw/qP0b0333sVSwo4G2n-cOg',
  'https://webcdn.fbmms.cn/assets/BfWS/Ei7GIWHPDHIrV-bjNzFnH',
];

const EnterpriseSimulate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { type = 'list' } = useParams();
  const [simulatePositions, setSimulatePositions] = useState([]);
  const [simulateRecords, setSimulateRecords] = useState([]);

  const handleSimulate = (applicationToken) => {
    navigate(`/simulate/${type}/${applicationToken}`);
  };

  const handleSimulatePostion = (positionToken) => {
    navigate(`/login/${positionToken}`);
  };

  useEffect(() => {
    SimulateAPI.simulatePositions()
      .then((res) => {
        setSimulatePositions(res.list);
        dispatch(
          interviewActions.setEnterprise({
            name: res.enterpriseName,
            logo: res.enterpriseLogo,
          })
        );
      })
      .catch(showErrorMessage);

    SimulateAPI.simulateApplications()
      .then((res) => {
        setSimulateRecords(res.list);
      })
      .catch(showErrorMessage);
  }, [dispatch]);

  return (
    <Layout>
      {type === 'list' && (
        <Stack width={1096}>
          <Stack spacing={4}>
            {simulatePositions?.map((item) => (
              <Stack spacing={3}>
                <Typography variant="h6" fontWeight="medium">
                  {item.category}
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: ' repeat(4, 1fr)',
                    gap: 3,
                    width: '100%',
                    padding: 0,
                  }}
                >
                  {item?.positions?.map((position) => (
                    <Stack
                      onClick={() => {
                        handleSimulatePostion(position.positionToken);
                      }}
                      sx={{
                        px: 3,
                        pt: 3,
                        pb: 2,
                        cursor: 'pointer',
                        justifyContent: 'space-between',
                        height: 144,
                        borderRadius: 2,
                        // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                        background: `url(${
                          BACKGROUNDS[hashFunction(position.positionToken)]
                        }) no-repeat center center`,
                        backgroundSize: 'cover',
                      }}
                    >
                      <Typography variant="body1" fontWeight="medium">
                        {position.positionName}
                      </Typography>
                      <Typography variant="body2">
                        {position.categoryName}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}

      {type === 'record' && (
        <Stack width={1096}>
          <Stack spacing={4}>
            {simulateRecords?.map((item) => (
              <Stack spacing={3}>
                <Typography variant="h6" fontWeight="medium">
                  {item.categoryName}
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: ' repeat(4, 1fr)',
                    gap: 3,
                    width: '100%',
                    padding: 0,
                  }}
                >
                  {item.items.map((position) => (
                    <Stack
                      onClick={() => handleSimulate(position.applicationToken)}
                      sx={{
                        px: 3,
                        pt: 3,
                        pb: 2,
                        cursor: 'pointer',
                        justifyContent: 'space-between',
                        height: 172,
                        borderRadius: 2,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                        backgroundImage:
                          'linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(203, 221, 246, 1) 100%)',
                      }}
                    >
                      <Typography variant="body1" fontWeight="medium">
                        {position.positionName}
                      </Typography>
                      <Typography variant="body2" mt={4}>
                        {t('Start Tim')}：{position.postTimeFormat}
                      </Typography>
                      <Typography variant="body2">
                        {position.enterpriseName}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </Layout>
  );
};

export default EnterpriseSimulate;
