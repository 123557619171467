import React, { useRef, useCallback } from 'react';
import {
  Box,
  Button,
  CloseIcon,
  DoneIcon,
  Message,
  PlayOutlineIcon,
  PowerIcon,
  SpeedIcon,
  Typography,
} from 'fbm-ui';
import { Paper, Stack } from 'fbm-ui/lib/mui';
import {
  TypeItem,
  useTypewriter,
  Pusher,
  Finish,
  TrainingFinish,
} from '../modules/Typewriter';
import {
  AlarmIcon,
  BackspaceIcon,
  ChartIcon,
  VideoPauseOutlineIcon,
} from 'fbm-icons';
import { useParams, useNavigate } from 'react-router-dom';

const Typewriter = () => {
  const navigate = useNavigate();
  const { applicationToken, interviewToken, articleToken, mode } = useParams();
  const ref = useRef(null);

  const {
    title,
    articleTitle,
    standard,
    timeToString,
    duration,
    liveUrl,
    status,
    bgImg,
    items,
    setNextInput,
    setPrevInput,
    handlePause,
    handleStart,
    handleWordChange,
    typingInfo,
    isEnd,
    nextPinyin,
    isStart,
    result,
    pauseStatus,
    reTryNumber,
  } = useTypewriter(interviewToken, articleToken, ref, mode);

  const handleQuit = () => {
    ref.current?.stopPush();
    navigate(`/t/login/${applicationToken}/${interviewToken}`);
  };

  const handlePusherError = useCallback(() => {
    navigate(`/t/login/${applicationToken}/${interviewToken}`);
  }, [applicationToken, interviewToken, navigate]);

  const handleTotalPause = useCallback(() => {
    if (isStart?.current) {
      Message.warning('暂停中... 10分钟后自动退出');
      handlePause();
    } else {
      Message.success('继续...');
      handleStart();
    }
  }, [handlePause, handleStart, isStart]);

  const isNotPractice = mode === '1';

  if (status === -1) return null;
  if (status === 3 && isNotPractice) {
    navigate(`/process/${applicationToken}`);
    return null;
  }

  if (!isNotPractice && isEnd)
    return <TrainingFinish bgImg={bgImg} typingInfo={typingInfo} />;
  return (
    <Box
      display="flex"
      justifyContent="center"
      py={3}
      sx={{
        background: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      height="100vh"
    >
      <Stack direction="row" spacing={3}>
        <Stack component={Paper} width={900} py={3} pl={2} pr={3}>
          <Typography variant="h5" fontWeight={500}>
            {title}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            color="#FFA107"
            mt={2}
            mb={1}
          >
            <Typography variant="body2" color="inherit">
              开始输入时计时！
            </Typography>
            <Typography variant="body2" color="inherit">
              文章名称： {articleTitle}
            </Typography>
          </Stack>
          <Stack flex={1} spacing={2} overflow="scroll">
            {items.map((item, index) => (
              <TypeItem
                key={index}
                index={index}
                disabled={item.disabled}
                content={item.text}
                handleWordChange={handleWordChange}
                nextInput={setNextInput}
                prevInput={setPrevInput}
                onChange={handleStart}
              ></TypeItem>
            ))}
          </Stack>
        </Stack>
        <Stack component={Paper} flex={1} width={320} p={2}>
          {isNotPractice && liveUrl && (
            <Pusher
              liveUrl={liveUrl}
              ref={ref}
              onPusherError={handlePusherError}
            />
          )}
          <Stack
            my={3}
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
          >
            <Stack justifyContent="center" alignItems="center">
              <AlarmIcon></AlarmIcon>
              <Typography variant="body2">倒计时</Typography>
            </Stack>
            <Typography
              my={3}
              variant="h4"
              color="#FFA107"
              fontWeight={500}
              fontSize={30}
            >
              {timeToString}
            </Typography>
            <Typography variant="caption">
              （设定时间：{duration}分钟）
            </Typography>
          </Stack>
          <Typography mb={3}>{standard}</Typography>
          <Stack spacing={2} mb={2}>
            <Stack direction="row" flex={1} spacing={1} height={64}>
              <SpeedIcon htmlColor="#FFC107"></SpeedIcon>
              <Typography>速度：{typingInfo.typingSpeed} 字/分钟</Typography>
            </Stack>
            <Stack direction="row" flex={1} spacing={1} height={64}>
              <DoneIcon color="primary"></DoneIcon>
              <Typography>正确率：{typingInfo.typingAccuracy} %</Typography>
            </Stack>
            <Stack direction="row" flex={1} spacing={1} height={64}>
              <CloseIcon color="error"></CloseIcon>
              <Typography>错误：{typingInfo.typingError} 字</Typography>
            </Stack>
            <Stack direction="row" flex={1} spacing={1} height={64}>
              <ChartIcon color="warning"></ChartIcon>
              <Typography>总字数：{typingInfo.total} 字</Typography>
            </Stack>
            <Stack direction="row" flex={1} spacing={1} height={64}>
              <BackspaceIcon color="info"></BackspaceIcon>
              <Typography>退格：{typingInfo.typingBackspace} 次</Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              borderTop: '1px solid rgba(0, 0, 0, 0.08)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
            }}
            py={2}
            px={6}
          >
            <Typography variant="body2">拼音：{nextPinyin}</Typography>
          </Stack>
          <Stack>
            <Stack
              direction="row"
              flex={1}
              height={64}
              spacing={3}
              mt={3}
              justifyContent="center"
            >
              <Button
                color="inherit"
                variant="outlined"
                size="large"
                onClick={handleTotalPause}
                startIcon={
                  pauseStatus ? <VideoPauseOutlineIcon /> : <PlayOutlineIcon />
                }
              >
                {pauseStatus ? '暂停' : '继续'}
              </Button>
              <Button
                color="inherit"
                variant="outlined"
                size="large"
                onClick={handleQuit}
                startIcon={<PowerIcon />}
              >
                退出
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {result && (
        <Finish
          status={result?.passStatus}
          typingInfo={result}
          retry={reTryNumber}
        />
      )}
    </Box>
  );
};
export default Typewriter;
