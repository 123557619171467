import * as React from 'react';
import clsx from 'clsx';
import { useIsMounted, useEffectOnce } from 'usehooks-ts';

import { Avatar, Box, Stack, Text, toast } from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';
import { dayjs, scrollToBottom } from '@avocadoui/utils';
import styles from './Comment.module.css';
import { ICommentItem } from './slice';
import useMutationAddComment from './useMutationAddComment';

function formatDate(timestamp: number) {
  const hour = `${dayjs(timestamp).hour()}`.padStart(2, '0');
  const minute = `${dayjs(timestamp).minute()}`.padStart(2, '0');
  return `${hour}:${minute}`;
}

function CommentItem({ uid, type, message, createdAt }: ICommentItem) {
  const roomUsers = useAppSelector((state) => state.room.roomUsers);
  const localUserId = useAppSelector((state) => state.room.localUserId);
  const user = roomUsers.find((u) => u.agoraUid.toString() === uid);
  const isMe = uid === localUserId;

  if (user) {
    return (
      <Stack direction="row" marginTop="auto">
        <Stack direction="row" spacing={1}>
          {type === 1 && (
            <>
              <Avatar
                size="small"
                name={user.userName}
                src={user.avatar}
                sx={{ marginTop: '6px' }}
              />
              <Stack>
                <Text
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    wordBreak: 'break-all',
                  }}
                >
                  {user.userName}
                  {isMe && '(我)'}
                  {!!createdAt && (
                    <Box display="inline-block" ml={1}>
                      {formatDate(createdAt)}
                    </Box>
                  )}
                </Text>
                <Text
                  variant="body2"
                  color="text.primary"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {message}
                </Text>
              </Stack>
            </>
          )}
          {type === 2 && (
            <>
              <Avatar size="small" name={user.userName} src={user.avatar} />
              <Stack direction="row" alignItems="center">
                <Text
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    wordBreak: 'break-all',
                  }}
                >
                  {user.userType === 1 ? '企业成员' : '候选人'}
                  {user.userName}
                  {message}
                </Text>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    );
  }

  return null;
}

function CommentInput() {
  const isMounted = useIsMounted();
  const [comment, setComment] = React.useState('');
  const [commentComposition, setCommentComposition] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const localUserId = useAppSelector((state) => state.room.localUserId);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const isOnCompositionRef = React.useRef(false);

  const { mutate: addComment } = useMutationAddComment();

  useEffectOnce(() => {
    inputRef.current?.focus();
  });

  const handleComposition = (
    event: React.CompositionEvent<HTMLInputElement>
  ) => {
    if (event.type === 'compositionstart') {
      isOnCompositionRef.current = true;
    }

    if (event.type === 'compositionend') {
      isOnCompositionRef.current = false;
      setCommentComposition(comment);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newComment = event.target.value;
    setComment(newComment);

    // if (!isOnCompositionRef.current) {}

    // 若在末尾输入，则滑动至末尾
    const isAtEnd = newComment.indexOf(commentComposition) === 0;
    const node = inputRef.current;
    if (isAtEnd && node) node.scrollLeft = node.scrollWidth;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const message = comment.trim();
    if (!message) return;

    setIsSubmitting(true);
    addComment(
      { uid: localUserId, message },
      {
        onSuccess: () => {
          setComment('');
        },
        onError: () => {
          toast.error('评论发送失败');
        },
        onSettled: () => {
          if (isMounted()) setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <input
        ref={inputRef}
        type="text"
        value={comment}
        onChange={handleChange}
        placeholder="评论"
        className={clsx(styles.input, { [styles.disabled]: isSubmitting })}
        onCompositionStart={handleComposition}
        onCompositionEnd={handleComposition}
        disabled={isSubmitting}
      />
    </form>
  );
}

function CommentList() {
  const ref = React.useRef<HTMLDivElement>(null);
  const list = useAppSelector((state) => state.comment.commentList);

  React.useEffect(() => {
    const node = ref.current;
    if (node) {
      scrollToBottom(node);
    }
  }, [list]);

  if (list.length === 0) {
    return null;
  }

  return (
    <Stack
      ref={ref}
      spacing={1}
      maxHeight="310px"
      sx={{
        overflowY: 'auto',
      }}
      className={styles.list}
    >
      {list.map(({ uid, type, message, createdAt }) => (
        <CommentItem
          key={createdAt}
          uid={uid}
          type={type}
          message={message}
          createdAt={createdAt}
        />
      ))}
    </Stack>
  );
}

function Comment() {
  return (
    <Stack
      spacing={2}
      padding={2}
      sx={{
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '4px',
      }}
    >
      <CommentList />
      <CommentInput />
    </Stack>
  );
}

export default Comment;
