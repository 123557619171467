import { useEffectOnce } from 'usehooks-ts';

type BeforeunloadHandler = (evt: BeforeUnloadEvent) => void;

function useEffectPreventTabClose() {
  useEffectOnce(() => {
    const handleTabClose: BeforeunloadHandler = (event) => {
      event.preventDefault();
      return (event.returnValue = '确认离开?');
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  });
}

export default useEffectPreventTabClose;
