interface INetworkQuality {
  uplinkNetworkQuality: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  downlinkNetworkQuality: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

function fromQualityToScore(stats: INetworkQuality) {
  const { uplinkNetworkQuality, downlinkNetworkQuality } = stats;
  let score = 0;
  if (
    [0].includes(uplinkNetworkQuality) ||
    [0].includes(downlinkNetworkQuality)
  ) {
    score = 0;
  } else if (
    [5, 6].includes(uplinkNetworkQuality) ||
    [5, 6].includes(downlinkNetworkQuality)
  ) {
    score = 1;
  } else if (
    [3, 4].includes(uplinkNetworkQuality) ||
    [3, 4].includes(downlinkNetworkQuality)
  ) {
    score = 2;
  } else {
    score = 3;
  }
  if (uplinkNetworkQuality === 6 && downlinkNetworkQuality === 6) {
    score = -1;
  }
  return score;
}

export default fromQualityToScore;
