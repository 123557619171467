import Logger from 'eli-fe-track';

function createConfig(name) {
  return {
    Base: {
      platform: 'web',
      version: '1.0.0',
      appId: name,
      SlsTracker: true,
    },
    SlsTracker: {
      host: 'cn-beijing.log.aliyuncs.com',
      project: 'web-frontend',
      logstore: name,
      time: 10,
      count: 10,
    },
  };
}

const logger = {
  init: (name) => {
    Logger.config(createConfig(name));
    Logger.sendSysInfo();
  },
  logLifeCycle: (name, params) => {
    Logger.logLifeCycle(name, params);
  },
};

export default logger;
