import * as React from 'react';
import { dayjs } from '@avocadoui/utils';

interface IProps {
  timestamp: number;
  withoutSuffix?: boolean;
}

function FromNow({ timestamp, withoutSuffix = false }: IProps) {
  const [text, setText] = React.useState(
    dayjs(timestamp).fromNow(withoutSuffix)
  );

  React.useEffect(() => {
    const id = setInterval(() => {
      setText(dayjs(timestamp).fromNow(withoutSuffix));
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [timestamp, withoutSuffix]);

  return <span>{text}</span>;
}

export default FromNow;
