import { Box } from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';
import Comment from '../Comment/Comment';

function CommentLayout() {
  const isCommentOpen = useAppSelector((state) => state.comment.isCommentOpen);

  if (isCommentOpen) {
    return (
      <Box
        width="320px"
        sx={{ position: 'absolute', left: 148, bottom: 4, zIndex: 3 }}
      >
        <Comment />
      </Box>
    );
  }

  return null;
}

export default CommentLayout;
