import { styled, Box } from '@mui/material';

export const StyledVideo = styled(Box)(
  ({ size }: { size: 'small' | 'large' }) =>
    size === 'small'
      ? {
          width: 293,
          height: 164,
          position: 'absolute',
          overflow: 'hidden',
          right: 16,
          top: 16,
          // background: 'rgb(249, 250, 249)',
          borderRadius: 8,
        }
      : {
          width: 1087,
          height: 612,
          position: 'relative',
          borderRadius: 8,
          overflow: 'hidden',
          mt: 6,
        }
);
