import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { RemoteInvitation, RtmStatusCode } from 'agora-rtm-sdk';
import useSound from 'use-sound';
import {
  Box,
  Button,
  Paper,
  Stack,
  Tab,
  TabPanel,
  Tabs,
  Text,
  useDialog,
  Dialog,
  ConfirmDialog,
  LoadingDots,
} from '@avocadoui/components';
import { dayjs } from '@avocadoui/utils';
import useRTM from './useRTM';
import RoomModel from './RoomModel';
import { useAppSelector } from '../../redux/hooks';
import { useRoomSend } from './useMachineContext';
import PositionInfoTab from './PositionInfoTab';
import ResumeTab from './ResumeTab';
import { DIAL_SOUND_URL, HANGUP_SOUND_URL } from './const';
import useEffectRoomOnlinePing from './useEffectRoomOnlinePing';
import useRoom from './useRoom';
import useEffectCheckOnlineIntervieweeNum from './useEffectCheckOnlineIntervieweeNum';
import useQueryRoomUsersInfo from './useQueryRoomUsersInfo';

const tabData = ['职位要求', '我的简历'];

function RoomWaiting() {
  const send = useRoomSend();
  const { join: joinRTM, leave: leaveRTM } = useRTM();
  const { beforeMeWaitingUserLength } = useRoom();
  const [tab, setTab] = React.useState(0);
  const {
    isOpen: isInvitationOpen,
    onOpen: onInvitationOpen,
    onClose: onInvitationClose,
  } = useDialog();
  const {
    isOpen: isActiveRefuseOpen,
    onOpen: onActiveRefuseOpen,
    onClose: onActiveRefuseClose,
  } = useDialog();
  const {
    isOpen: isPassiveRefuseOpen,
    onOpen: onPassiveRefuseOpen,
    onClose: onPassiveRefuseClose,
  } = useDialog();
  const roomStartTime = useAppSelector((state) => state.account.roomStartTime);
  const roomEndTime = useAppSelector((state) => state.account.roomEndTime);

  const [playDialSound, { stop: stopDialSound }] = useSound(DIAL_SOUND_URL, {
    loop: true,
  });
  const [playHangupSound] = useSound(HANGUP_SOUND_URL);

  const handleJoin = React.useCallback(() => {
    joinRTM();
  }, [joinRTM]);

  const handleLeave = React.useCallback(() => {
    leaveRTM();
  }, [leaveRTM]);

  const remoteInvitationRef = React.useRef<RemoteInvitation | null>(null);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleRemoteInvitationFailure = React.useCallback(
    (reason: RtmStatusCode.RemoteInvitationFailureReason) => {
      if (reason === 'INVITATION_EXPIRE') {
        handleLeave();
        onInvitationClose();
        onPassiveRefuseOpen();
        stopDialSound();
        playHangupSound();
      }
    },
    [
      handleLeave,
      onInvitationClose,
      onPassiveRefuseOpen,
      stopDialSound,
      playHangupSound,
    ]
  );

  const handleRemoteInvitationCanceled = React.useCallback(() => {
    handleLeave();
    onInvitationClose();
    onPassiveRefuseOpen();
    stopDialSound();
    playHangupSound();
  }, [
    handleLeave,
    onInvitationClose,
    onPassiveRefuseOpen,
    stopDialSound,
    playHangupSound,
  ]);

  const handleRemoteInvitationReceived = React.useCallback(
    (remoteInvitation: RemoteInvitation) => {
      remoteInvitationRef.current = remoteInvitation;
      remoteInvitationRef.current.on(
        'RemoteInvitationFailure',
        handleRemoteInvitationFailure
      );
      remoteInvitationRef.current.on(
        'RemoteInvitationCanceled',
        handleRemoteInvitationCanceled
      );

      onInvitationOpen();
      playDialSound();
    },
    [
      onInvitationOpen,
      handleRemoteInvitationFailure,
      handleRemoteInvitationCanceled,
      playDialSound,
    ]
  );

  const handleAccept = React.useCallback(() => {
    try {
      if (remoteInvitationRef.current) {
        remoteInvitationRef.current.accept();
        send('NEXT');
      }
      onInvitationClose();
      stopDialSound();
    } catch (error) {
      console.log('handleAccept error:', error);
    }
  }, [send, onInvitationClose, stopDialSound]);

  const handleRefuse = React.useCallback(() => {
    try {
      remoteInvitationRef.current?.refuse();
      handleLeave();
      onInvitationClose();
      onActiveRefuseOpen();
      stopDialSound();
      playHangupSound();
    } catch (error) {
      console.log('handleRefuse error:', error);
    }
  }, [
    handleLeave,
    onInvitationClose,
    onActiveRefuseOpen,
    stopDialSound,
    playHangupSound,
  ]);

  React.useEffect(() => {
    RoomModel.rtmClient?.on(
      'RemoteInvitationReceived',
      handleRemoteInvitationReceived
    );

    return () => {
      RoomModel.rtmClient?.off(
        'RemoteInvitationReceived',
        handleRemoteInvitationReceived
      );
    };
  }, [handleRemoteInvitationReceived]);

  React.useEffect(() => {
    remoteInvitationRef.current?.on(
      'RemoteInvitationFailure',
      handleRemoteInvitationFailure
    );
    return () => {
      remoteInvitationRef.current?.off(
        'RemoteInvitationFailure',
        handleRemoteInvitationFailure
      );
    };
  }, [handleRemoteInvitationFailure]);

  React.useEffect(() => {
    remoteInvitationRef.current?.on(
      'RemoteInvitationCanceled',
      handleRemoteInvitationCanceled
    );
    return () => {
      remoteInvitationRef.current?.off(
        'RemoteInvitationCanceled',
        handleRemoteInvitationCanceled
      );
    };
  }, [handleRemoteInvitationCanceled]);

  useEffectOnce(() => {
    RoomModel.rtmChannel?.sendMessage({
      text: JSON.stringify({ type: 'JOIN_WAITING_ROOM' }),
    });
  });

  useEffectOnce(() => {
    return () => {
      RoomModel.rtmChannel?.sendMessage({
        text: JSON.stringify({ type: 'LEAVE_WAITING_ROOM' }),
      });
    };
  });

  useEffectRoomOnlinePing();
  useEffectCheckOnlineIntervieweeNum();

  useQueryRoomUsersInfo();

  return (
    <>
      <Box flex={1} sx={{ backgroundColor: '#F9FAF9' }}>
        <Box maxWidth="720px" margin="auto">
          <Box mt={10} textAlign="center">
            <Text variant="body1" color="text.primary">
              视频时间：
              {`${dayjs(roomStartTime * 1000).hour()}`.padStart(2, '0')}:
              {`${dayjs(roomStartTime * 1000).minute()}`.padStart(2, '0')} -{' '}
              {`${dayjs(roomEndTime * 1000).hour()}`.padStart(2, '0')}:
              {`${dayjs(roomEndTime * 1000).minute()}`.padStart(2, '0')}
            </Text>
            <Text
              mt={2}
              variant="h5"
              color="text.primary"
              fontWeight="fontWeightBold"
            >
              等待企业成员邀请加入
              <LoadingDots />
            </Text>
            {beforeMeWaitingUserLength > 0 ? (
              <Text mt={2} variant="body1" sx={{ color: '#F5C441' }}>
                前面还有{beforeMeWaitingUserLength}位
              </Text>
            ) : (
              <Box mt={2} sx={{ height: '24px' }} />
            )}
          </Box>
          <Box mt={5}>
            <Paper
              sx={{
                width: '100%',
                border: 1,
                borderColor: 'divider',
                borderRadius: '4px',
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Tabs value={tab} onChange={handleChangeTab} centered>
                  {tabData.map((label, index) => (
                    <Tab key={`${index}${label}`} index={index} label={label} />
                  ))}
                </Tabs>
              </Box>

              <TabPanel value={tab} index={0}>
                <PositionInfoTab />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ResumeTab />
              </TabPanel>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Dialog
        isOpen={isInvitationOpen}
        onClose={onInvitationClose}
        title={
          <Text
            variant="body1"
            color="text.primary"
            fontWeight="fontWeightBold"
            mb={2}
          >
            对方邀请你开始视频沟通
            <LoadingDots />
          </Text>
        }
        actions={
          <Stack direction="row" spacing={1}>
            <Button variant="contained" color="primary" onClick={handleAccept}>
              接受
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleRefuse}>
              拒绝
            </Button>
          </Stack>
        }
      />
      <ConfirmDialog
        isOpen={isActiveRefuseOpen}
        onClose={onActiveRefuseClose}
        desc="你已离线，请准备好后继续等待视频邀请。"
        confirmTxt="准备好了"
        onConfirm={handleJoin}
        showCancelBtn={false}
      />
      <ConfirmDialog
        isOpen={isPassiveRefuseOpen}
        onClose={onPassiveRefuseClose}
        desc="你刚刚错过了视频邀请，系统已经将你离线，请准备好后继续等待视频邀请。"
        confirmTxt="准备好了"
        onConfirm={handleJoin}
        showCancelBtn={false}
      />
    </>
  );
}

export default RoomWaiting;
