interface IProp {
  score: -1 | 0 | 1 | 2 | 3;
}

function NetworkQualityIcon({ score }: IProp) {
  if (score === 3) {
    return (
      <div style={{ width: '16px', height: '16px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              fill="#4CAF50"
              d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3a4.237 4.237 0 00-6 0z"
            />
            <path
              fill="#4CAF50"
              d="M5 13l2 2a7.074 7.074 0 0110 0l2-2C15.14 9.14 8.87 9.14 5 13z"
            />
          </g>
        </svg>
      </div>
    );
  }

  if (score === 2) {
    return (
      <div style={{ width: '16px', height: '16px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              fill="#000"
              fillOpacity=".26"
              d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9z"
            />
            <path
              fill="#F5C441"
              d="M9 17l3 3 3-3a4.237 4.237 0 00-6 0zm-4-4l2 2a7.074 7.074 0 0110 0l2-2C15.14 9.14 8.87 9.14 5 13z"
            />
          </g>
        </svg>
      </div>
    );
  }

  if (score === 1) {
    return (
      <div style={{ width: '16px', height: '16px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              fill="#000"
              fillOpacity=".26"
              d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9z"
            />
            <path fill="#FF6C6C" d="M9 17l3 3 3-3a4.237 4.237 0 00-6 0z" />
            <path
              fill="#000"
              fillOpacity=".26"
              d="M5 13l2 2a7.074 7.074 0 0110 0l2-2C15.14 9.14 8.87 9.14 5 13z"
            />
          </g>
        </svg>
      </div>
    );
  }

  if (score === -1) {
    return (
      <div style={{ width: '16px', height: '16px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <defs>
            <filter id="wifi_off" colorInterpolationFilters="auto">
              <feColorMatrix
                in="SourceGraphic"
                values="0 0 0 0 1.000000 0 0 0 0 0.423529 0 0 0 0 0.423529 0 0 0 1.000000 0"
              />
            </filter>
          </defs>
          <g
            fill="none"
            fillRule="evenodd"
            filter="url(#wifi_off)"
            transform="translate(-1380 -189)"
          >
            <path d="M1404 189.01c0-.01 0-.01 0 0l-24-.01v24h24v-23.99zm-24-.01h24v24h-24v-24zm0 0h24v24h-24v-24z" />
            <path
              fill="#000"
              fillRule="nonzero"
              d="M1402.99 198c-3.84-3.84-9.19-5.24-14.15-4.22l2.52 2.52c3.47-.17 6.99 1.05 9.63 3.7l2-2zm-4 4a9.793 9.793 0 00-4.49-2.56l3.53 3.53.96-.97zm-16.99-9.95l3.07 3.05c-1.47.72-2.85 1.68-4.07 2.9l1.99 2c1.24-1.24 2.67-2.16 4.2-2.77l2.24 2.24A9.684 9.684 0 001385 202v.01l1.99 1.99a7.042 7.042 0 014.92-2.06l7.07 7.06 1.27-1.26-16.96-16.95-1.29 1.26zm7 13.95l3 3 3-3a4.237 4.237 0 00-6 0z"
            />
          </g>
        </svg>
      </div>
    );
  }

  return null;
}

export default NetworkQualityIcon;
