import * as React from 'react';
import { LeftDrawerWithHandle } from '@avocadoui/components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { roomActions } from './slice';

interface IProps {
  children: React.ReactNode;
}

function PlayerListInFocusedMode({ children }: IProps) {
  const dispatch = useAppDispatch();
  const isPlayerListDrawerOpen = useAppSelector(
    (state) => state.room.isPlayerListDrawerOpen
  );
  const handleToggleDrawer = React.useCallback(() => {
    dispatch(roomActions.togglePlayerListDrawer());
  }, [dispatch]);

  return (
    <LeftDrawerWithHandle
      width={280}
      isOpen={isPlayerListDrawerOpen}
      toggle={handleToggleDrawer}
    >
      {children}
    </LeftDrawerWithHandle>
  );
}

export default PlayerListInFocusedMode;
