export const MAX_ITEM_PER_PAGE = 8;
export const DIAL_SOUND_URL = '//webcdn.fbmms.cn/interview/live/mp3/dial.mp3';
export const HANGUP_SOUND_URL =
  '//webcdn.fbmms.cn/interview/live/mp3/hangup.mp3';
export const SIDEBAR_TRANSFORM_DURATION = 250;

// doc: https://docs.agora.io/cn/Interactive%20Broadcast/API%20Reference/web_ng/interfaces/iagorartcclient.html?platform=Web#event_exception
export const RTC_EXCEPTION_EVENTS = {
  1001: '视频采集帧率过低，检查摄像头连接',
  1002: '视频发送帧率过低，检查摄像头连接',
  1003: '视频发送码率过低，检查摄像头连接',
  1005: '接收视频解码失败，正在尝试恢复',
  2001: '发送音量过低，检查麦克风连接',
  2002: '接收音量过低，对方麦克风异常',
  2003: '音频发送码率过低，检查麦克风连接',
  2005: '接收音频解码失败，正在尝试恢复',
  3001: '视频采集帧率恢复正常',
  3002: '视频发送帧率恢复正常',
  3003: '视频发送码率恢复正常',
  3005: '接收视频解码恢复正常',
  4001: '发送音量恢复正常',
  4002: '接收音量恢复正常',
  4003: '音频发送码率恢复正常',
  4005: '接收音频解码恢复正常',
};
