import { getDefaultMiddleware } from '@reduxjs/toolkit';

const middleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredPaths: ['room'],
    ignoredActionPaths: ['payload'],
  },
});

export default middleware;
