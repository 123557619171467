import { Typography, Box } from 'fbm-ui';
import { useAppSelector } from '../../redux/hooks';

const Progress = () => {
  const room = useAppSelector((state) => state.interview);
  const { stepIndex, stepProgressPercent } = room;
  const { interviewSteps } = room.progressInfo;

  return (
    <Box height={12} width={1} bgcolor="rgb(232, 245, 233)">
      <Box width={`${stepProgressPercent}%`} height={1} bgcolor="#4caf50"></Box>
      <Typography
        position="absolute"
        right={24}
        fontWeight="medium"
        top={16}
        sx={{
          verticalAlign: 'middle',
          px: 2,
          height: 28,
          color: '#4CAF50',
          background: '#E8F5E9',
          boxShadow:
            ' 3px 3px 6px -2px rgba(0,0,0,0.2), -2px -2px 7px -2px #FFFFFF',
          borderRadius: '19px 19px 19px 19px',
          border: '2px solid #FFFFFF',
        }}
      >
        {stepIndex + 1}/{interviewSteps.length}
      </Typography>
    </Box>
  );
};

export default Progress;
