export interface stepInfo {
  stepToken: string;
  questionToken: string;
  questionType: number;
  status: number;
  retryRemainTimes: number;
  answerRemainTimes: number;
  answerStatus: number;
  isFinish: boolean;
}

export function isStepsUnfinished(step: stepInfo) {
  const { retryRemainTimes, answerRemainTimes, answerStatus, isFinish } = step;
  const isStepUnfinished =
    !isFinish &&
    retryRemainTimes > 0 &&
    ((answerStatus === 1 && answerRemainTimes > 0) || answerStatus === 2);
  return isStepUnfinished;
}
