import * as React from 'react';
import { Layout, MobileTextField, useMobileTextField } from 'fbm-ui';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack, TextField } from '@avocadoui/components';
import { FormData } from './types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loginActions } from './slice';
import LoginTabs from './LoginTabs';
import { anyMobileSchema, emailSchema, mobileSchema } from './utils';
import AgreementCheckbox, {
  AgreementCheckboxHandle,
} from './AgreementCheckbox';
import useMobileCodes from '../../hooks/useMobileCodes';

function StepOne() {
  const dispatch = useAppDispatch();
  const { mode, mobile, mobileAreaCode, email, isAgreementChecked } =
    useAppSelector(({ login }) => login);

  const AgreementCheckboxRef = React.useRef<AgreementCheckboxHandle>(null);

  const schema =
    mode === 0
      ? mobileAreaCode === 'CN_243'
        ? mobileSchema
        : anyMobileSchema
      : emailSchema;
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      mobile: {
        mobile,
        mobileAreaCode,
      },
      email,
      isAgreementChecked,
    },
    resolver: zodResolver(schema),
  });

  const watched = {
    mobile: watch('mobile'),
    email: watch('email'),
  };

  const { mobileCodes } = useMobileCodes();
  // @ts-ignore
  const { mobileTextFieldProps } = useMobileTextField({
    options: mobileCodes,
    defaultMobile: {
      mobile,
      mobileAreaCode,
    },
    onChange: (val) => {
      setValue('mobile', val);
      dispatch(loginActions.editMobile(val));
    },
    onAreaChange: (val) => {
      // @ts-ignore
      setValue('mobile', val);
      dispatch(loginActions.editMobile(val));
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (data.isAgreementChecked) {
      dispatch(loginActions.setStepOneForm(data));
      dispatch(loginActions.nextStep());
    } else {
      // 请先阅读并同意《用户协议》和《隐私条款》
      AgreementCheckboxRef.current?.startAnimation();
    }
  });

  const isSubmitDisabled = React.useMemo(() => {
    if (mode === 0) {
      try {
        schema.parse({
          mobile: {
            mobile,
            mobileAreaCode,
          },
          isAgreementChecked: true,
        });
        return false;
      } catch (e) {
        return true;
      }
    } else if (mode === 1) {
      return !!errors.email || !watched.email;
    } else {
      return false;
    }
  }, [mode, schema, mobile, mobileAreaCode, errors.email, watched.email]);

  // const isSubmitDisabled = React.useMemo(() => {
  //   if (mode === 0) {
  //     return !!errors.mobile || !watched.mobile;
  //   } else if (mode === 1) {
  //     return !!errors.email || !watched.email;
  //   } else {
  //     return false;
  //   }
  // }, [mode, errors.mobile, errors.email, watched.mobile, watched.email]);

  return (
    <Stack spacing={4} px={3} pt={1} pb={3}>
      <LoginTabs />

      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          {mode === 0 && (
            <Controller
              control={control}
              name="mobile"
              render={() => {
                return (
                  <Layout>
                    <MobileTextField
                      {...mobileTextFieldProps}
                      key={watched.mobile.mobileAreaCode}
                      // @ts-ignore
                      label="手机号"
                      // @ts-ignore
                      sx={{ height: 'auto' }}
                    />
                  </Layout>
                );
              }}
              // render={({ field }) => (
              //   <TextField
              //     {...field}
              //     variant="outlined"
              //     label="手机号"
              //     autoFocus
              //     fullWidth
              //   />
              // )}
            />
          )}

          {mode === 1 && (
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="邮箱"
                  autoFocus
                  fullWidth
                />
              )}
            />
          )}

          <AgreementCheckbox ref={AgreementCheckboxRef} control={control} />

          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            disabled={isSubmitDisabled}
          >
            下一步
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default StepOne;
