export const INTVIEW_STATUS_INIT = 0;
export const INTVIEW_STATUS_VIDEO_BROADCAST = 1;
export const INTVIEW_STATUS_VIDEO_BROADCAST_REPLAY = 2;
export const INTVIEW_STATUS_AUDIO_BROADCAST = 3;
export const INTVIEW_STATUS_PREPARE = 4;
export const INTVIEW_STATUS_START = 5;
export const INTVIEW_STATUS_FOLLOWUP = 6;
export const INTVIEW_STATUS_FINISH = 7;

export const CARD_BACKGROUNDS = [
  'https://webcdn.fbmms.cn/assets/HgRR/I8FFT4Ld1JusJIQC_W1vI',
  'https://webcdn.fbmms.cn/assets/vThz/1zS1wxcxf3z7egIHCYBIU',
  'https://webcdn.fbmms.cn/assets/LLPK/3AfXIfHrFUeCo4sF0tzo1',
  'https://webcdn.fbmms.cn/assets/0MSc/nyqD508GuNDWZOQQ2WLkM',
  'https://webcdn.fbmms.cn/assets/VKxw/qP0b0333sVSwo4G2n-cOg',
  'https://webcdn.fbmms.cn/assets/BfWS/Ei7GIWHPDHIrV-bjNzFnH',
];

export function hashFunction(t) {
  let hash = 0;
  if (t.length === 0) {
    return hash;
  }
  for (let i = 0; i < t.length; i++) {
    const char = t.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash % 6);
}

export const canMobileLogin = () => {
  const mobileLoginOpen = localStorage.getItem('mobileLoginOpen');
  return mobileLoginOpen === '1' || mobileLoginOpen == null;
};

export const SETP_ACTION_MAP = {
  [INTVIEW_STATUS_INIT]: 'taking',
  [INTVIEW_STATUS_VIDEO_BROADCAST]: 'taking',
  [INTVIEW_STATUS_VIDEO_BROADCAST_REPLAY]: 'taking',
  [INTVIEW_STATUS_AUDIO_BROADCAST]: 'taking',
  [INTVIEW_STATUS_PREPARE]: 'idle',
  [INTVIEW_STATUS_START]: 'activated',
  [INTVIEW_STATUS_FOLLOWUP]: 'thinking',
  [INTVIEW_STATUS_FINISH]: 'idle',
};
