import { useQuery } from 'react-query';
import useRoomToken from './useRoomToken';
import RoomAPI from '../../api/room';
import { isInterviewer } from '../../utils/localStorage';
import { useAppDispatch } from '../../redux/hooks';
import { IRoomUser, roomActions } from './slice';
import RoomModel, { IAllUsers } from './RoomModel';

const placeholderData: IRoomUser[] = [];

function transformRoomUsersToMap(users: IRoomUser[]) {
  return users.reduce((acc: IAllUsers, user: IRoomUser) => {
    acc[user?.agoraUid?.toString()] = user;
    return acc;
  }, {});
}

function useQueryRoomUsersInfo() {
  const roomToken = useRoomToken();
  const dispatch = useAppDispatch();
  return useQuery(
    ['roomUsers', roomToken],
    async () => {
      const result = isInterviewer()
        ? await RoomAPI.roomUsersForInterviewer(roomToken)
        : await RoomAPI.roomUsersForInterviewee(roomToken);
      RoomModel.allUsers = transformRoomUsersToMap(result.data);
      dispatch(roomActions.setRoomUsers(result.data));
      return result.data;
    },
    {
      placeholderData,
      refetchInterval: 5 * 1000,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
}

export default useQueryRoomUsersInfo;
