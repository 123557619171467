import MuiLink, { LinkProps } from '@mui/material/Link';

function Link(props: LinkProps) {
  const { sx, ...others } = props;
  return (
    <MuiLink sx={{ color: '#576B95', cursor: 'pointer', ...sx }} {...others} />
  );
}

export default Link;
