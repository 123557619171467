import { SelectChangeEvent, Stack } from '@mui/material';
import { Select, Typography } from 'fbm-ui';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { formatDeviceLabel } from './helpers';
import { roomActions } from './slice';
import { useTranslation } from 'react-i18next';

const MicSelector = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const micsInfo = useAppSelector((state) => state.room.micsInfo);
  const selectedMicId = useAppSelector((state) => state.room.selectedMicId);

  const options = micsInfo.map((option) => ({
    label: formatDeviceLabel(option.label, option.deviceId === 'default'),
    value: option.deviceId,
  }));
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const deviceId = event.target.value;
    dispatch(roomActions.setSelectedMicId(deviceId));
  };

  if (micsInfo.length > 1) {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">
          {t('Microphone').replace('rophone', '')}
        </Typography>
        <Select
          size="small"
          value={selectedMicId}
          options={options}
          onChange={handleChange}
          sx={{ width: '260px' }}
        />
      </Stack>
    );
  }

  return null;
};

export default MicSelector;
