import * as React from 'react';
import { log } from '@avocadoui/utils';
import RoomAPI from '../../api/room';
import { useAppSelector } from '../../redux/hooks';
import { isInterviewer } from '../../utils/localStorage';
import useRoom from './useRoom';
import useRoomToken from './useRoomToken';

function useEffectChangedMaxAudioVolumeUserId() {
  const roomToken = useRoomToken();
  const localUserId = useAppSelector((state) => state.room.localUserId);
  const { ingUserIds } = useRoom();
  const roomUsers = useAppSelector((state) => state.room.roomUsers);

  const maxAudioVolumeUserId = useAppSelector(
    (state) => state.room.maxAudioVolumeUserId
  );
  const preMaxAudioVolumeUserId = React.useRef(maxAudioVolumeUserId);

  const theId = React.useMemo(() => {
    const allInterviewerIds = [`${localUserId}`, ...ingUserIds]
      .map((id) => {
        const user = roomUsers.find((u) => u.agoraUid.toString() === id);
        if (user) {
          return {
            id,
            role: user.userType,
          };
        }
        return null;
      })
      .filter((u) => u?.role === 1)
      .map((u) => u?.id);

    const sortedInterviewerIds = [...allInterviewerIds].sort();
    return sortedInterviewerIds && sortedInterviewerIds[0];
  }, [roomUsers, localUserId, ingUserIds]);

  React.useEffect(() => {
    if (!isInterviewer()) return;
    if (!maxAudioVolumeUserId) return;
    if (preMaxAudioVolumeUserId.current === maxAudioVolumeUserId) return;
    if (!theId) return;
    if (theId !== localUserId) return; // 只允许最多一个面试官调用 updateVideoLayout 请求

    preMaxAudioVolumeUserId.current = maxAudioVolumeUserId;
    RoomAPI.updateVideoLayout(roomToken, maxAudioVolumeUserId)
      .then(() => {
        log.capture('SUCCESS:切换说话人', { maxAudioVolumeUserId });
      })
      .catch(() => {
        log.capture('FAIL:切换说话人', { maxAudioVolumeUserId });
      });
  }, [maxAudioVolumeUserId, theId, localUserId, roomToken]);
}

export default useEffectChangedMaxAudioVolumeUserId;
