import { Stack } from '@mui/material';
import { ArrowLeftIcon } from 'fbm-icons';
import { IconButton, Typography } from 'fbm-ui';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface BackTitleProps {
  title: string;
  path?: string;
}

const BackTitle: React.FC<BackTitleProps> = ({ title, path }) => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    if (path) {
      navigate(path, { replace: true });
    } else {
      navigate(-1);
    }
  }, [navigate, path]);

  if (!title) return null;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignSelf: 'flex-start',
        alignItems: 'center',
      }}
    >
      <IconButton color="inherit" onClick={handleGoBack}>
        <ArrowLeftIcon></ArrowLeftIcon>
      </IconButton>
      <Typography variant="h5" mt={3} mb={4} fontWeight="medium">
        {title}
      </Typography>
    </Stack>
  );
};

export default BackTitle;
