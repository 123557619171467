import * as React from 'react';
import useRoom from './useRoom';

const useMemoVideoRows = () => {
  const { ingUserIds } = useRoom();

  const rows = React.useMemo(() => {
    const userLength = ingUserIds.length;
    if ([0, 1].includes(userLength)) return 1;
    else if ([2, 3, 4, 5].includes(userLength)) return 2;
    else return 3;
  }, [ingUserIds]);

  return rows;
};

export default useMemoVideoRows;
