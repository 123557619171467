import { Text, Timer } from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';

function RoomTimer() {
  const joinedTimestamp = useAppSelector((state) => state.room.joinedTimestamp);

  if (joinedTimestamp) {
    return (
      <Text variant="body2" color="text.secondary">
        <Timer timestamp={joinedTimestamp} />
      </Text>
    );
  }

  return null;
}

export default RoomTimer;
