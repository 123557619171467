import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TypewriterAPI from '../../api/typewriter';
import { get } from 'lodash';
import { showErrorMessage } from '../../utils/apiCheck';
import { textToLines } from '../../utils/typewriter';
import { useNavigate, useParams } from 'react-router-dom';

const useTypewriter = (interviewToken, articleToken, pushRef, mode) => {
  const navigate = useNavigate();
  const { applicationToken } = useParams();
  const streamName = useRef('');
  const isStart = useRef(false);
  const inputRef = useRef([]);
  const pinyinRef = useRef('');
  const inputIndex = useRef(0);
  const linesTextRef = useRef([]);
  const pauseCountRef = useRef(-1);

  const [maxLimit, setMaxLimit] = useState(0);
  const [tryCount, setTryCount] = useState(0);
  const [result, setResult] = useState(null);

  const [nextPinyin, setNextPinyin] = useState('');
  const [title, setTitle] = useState('');
  const [articleTitle, setArticleTitle] = useState('');
  const [articleContent] = useState('');
  const [duration, setDuration] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [standard, setStandard] = useState('');
  const [accuracy, setAccuracy] = useState(0);
  const [total, setTotal] = useState(0);
  const [bgImg, setBgImg] = useState('');

  const [isEnd, setIsEnd] = useState(false);
  const [status, setStatus] = useState(-1);
  const [liveUrl, setLiveUrl] = useState('');

  const [items, setItems] = useState([]);
  const [qrCode, setQrCode] = useState('');

  const [text, setText] = useState([]);
  const [time, setTime] = useState(0);
  const [passStatus, setPassStatus] = useState(0);
  const [failedMsg, setFailedMsg] = useState('');

  const [pauseStatus, setPauseStatus] = useState(true);

  const [typingInfo, setTypingInfo] = useState({
    typingSpeed: 0,
    typingAccuracy: 0,
    typingError: 0,
    typingBackspace: 0,
    total: 0,
  });

  const handleStart = useCallback(() => {
    if (inputIndex.current === 0) return;
    isStart.current = true;
    setPauseStatus(true);
    pauseCountRef.current = -1;
  }, []);

  const handlePause = useCallback(() => {
    isStart.current = false;
    setPauseStatus(false);
    pauseCountRef.current = 0;
  }, []);

  const timeToString = useMemo(() => {
    return new Date(time * 1000).toISOString().slice(14, 19);
  }, [time]);

  const reloadData = useCallback(() => {
    TypewriterAPI.getTypewriterArticle(interviewToken, articleToken)
      .then((data) => {
        setTitle(get(data, 'responseTpInfo.paperTitle', ''));
        setArticleTitle(get(data, 'responseTpInfo.articleInfo.title', ''));
        setText(
          textToLines(get(data, 'responseTpInfo.articleInfo.content', ''))
        );
        setQrCode(get(data, 'responseTpInfo.qrCode', ''));
        const formatText = textToLines(
          get(data, 'responseTpInfo.articleInfo.content', '')
        ).map((item, index) => ({
          id: index,
          text: item,
          total: item.length,
          input: '',
          correct: 0,
          incorrect: 0,
          backspace: 0,
          disabled: index !== 0,
        }));
        linesTextRef.current = formatText;

        setItems(formatText);

        setLiveUrl(get(data, 'responseTpInfo.liveUrl', ''));
        setDuration(get(data, 'responseTpInfo.duration', 0));
        setTotal(get(data, 'responseTpInfo.articleInfo.wordCount', 0));
        setAccuracy(get(data, 'responseTpInfo.passAccuracyRate', 0));
        setSpeed(get(data, 'responseTpInfo.passSpeed', 0));
        setTime(get(data, 'responseTpInfo.duration', 0) * 60);
        setStatus(get(data, 'status', 1));
        setBgImg(get(data, 'responseTpInfo.bgImg', ''));
        setPassStatus(get(data, 'passStatus', ''));
        setFailedMsg(get(data, 'responseTpInfo.accuracySlowNotice', ''));
        setMaxLimit(get(data, 'responseTpInfo.maxLimit', ''));
        setTryCount(get(data, 'responseTpInfo.tryCount', ''));
        pinyinRef.current = get(
          data,
          'responseTpInfo.articleInfo.pinyin',
          ''
        ).split(' ');
        setNextPinyin(pinyinRef.current?.[0]);
        streamName.current = get(data, 'responseTpInfo.streamName', '');
      })
      .catch((e) => {
        console.log(e);
        showErrorMessage(e);
      });
  }, [interviewToken, articleToken]);

  useEffect(() => {
    if (speed > 0 && accuracy > 0) {
      setStandard(
        `通过标准：速度大于${speed}字/分钟，且正确率大于${accuracy}%`
      );
    } else if (speed > 0 && accuracy === 0) {
      setStandard(`通过标准：速度大于${speed}字/分钟`);
    } else if (speed === 0 && accuracy > 0) {
      setStandard(`通过标准：正确率大于${accuracy}%`);
    }
  }, [speed, accuracy]);

  const reTryNumber = useMemo(() => {
    return maxLimit - tryCount;
  }, [maxLimit, tryCount]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  const handleSummit = useCallback(() => {
    pushRef.current?.stopPush();
    setIsEnd(true);
    if (mode !== '1') return;
    TypewriterAPI.setTypewriterResult(
      interviewToken,
      articleToken,
      typingInfo.typingSpeed,
      typingInfo.typingAccuracy,
      typingInfo.typingError,
      typingInfo.typingBackspace,
      inputIndex.current,
      streamName.current
    )
      .then((data) => {
        setResult(data);
        console.log(data);
      })
      .catch(() => {
        navigate(`/process/${applicationToken}`);
      });
  }, [
    pushRef,
    mode,
    interviewToken,
    articleToken,
    typingInfo.typingSpeed,
    typingInfo.typingAccuracy,
    typingInfo.typingError,
    typingInfo.typingBackspace,
    navigate,
    applicationToken,
  ]);

  const handleWordChange = useCallback(
    (text, input, index) => {
      const prevBackspace = inputRef.current[index]?.backspace || 0;
      const prevInput = inputRef.current[index]?.input || '';
      const needInput = input
        .replace(/[a-zA-Z']/g, '')
        .slice(0, text.length)
        .split('');

      const prevLineTotal = linesTextRef.current
        .slice(0, index)
        .reduce((acc, cur) => acc + cur.total, 0);

      inputIndex.current = prevLineTotal + needInput.length;

      setNextPinyin(pinyinRef.current?.[inputIndex.current]);

      const backspace =
        prevBackspace +
        (prevInput.length - needInput.length > 0
          ? prevInput.length - needInput.length
          : 0);

      const result = needInput.reduce(
        (acc, cur, idx) => {
          if (cur === text[idx]) {
            acc.correct++;
          } else {
            acc.incorrect++;
          }
          return acc;
        },
        { correct: 0, incorrect: 0 }
      );
      inputRef.current[index] = {
        input: needInput,
        backspace,
        ...result,
      };
      const allRight =
        needInput[needInput.length - 1] === text[text.length - 1];

      if (inputIndex.current === total && allRight) {
        handleSummit();
      }
    },
    [handleSummit, total]
  );

  const setNextInput = useCallback(() => {
    setItems((items) => {
      const nextItems = [...items];
      for (let i = 0; i < nextItems.length; i++) {
        if (!nextItems[i].disabled) {
          if (i < items.length - 1) {
            nextItems[i].disabled = true;
            nextItems[i + 1].disabled = false;
          }
          break;
        }
      }
      return nextItems;
    });
  }, []);

  const setPrevInput = useCallback(() => {
    setItems((items) => {
      const nextItems = [...items];
      for (let i = nextItems.length - 1; i >= 0; i--) {
        if (!nextItems[i].disabled) {
          if (i > 0) {
            nextItems[i].disabled = true;
            nextItems[i - 1].disabled = false;
          }
          break;
        }
      }
      return nextItems;
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isStart.current && isEnd === false) {
        const last = inputRef.current.reduce(
          (acc, cur) => {
            acc.correct += cur.correct;
            acc.incorrect += cur.incorrect;
            acc.backspace += cur.backspace;
            return acc;
          },
          { correct: 0, incorrect: 0, backspace: 0 }
        );

        const typingSpeed = Math.round(
          last.correct / ((duration * 60 - time) / 60)
        );
        setTypingInfo({
          typingSpeed: isNaN(typingSpeed) ? 0 : typingSpeed,
          typingAccuracy:
            last.correct + last.incorrect > 0
              ? Math.round(
                  (last.correct / (last.correct + last.incorrect)) * 100
                )
              : 0,
          typingError: last.incorrect,
          typingBackspace: last.backspace,
          total: inputIndex.current,
        });

        setTime((seconds) => {
          if (seconds === 1) {
            clearInterval(intervalId);
            handleSummit();
          } else if (seconds === duration * 60) {
            pushRef.current?.startPush();
          }
          return seconds - 1;
        });
      } else {
        if (pauseCountRef.current === -1) return;
        pauseCountRef.current++;
        if (pauseCountRef.current === 600) {
          pushRef.current?.stopPush();
          navigate(`/t/login/${interviewToken}`);
          clearInterval(intervalId);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, handleSummit, time, interviewToken]);

  return {
    title,
    articleTitle,
    articleContent,
    duration,
    speed,
    standard,
    accuracy,
    total,
    isEnd,
    setIsEnd,
    setText,
    text,
    liveUrl,
    timeToString,
    handleSummit,
    items,
    status,
    qrCode,
    bgImg,
    setNextInput,
    setPrevInput,
    handleStart,
    handlePause,
    handleWordChange,
    typingInfo,
    nextPinyin,
    passStatus,
    failedMsg,
    isStart,
    pauseStatus,
    result,
    reTryNumber,
  };
};

export default useTypewriter;
