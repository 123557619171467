import { createSlice, current, nanoid } from '@reduxjs/toolkit';
import AccountAPI from '../../api/account';

const initialState = {
  agoraUid: 0,
  agoraCname: '',
  agoraRTCToken: '',
  agoraRTMToken: '',
  roomStatus: 0,
  roomStartTime: 0,
  roomEndTime: 0,
  username: '',
  avatar: '',
  virtualOpen: 0, // 0:关闭 1:背景虚化 2:背景图片
  enterpriseImage: '',
  backgroundImages: [],
  rtcCheatInfo: {
    // 反作弊信息
    cheatKey: '',
    rtcUserToken: '',
    rtcTitle: '',
  },
};

function setAllBackgroundImagesToUnused(state) {
  state.backgroundImages.forEach((item) => {
    item.isUse = 0;
  });
}

function sentCurrentVirtualBackgroundInfo(state) {
  const { virtualOpen, backgroundImages } = current(state);
  const newInfo = {
    virtualOpen,
    backgroundImages,
  };
  AccountAPI.setUserVirtualBackground(newInfo).catch((e) =>
    console.log('AccountAPI.setUserVirtualBackground error:', e)
  );
}

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetAccount: () => {
      return initialState;
    },
    setAccount: (state, action) => {
      const {
        agoraUid,
        agoraCname,
        agoraRTCToken,
        agoraRTMToken,
        roomInfo: {
          status: roomStatus,
          start: roomStartTime,
          end: roomEndTime,
        },
        userName,
        avatar,
        rtcCheatInfo,
      } = action.payload;

      state.agoraUid = agoraUid;
      state.agoraCname = agoraCname;
      state.agoraRTCToken = agoraRTCToken;
      state.agoraRTMToken = agoraRTMToken;
      state.roomStatus = roomStatus;
      state.roomStartTime = roomStartTime;
      state.roomEndTime = roomEndTime;
      state.username = userName;
      state.avatar = avatar;
      if (rtcCheatInfo.cheatKey) state.rtcCheatInfo = rtcCheatInfo;
    },
    renewAccount: (state, action) => {
      const { agoraUid, agoraCname, agoraRTCToken, agoraRTMToken } =
        action.payload;

      state.agoraUid = agoraUid;
      state.agoraCname = agoraCname;
      state.agoraRTCToken = agoraRTCToken;
      state.agoraRTMToken = agoraRTMToken;
    },
    setVirtualBackgroundInfo: (state, action) => {
      const { virtualOpen, enterpriseImage, backgroundImages } = action.payload;

      state.virtualOpen = virtualOpen;
      state.enterpriseImage = enterpriseImage;
      state.backgroundImages = backgroundImages;

      // 特殊情况: 企业背景图被删除
      const useBackgroundImage = virtualOpen === 2;
      const hasNoEnterpriseImage = !enterpriseImage;
      const notUsedAnyUserUploadImage = !backgroundImages.some(
        (item) => item.isUse === 1
      );
      if (
        useBackgroundImage &&
        hasNoEnterpriseImage &&
        notUsedAnyUserUploadImage
      ) {
        state.virtualOpen = 0;
      }
    },
    setVirtualBackgroundType: (state, action) => {
      state.virtualOpen = action.payload;
    },
    toggleBlurEffect: (state) => {
      state.virtualOpen = state.virtualOpen === 0 ? 1 : 0;
    },
    disableVirtualBackground: (state) => {
      state.virtualOpen = 0;
      setAllBackgroundImagesToUnused(state);
      sentCurrentVirtualBackgroundInfo(state);
    },
    enableBlurEffect: (state) => {
      state.virtualOpen = 1;
      setAllBackgroundImagesToUnused(state);
      sentCurrentVirtualBackgroundInfo(state);
    },
    setEnterpriseImageAsVirtualBackground: (state) => {
      state.virtualOpen = 2;
      setAllBackgroundImagesToUnused(state);
      sentCurrentVirtualBackgroundInfo(state);
    },
    addUploadImageAsVirtualBackground: (state, action) => {
      // 将上传的图片设为已选中
      const img = {
        imageUrl: action.payload,
        isUse: 0,
        token: nanoid(),
      };
      state.backgroundImages.push(img);
      sentCurrentVirtualBackgroundInfo(state);
    },
    setUploadImageAsVirtualBackground: (state, action) => {
      state.virtualOpen = 2;
      setAllBackgroundImagesToUnused(state);
      // 将所选图片设为已选中
      const imageToken = action.payload;
      const image = state.backgroundImages.find(
        (item) => item.token === imageToken
      );
      if (image) image.isUse = 1;
      sentCurrentVirtualBackgroundInfo(state);
    },
    removeUploadImage: (state, action) => {
      const imageToken = action.payload;
      const isSelected =
        state.backgroundImages.find((item) => item.token === imageToken)
          ?.isUse === 1;
      if (isSelected) {
        state.virtualOpen = 0;
        setAllBackgroundImagesToUnused(state);
      }
      state.backgroundImages = state.backgroundImages.filter(
        (item) => item.token !== imageToken
      );
      sentCurrentVirtualBackgroundInfo(state);
    },
  },
});

export const accountActions = slice.actions;
export const accountReducer = slice.reducer;
