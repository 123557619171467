import { Paper } from '@mui/material';
import { Stack, Box } from 'fbm-ui/lib/mui';
import { FC, ReactNode } from 'react';
import { StyledVideo } from '../styles';

export interface SingleChoiceProps {
  options: Array<{
    content: string;
  }>;
  selected?: number;
  onChange?: (value: number) => void;
  title: ReactNode;
  pusher?: ReactNode;
  okButon?: ReactNode;
}

const SingleChoice: FC<SingleChoiceProps> = ({
  options,
  selected,
  onChange,
  title,
  pusher,
  okButon,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        background: '#F9FAF9',
        borderRadius: 2,
        overflow: 'hidden',
      }}
    >
      <Stack
        height={612}
        spacing={4}
        py={2}
        component={Paper}
        sx={{ position: 'relative', borderRadius: 2, overflow: 'hidden' }}
      >
        <Stack px={2}>{title}</Stack>
        <Stack
          spacing={2}
          flexGrow={1}
          px={2}
          sx={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {options?.map((ele, index) => (
            <Box
              component={Paper}
              variant="outlined"
              key={ele.content}
              onClick={() => onChange?.(index)}
              sx={{
                cursor: 'pointer',
                width: 587,
                minHeight: 56,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 500,
                fontwSize: 16,
                lineHeight: 1.5,
                p: 2,
                border:
                  selected === index
                    ? '1px solid #4caf50'
                    : '1px solid #DDDDDD',
              }}
            >
              {ele.content}
            </Box>
          ))}
        </Stack>
        <Box
          height={32}
          sx={{
            position: 'relative',
          }}
        >
          {okButon}
        </Box>
        {/* {okButon} */}
      </Stack>
      <Stack
        height={612}
        component={Paper}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          width: 325,
          flexShrink: 0,
          backgroundSize: '100% auto',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            "url('//webcdn.fbmms.cn/assets/ytGX/sRsajH3OEGH1NrzqjSW3n.png')",
          aspectRatio: '16/9',
        }}
      >
        <StyledVideo size="small">{pusher}</StyledVideo>
      </Stack>
    </Stack>
  );
};

export default SingleChoice;
