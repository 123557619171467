import * as React from 'react';
import { Stack, Text } from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';
import { isInterviewer } from '../../utils/localStorage';
import useQueryPositionInfo from './useQueryPositionInfo';

const Layout: React.FC = ({ children }) => (
  <Stack
    flex={1}
    alignItems="center"
    spacing={3}
    pt="10%"
    sx={{ backgroundColor: '#F9FAF9' }}
  >
    {children}
  </Stack>
);

function RoomLeft() {
  const accountRoomStatus = useAppSelector((state) => state.account.roomStatus);
  const positionInfoRes = useQueryPositionInfo();
  const positionInfoStatus = positionInfoRes?.data?.status;
  const roomStatus = accountRoomStatus || positionInfoStatus;

  const afterPageType = useAppSelector((state) => state.room.afterPageType);

  if ([2, 3].includes(roomStatus)) {
    return (
      <Layout>
        <img
          src="//webcdn.fbmms.cn/interview/live/cancelled-event-rafiki.svg"
          style={{ maxWidth: '250px' }}
          alt=""
        />
        <Text variant="h5" color="text.primary" fontWeight="fontWeightBold">
          {roomStatus === 2 && '沟通已取消'}
          {roomStatus === 3 && '沟通已结束'}
        </Text>
      </Layout>
    );
  }

  if (afterPageType === 1) {
    return (
      <Layout>
        <img
          src="//webcdn.fbmms.cn/interview/live/departing-bro.svg"
          style={{ maxWidth: '250px' }}
          alt=""
        />
        <Text variant="h5" color="text.primary" fontWeight="fontWeightBold">
          你已离开视频沟通
        </Text>
      </Layout>
    );
  }

  if (afterPageType === 2) {
    const txt = isInterviewer()
      ? '辛苦了，本轮面试已结束，先去休息休息吧'
      : '沟通已结束，辛苦了';
    return (
      <Layout>
        <img
          src="//webcdn.fbmms.cn/interview/live/celebration-amico.svg"
          style={{ maxWidth: '250px' }}
          alt=""
        />
        <Text variant="h5" color="text.primary" fontWeight="fontWeightBold">
          {txt}
        </Text>
      </Layout>
    );
  }

  if (afterPageType === 3) {
    return (
      <Layout>
        <img
          src="//webcdn.fbmms.cn/interview/live/cancelled-event-rafiki.svg"
          style={{ maxWidth: '250px' }}
          alt=""
        />
        <Text variant="h5" color="text.primary" fontWeight="fontWeightBold">
          你已被迫下线
        </Text>
        <Text variant="body1" color="text.secondary" mt={3}>
          你的视频链接已在别处登录，此处被迫下线。
        </Text>
      </Layout>
    );
  }

  if (afterPageType === 4) {
    return (
      <Layout>
        <img
          src="//webcdn.fbmms.cn/interview/live/celebration-amico.svg"
          style={{ maxWidth: '250px' }}
          alt=""
        />
        <Text variant="h5" color="text.primary" fontWeight="fontWeightBold">
          录制已结束，辛苦了！
        </Text>
      </Layout>
    );
  }

  return null;
}

export default RoomLeft;
