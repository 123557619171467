import { SxProps, Theme } from '@mui/material';
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';

export interface IAvatar extends AvatarProps {
  size?: keyof typeof sizes;
  name?: string;
}

const sizes = {
  small: '24px',
  medium: '36px',
  large: '48px',
  extraLarge: '88px',
};

function Avatar(props: IAvatar) {
  const { sx, size = 'medium', name, src, ...rest } = props;

  let style = { ...sx } as SxProps<Theme>;
  if (size) {
    const avatarSize = { width: sizes[size], height: sizes[size] };
    style = { ...style, ...avatarSize };
  }

  if (size === 'small') {
    style = { ...style, fontSize: '1rem' };
  }

  if (name && !src) {
    const { sx, children } = stringAvatar(name);
    return <MuiAvatar children={children} sx={{ ...sx, ...style }} {...rest} />;
  }

  return <MuiAvatar sx={style} src={src} {...rest} />;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split('')[name.length - 1]}`,
  };
}

export default Avatar;
