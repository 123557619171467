import { useMachine } from '@xstate/react';
import constate from 'constate';

import roomMachine from '../../machines/room';

function useRoomMachine() {
  const [state, send] = useMachine(roomMachine);
  return { state, send };
}

const [RoomContextProvider, useRoomState, useRoomSend] = constate(
  useRoomMachine,
  (value) => value.state,
  (value) => value.send
);

export { RoomContextProvider, useRoomState, useRoomSend };
