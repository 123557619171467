import * as React from 'react';
import { Stack } from '@mui/material';
import { Box, confirm, Popover, Typography } from 'fbm-ui';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ToggleButton from './ToggleButton';
import { CancelIcon, ImageIcon, StopIcon } from 'fbm-icons';
import { COLOR } from '../../utils/const';
import { accountActions } from '../Account/slice';
import UploadBackgroundImage from './UploadBackgroundImage';
import WaterDropOutlineIcon from './WaterDropOutlineIcon';
import { log } from '@avocadoui/utils';

const UPLOAD_BACKGROUND_IMAGE_MAX_LENGTH = 5;

interface ICard {
  children?: React.ReactNode;
  selected?: boolean;
  src?: string;
  isBlur?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

function Card({
  children,
  selected = false,
  src,
  isBlur = false,
  onClick,
  onDelete,
}: ICard) {
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete?.();
  };
  return (
    <Box
      sx={[
        {
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: 96,
          height: 54,
          backgroundColor: '#EFF0F1',
          borderRadius: '8px',
          transition: 'transform 0.1s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
          '&:hover .delete-btn': {
            display: 'flex',
          },
        },
        selected && {
          border: `2px solid ${COLOR.PRIMARY}`,
        },
        src && {
          backgroundImage: `url(${src})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
        isBlur && {
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'rgba(0,0,0,0.2)',
          backgroundImage:
            'url(https://webcdn.fbmms.cn/assets/PLc-/RKQRtlDVpVyBB2KkGdsRY)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
        onClick && {
          cursor: 'pointer',
        },
        onDelete && {
          position: 'relative',
        },
      ]}
      onClick={onClick}
    >
      {isBlur && (
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            background: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(5px)',
          }}
        />
      )}
      {onDelete && (
        <Box
          className="delete-btn"
          sx={{
            position: 'absolute',
            top: -10,
            right: -10,
            display: 'none',
            backgroundColor: 'white',
            borderRadius: '100%',
          }}
          onClick={handleDelete}
        >
          <CancelIcon sx={{ width: 20, height: 20 }} />
        </Box>
      )}
      {children && (
        <Box
          sx={{
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

function Content({ onClose }: { onClose: () => void }) {
  const dispatch = useAppDispatch();
  const virtualOpen = useAppSelector((state) => state.account.virtualOpen);
  const enterpriseImage = useAppSelector(
    (state) => state.account.enterpriseImage
  );
  const backgroundImages = useAppSelector(
    (state) => state.account.backgroundImages
  );
  const enterpriseImageIsUse = useAppSelector(
    (state) =>
      state.account.virtualOpen === 2 &&
      state.account.backgroundImages.findIndex((item) => item.isUse === 1) ===
        -1
  );

  const actionsWrapperRef = React.useRef<HTMLDivElement>(null);

  const disableVirtualBackground = React.useCallback(() => {
    dispatch(accountActions.disableVirtualBackground());
    log.capture('SUCCESS:关闭虚拟背景');
  }, [dispatch]);

  const enableBlurEffect = React.useCallback(() => {
    dispatch(accountActions.enableBlurEffect());
  }, [dispatch]);

  const setEnterpriseImageAsVirtualBackground = React.useCallback(() => {
    dispatch(accountActions.setEnterpriseImageAsVirtualBackground());
  }, [dispatch]);

  const setUploadImageAsVirtualBackground = React.useCallback(
    (token) => () => {
      dispatch(accountActions.setUploadImageAsVirtualBackground(token));
    },
    [dispatch]
  );

  const removeUploadImage = React.useCallback(
    (token, fileUrl) => () => {
      confirm({
        title: '确认删除该虚拟背景？',
        content: '虚拟背景删除后将无法恢复，请谨慎操作。',
        okText: '删除',
        okProps: {
          color: 'error',
        },
        onOk({ onClose }) {
          dispatch(accountActions.removeUploadImage(token));
          onClose?.();
          log.capture('SUCCESS:删除背景图片', { fileToken: token, fileUrl });
        },
      });
    },
    [dispatch]
  );

  return (
    <Box maxWidth="620px" pt={2}>
      <Typography variant="body1" fontWeight={500} sx={{ pl: 3 }}>
        虚拟背景
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        p={3}
        sx={{ overflowX: 'auto' }}
        ref={actionsWrapperRef}
      >
        <Card selected={virtualOpen === 0} onClick={disableVirtualBackground}>
          <StopIcon style={{ color: COLOR.DEFAULT }} />
        </Card>
        <Card>
          <UploadBackgroundImage
            actionsWrapperRef={actionsWrapperRef}
            disabled={
              backgroundImages.length >= UPLOAD_BACKGROUND_IMAGE_MAX_LENGTH
            }
            max={UPLOAD_BACKGROUND_IMAGE_MAX_LENGTH}
          />
        </Card>
        <Card isBlur selected={virtualOpen === 1} onClick={enableBlurEffect}>
          <WaterDropOutlineIcon style={{ color: COLOR.DEFAULT }} />
        </Card>
        {enterpriseImage && (
          <Card
            src={enterpriseImage}
            selected={enterpriseImageIsUse}
            onClick={setEnterpriseImageAsVirtualBackground}
          />
        )}
        {backgroundImages.map((item) => (
          <Card
            key={item.token}
            src={item.imageUrl}
            selected={virtualOpen === 2 && item.isUse === 1}
            onClick={setUploadImageAsVirtualBackground(item.token)}
            onDelete={removeUploadImage(item.token, item.imageUrl)}
          />
        ))}
      </Stack>
    </Box>
  );
}

function VirtualBackgroundPopover() {
  const [open, setOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setOpen((o) => !o);
  }, []);

  return (
    <Popover
      open={open}
      content={<Content onClose={handleToggle} />}
      // onClickAway={handleToggle}
    >
      <ToggleButton
        icon={<ImageIcon style={{ color: COLOR.DEFAULT }} />}
        onClick={handleToggle}
        isOpen={open}
      >
        虚拟背景
      </ToggleButton>
    </Popover>
  );
}

export default VirtualBackgroundPopover;
