import * as React from 'react';
import * as Sentry from '@sentry/react';
// import { log } from '@avocadoui/utils';
// import AccountAPI from '../../api/account';
// import { useAppDispatch } from '../../redux/hooks';
// import { getUserType } from '../../utils/localStorage';
// import { roomActions } from '../Room/slice';
// import useRoomToken from '../Room/useRoomToken';
// import { accountActions } from './slice';

function useAccount() {
  // const dispatch = useAppDispatch();
  // const roomToken = useRoomToken();

  // const sendCode = React.useCallback(
  //   async (mode: number, target: string, roomToken: string) => {
  //     const res =
  //       mode === 0
  //         ? await AccountAPI.sendCodeToMobile(target)
  //         : await AccountAPI.sendCodeToEmail(target, roomToken);
  //     if (res.code === 0) {
  //       log.capture('SUCCESS:验证码发送', { account: target });
  //     } else {
  //       log.capture('FAIL:验证码发送', { account: target, error: res.message });
  //     }
  //     return res;
  //   },
  //   []
  // );

  // const login = React.useCallback(
  //   async (mode: number, target: string, code: string) => {
  //     const res =
  //       mode === 0
  //         ? await AccountAPI.loginByMobile(roomToken, target, code)
  //         : await AccountAPI.loginByEmail(roomToken, target, code);
  //     if (res.code === 0) {
  //       const user = res.data;
  //       localStorage.setItem('id_token', user.token);
  //       localStorage.setItem('user_type', user.type);
  //       if (user.mobileLinkUrl) {
  //         dispatch(roomActions.setMobileLinkUrl(user.mobileLinkUrl));
  //       }
  //       log.capture('SUCCESS:登录', { account: target, code });
  //       return res;
  //     } else {
  //       log.capture('FAIL:登录', { account: target, code, error: res.message });
  //       return res;
  //     }
  //   },
  //   [roomToken, dispatch]
  // );

  // const fetchUserInfo = React.useCallback(async () => {
  //   const type = getUserType();
  //   if (type === 1 || type === 2) {
  //     const result = await AccountAPI.userInfo(type, roomToken);
  //     if (result.code === 0) {
  //       dispatch(accountActions.setAccount(result.data));
  //       if (result.data?.roomInfo?.mobileLinkUrl) {
  //         dispatch(
  //           roomActions.setMobileLinkUrl(result.data?.roomInfo?.mobileLinkUrl)
  //         );
  //       }
  //       if (result.data?.agoraUid) {
  //         log.identify(result.data?.agoraUid);
  //       }
  //       return result.data;
  //     } else {
  //       dispatch(accountActions.resetAccount());
  //       throw new Error('code !== 0');
  //     }
  //   }
  //   dispatch(accountActions.resetAccount());
  // }, [roomToken, dispatch]);

  const logout = React.useCallback(() => {
    Sentry.configureScope((scope) => scope.setUser(null));
    localStorage.clear();
    sessionStorage.clear();
    const url = window.location.href;
    if (url.includes('?')) {
      const newUrl = url.split('?')?.[0];
      window.location.href = newUrl;
    } else {
      window.location.reload();
    }
  }, []);

  return {
    // sendCode,
    // login,
    // fetchUserInfo,
    logout,
  };
}

export default useAccount;
