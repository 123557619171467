import * as React from 'react';
import useRoomToken from './useRoomToken';
import RoomAPI from '../../api/room';
import { isInterviewer } from '../../utils/localStorage';

function onlinePing(roomToken: string) {
  try {
    if (isInterviewer()) {
      RoomAPI.onlinePingInterviewer(roomToken);
    } else {
      RoomAPI.onlinePingInterviewee(roomToken);
    }
  } catch (error) {
    console.log('onlinePing error:', error);
  }
}

const useEffectRoomOnlinePing = () => {
  const roomToken = useRoomToken();

  React.useEffect(() => {
    const id = setInterval(() => {
      onlinePing(roomToken);
    }, 15 * 1000);

    return () => id && clearInterval(id);
  }, [roomToken]);

  return null;
};

export default useEffectRoomOnlinePing;
