import * as React from 'react';

function usePlaySound() {
  const audio = React.useRef<HTMLAudioElement>();

  const play = React.useCallback((url: string, onEnd) => {
    audio.current?.pause();
    // if (!audio.current)
    audio.current = new Audio();
    audio.current.src = url;
    audio.current?.play();
    audio.current?.addEventListener('ended', onEnd);
    return () => {
      audio.current?.removeEventListener('ended', onEnd);
    };
  }, []);

  return {
    play,
  };
}

export default usePlaySound;
