import * as React from 'react';
import useRoom from './useRoom';

interface IProps {
  playerContainerWidth: number;
  playerContainerHeight: number;
}

interface IResize extends IProps {
  focusedUserId: string;
  unfocusedUserIds: string[];
  focusedIsLocalUser: boolean;
}

const MINI_PLAYER_WIDTH = 280;
const GAP_WIDTH = 8;
const ratio = [9, 16];

function resize({
  focusedUserId,
  unfocusedUserIds,
  focusedIsLocalUser,
  playerContainerWidth,
  playerContainerHeight,
}: IResize) {
  let width = playerContainerWidth - GAP_WIDTH;
  let height = (width * ratio[0]) / ratio[1];
  if (height > playerContainerHeight) {
    height = playerContainerHeight - GAP_WIDTH;
    width = (height * ratio[1]) / ratio[0];
  }

  const localPlayer = document.getElementById('local-player-container');
  if (localPlayer) {
    const width = MINI_PLAYER_WIDTH;
    const height = (width * 9) / 16;
    localPlayer.style.width = `${width}px`;
    localPlayer.style.height = `${height}px`;
  }

  unfocusedUserIds.forEach((userId) => {
    const width = MINI_PLAYER_WIDTH;
    const height = (width * 9) / 16;
    const remotePlayerContainer = document.getElementById(
      `remote-player-container-${userId}`
    );
    if (remotePlayerContainer) {
      remotePlayerContainer.style.width = `${width}px`;
      remotePlayerContainer.style.height = `${height}px`;
    }
    const remotePlayer = document.getElementById(`remote-player-${userId}`);
    if (remotePlayer) {
      remotePlayer.style.width = `${width}px`;
      remotePlayer.style.height = `${height}px`;
    }
  });

  if (focusedIsLocalUser) {
    const localPlayer = document.getElementById('local-player-container');
    if (localPlayer) {
      localPlayer.style.width = `${width}px`;
      localPlayer.style.height = `${height}px`;
    }
  } else {
    const focusedPlayerContainer = document.getElementById(
      `remote-player-container-${focusedUserId}`
    );
    if (focusedPlayerContainer) {
      focusedPlayerContainer.style.width = `${width}px`;
      focusedPlayerContainer.style.height = `${height}px`;
    }
    const focusedPlayer = document.getElementById(
      `remote-player-${focusedUserId}`
    );
    if (focusedPlayer) {
      focusedPlayer.style.width = `${width}px`;
      focusedPlayer.style.height = `${height}px`;
    }
  }
}

const useEffectResizeVideoInFocusedMode = ({
  playerContainerWidth,
  playerContainerHeight,
}: IProps) => {
  const { focusedUserId, unfocusedUserIds, focusedIsLocalUser } = useRoom();

  React.useEffect(() => {
    resize({
      focusedUserId,
      unfocusedUserIds,
      focusedIsLocalUser,
      playerContainerWidth,
      playerContainerHeight,
    });
  }, [
    focusedUserId,
    unfocusedUserIds,
    focusedIsLocalUser,
    playerContainerWidth,
    playerContainerHeight,
  ]);

  return null;
};

export default useEffectResizeVideoInFocusedMode;
