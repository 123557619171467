import * as React from 'react';
import { log } from '@avocadoui/utils';

import { useAppSelector } from '../../redux/hooks';
import RoomModel from './RoomModel';

const useEffectSetDevices = () => {
  const selectedCameraId = useAppSelector(
    (state) => state.room.selectedCameraId
  );
  const selectedMicId = useAppSelector((state) => state.room.selectedMicId);
  const selectedSpeakerId = useAppSelector(
    (state) => state.room.selectedSpeakerId
  );

  React.useEffect(() => {
    if (selectedCameraId) {
      try {
        RoomModel.localTracks?.videoTrack?.setDevice(selectedCameraId);
      } catch (error) {
        log.capture('FAIL:选择摄像头', { error, deviceId: selectedCameraId });
      }
    }
  }, [selectedCameraId]);

  React.useEffect(() => {
    if (selectedMicId) {
      try {
        RoomModel.localTracks?.audioTrack?.setDevice(selectedMicId);
      } catch (error) {
        log.capture('FAIL:选择麦克风', { error, deviceId: selectedMicId });
      }
    }
  }, [selectedMicId]);

  React.useEffect(() => {
    if (selectedSpeakerId) {
      RoomModel.playbackDeviceId = selectedSpeakerId;
      // RoomModel.localTracks?.audioTrack?.setPlaybackDevice(selectedSpeakerId);
    }
  }, [selectedSpeakerId]);
};

export default useEffectSetDevices;
