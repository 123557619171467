import * as React from 'react';
import { Box } from 'fbm-ui';
import { sleep } from '@avocadoui/utils';
import { useDimensions } from '../../hooks';
import useEffectResizeVideoInGridMode from './useEffectResizeVideoInGridMode';
import useMemoVideoColumns from './useMemoVideoColumns';
import LocalPlayerContainerInGridMode from './LocalPlayerContainerInGridMode';
import RemotePlayerContainerInGridMode from './RemotePlayerContainerInGridMode';
import useMemoVideoRows from './useMemoVideoRows';
import { useAppSelector } from '../../redux/hooks';
import useRoom from './useRoom';
import { SIDEBAR_TRANSFORM_DURATION } from './const';

function ResizeLayout({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = React.useState(false);
  const isUserListDrawerOpen = useAppSelector(
    (state) => state.room.isUserListDrawerOpen
  );
  const [playerContainerRef, playerContainerSize] = useDimensions({
    deps: [open],
  });
  const { width: playerContainerWidth, height: playerContainerHeight } =
    playerContainerSize;
  useEffectResizeVideoInGridMode({
    playerContainerWidth,
    playerContainerHeight,
  });

  React.useEffect(() => {
    async function init() {
      await sleep(SIDEBAR_TRANSFORM_DURATION);
      setOpen(isUserListDrawerOpen);
    }
    init();
  }, [isUserListDrawerOpen]);

  return (
    <div
      ref={playerContainerRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
}

function GridModeLayout() {
  const { ingUserIds } = useRoom();
  const videoColumns = useMemoVideoColumns();
  const videoRows = useMemoVideoRows();

  return (
    <ResizeLayout>
      <Box
        id="players-container"
        sx={{
          display: 'grid',
          gridGap: '4px',
          gridTemplateRows: `repeat(${videoRows}, 1fr)`,
          gridTemplateColumns: `repeat(${videoColumns}, 1fr)`,
          height: 'calc(100vh - 60px - 72px)',
          overflow: 'hidden',
        }}
      >
        {ingUserIds.length > 1 ? (
          <>
            <LocalPlayerContainerInGridMode />
            <RemotePlayerContainerInGridMode />
          </>
        ) : (
          <>
            <RemotePlayerContainerInGridMode />
            <LocalPlayerContainerInGridMode />
          </>
        )}
      </Box>
    </ResizeLayout>
  );
}

export default GridModeLayout;
