import { TextField, Typography } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import { useCallback, useEffect, useRef, useState } from 'react';

const disabledEvent = (e) => {
  e.preventDefault();
  return false;
};

const textColor = (text, index, item) => {
  const finalText = text.replace(/[a-zA-Z]+$/g, '');
  if (index >= text.length) {
    return 'inherit';
  }
  if (!finalText[index]) {
    return 'gray';
  }
  return finalText[index] === item ? 'green' : 'red';
};

interface TypeItemProps {
  content: string;
  disabled?: boolean;
  index: number;
  handleWordChange?: (text, input, index) => void;
  countError?: () => void;
  nextInput?: () => void;
  prevInput?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TypeItem: React.FC<TypeItemProps> = ({
  content,
  disabled = false,
  index,
  handleWordChange,
  prevInput,
  nextInput,
  onChange,
}) => {
  const ref = useRef(null);
  const boxRef = useRef(null);
  const [active, setActive] = useState(false);
  const [text, setText] = useState('');
  const handleChange = (e) => {
    const { value } = e.target;
    const finalText = value.replace(/[a-zA-Z]+$/g, '');
    handleWordChange?.(content, finalText, index);
    setText(value);
    if (
      value[value.length - 1] === content[value.length - 1] &&
      value.length === content.length
    ) {
      setTimeout(() => {
        nextInput?.();
      }, 200);
    }
    onChange?.(e);
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        if (text.length >= content.length) {
          nextInput?.();
        }
      } else if (e.keyCode === 8) {
        if (text.length === 0) {
          console.log('backspace');
          prevInput?.();
        }
      }
    },
    [content.length, nextInput, prevInput, text.length]
  );

  useEffect(() => {
    if (!disabled) {
      document
        .getElementById(`type-item-${index - 2}`)
        ?.scrollIntoView({ behavior: 'smooth' });
      ref.current?.focus();
    }
  }, [disabled, index]);
  return (
    <Stack
      ref={boxRef}
      id={`type-item-${index}`}
      spacing={2}
      sx={{ background: active ? '#FEEEDC' : '#e6ecf9', borderRadius: 0.5 }}
      pl={3}
      pr={4}
      py={2}
    >
      <Typography
        variant="h6"
        fontWeight={500}
        color={active ? 'primary' : 'textSecondary'}
      >
        {Array.from(content)?.map((item, index) => (
          <span
            key={index}
            style={{
              color: textColor(text, index, item),
            }}
          >
            {item}
          </span>
        ))}
      </Typography>
      <TextField
        ref={ref}
        sx={{
          height: 'auto',
          '& .MuiOutlinedInput-root': {
            fontWeight: 500,
            fontSize: 20,
            padding: 0,
          },
          '& .MuiOutlinedInput-input': {
            padding: '6px 2px !important',
          },
          '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'rgba(230, 230, 230, 0.16) !important',
          },
        }}
        disabled={disabled}
        onPaste={disabledEvent}
        onCopy={disabledEvent}
        onCut={disabledEvent}
        fullWidth
        onFocus={() => {
          setActive(true);
        }}
        onBlur={() => {
          setActive(false);
        }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      ></TextField>
    </Stack>
  );
};

export default TypeItem;
