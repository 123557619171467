import * as React from 'react';
import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { Box, Button, Typography } from '@mui/material';
// import { log } from '@avocadoui/utils';

// import { version } from '../package.json';

const isProd = process.env.NODE_ENV === 'production';
const isOfficial = window.location.hostname === 'v.fbmms.cn';
const canInitSentry = isProd && isOfficial;
console.log('canInitSentry:', canInitSentry);
// if (canInitSentry) {
//   Sentry.init({
//     dsn: 'https://a3c5d147362b4d649bde6d78fb0f9784@checkapi.fbmms.cn/4',
//     integrations: [
//       new Integrations.BrowserTracing(),
//       new log.posthog.SentryIntegration(log.posthog, 'elihr', 4),
//     ],
//     environment: process.env.NODE_ENV,
//     release: `app-rtc-${version}`,

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     ignoreErrors: [
//       'Failed to fetch',
//       'Timeout has occurred',
//       'ResizeObserver loop limit exceeded',
//       'TypeError: window.bannerNight is not a function',
//       'IndexSizeError: The index is not in the allowed range.',
//       'AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22',
//     ],
//   });
// }

function Fallback() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <img src="https://webcdn.fbmms.cn/assets/icons/Ufo.svg" alt="ufo" />
      <Typography variant="h5" style={{ marginTop: 32, marginBottom: 16 }}>
        啊哦…页面被外星人带走啦！
      </Typography>
      <Typography
        variant="body2"
        style={{
          color: 'rgba(0, 0, 0, 0.56)',
          marginBottom: 32,
        }}
      >
        请尝试重新刷新页面
      </Typography>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.reload();
        }}
      >
        刷新当前页
      </Button>
    </Box>
  );
}

class ErrorBoundary extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <Sentry.ErrorBoundary fallback={<Fallback />}>
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
