import { RoomContextProvider } from '../Room/useMachineContext';
// export { default as AISimulate } from './Simulate';
import Simulate from './Simulate';
// import Interview from './Interview';
import SimulateList from './SimulateList';
import EnterpriseSimulate from './EnterpriseSimulate';
export { default as AIPreviewCheck } from './PreviewCheck';
export { default as AIInterview } from './Interview';
export { default as AIWelcome } from './Welcome';
export { default as AIReport } from './Report';
// export { default as AISimulateList } from './SimulateList';
// export { default as AIEnterpriseSimulateList } from './EnterpriseSimulate';
export const AISimulate = () => {
  return (
    <RoomContextProvider>
      <Simulate />
    </RoomContextProvider>
  );
};

export const AISimulateList = () => {
  return (
    <RoomContextProvider>
      <SimulateList />
    </RoomContextProvider>
  );
};
export const AIEnterpriseSimulateList = () => {
  return (
    <RoomContextProvider>
      <EnterpriseSimulate />
    </RoomContextProvider>
  );
};
