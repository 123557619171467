import { Paper } from '@mui/material';
import { Stack, Box } from 'fbm-ui/lib/mui';
import { FC, ReactNode } from 'react';
import { StyledVideo } from '../styles';

export interface SingleChoiceProps {
  selected?: string;
  title: ReactNode;
  pusher?: ReactNode;
  okButon?: ReactNode;
  extra?: {
    question: string[];
  };
}

const Alphabet: FC<SingleChoiceProps> = ({
  selected,
  title,
  pusher,
  okButon,
  extra,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      height={612}
      sx={{
        background: '#F9FAF9',
      }}
    >
      <Stack spacing={4} p={2} component={Paper} sx={{ position: 'relative' }}>
        <Stack>{title}</Stack>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="center">
            {Array.from(extra?.question)?.map((ele) => (
              <Box
                key={ele}
                sx={{
                  cursor: 'pointer',
                  width: 48,
                  height: 48,
                  background: ' #475A60',
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 28,
                  color: '#fff',
                  fontWeight: 500,
                  lineHeight: 1.5,
                }}
              >
                {ele}
              </Box>
            ))}
          </Stack>
        </Stack>

        {okButon}
      </Stack>
      <Stack
        component={Paper}
        sx={{
          position: 'relative',
          width: 325,
          flexShrink: 0,
          height: 1,
          backgroundSize: '100% auto',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            "url('//webcdn.fbmms.cn/assets/ytGX/sRsajH3OEGH1NrzqjSW3n.png')",
          aspectRatio: '16/9',
        }}
      >
        <StyledVideo size="small">{pusher}</StyledVideo>
      </Stack>
    </Stack>
  );
};

export default Alphabet;
