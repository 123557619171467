import * as React from 'react';
import * as Sentry from '@sentry/react';
import { getUserType } from '../utils/localStorage';
import { useAppSelector } from '../redux/hooks';

function useSentry() {
  const { agoraUid: uid, username } =
    useAppSelector((state) => state.account) || {};

  const role = getUserType();

  React.useEffect(() => {
    if (uid || username || role) {
      Sentry.setUser({
        uid,
        username,
        role,
      });
    }
  }, [uid, username, role]);
}

export default useSentry;
