import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
import middleware from './middleware';

const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
