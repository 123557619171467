import { useMutation } from 'react-query';
import { toast } from '@avocadoui/components';
import AccountAPI from '../../api/account';
import { log } from '@avocadoui/utils';

function useMutationSendCodeToMobile() {
  const mutation = useMutation(
    async ({
      mobile,
      mobileAreaCode,
    }: {
      mobile: string;
      mobileAreaCode: string;
    }) => {
      const result = await AccountAPI.sendCodeToMobile(mobile, mobileAreaCode);
      return result;
    },
    {
      onSuccess: (res, mobile) => {
        if (res.code === 0) {
          toast.success('验证码发送成功');
          log.capture('SUCCESS:发送验证码', { account: mobile });
        } else {
          toast.error(res.message);
          log.capture('FAIL:发送验证码', {
            account: mobile,
            error: res.message,
          });
        }
      },
    }
  );
  return mutation;
}

export default useMutationSendCodeToMobile;
