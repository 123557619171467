import { useEffectOnce } from 'usehooks-ts';
import RoomModel from './RoomModel';

function useEffectPlayLocalUser() {
  useEffectOnce(() => {
    RoomModel.localTracks?.videoTrack?.play('local-player-container', {
      fit: 'contain',
    });
  });
}

export default useEffectPlayLocalUser;
