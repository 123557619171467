import * as React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  Box,
  IconButton,
  KeyboardArrowDownIcon,
  Popover,
  Typography,
} from 'fbm-ui';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { formatDeviceLabel } from './helpers';
import { roomActions } from './slice';
import { useTranslation } from 'react-i18next';

function Content() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const camerasInfo = useAppSelector((state) => state.room.camerasInfo);
  const selectedCameraId = useAppSelector(
    (state) => state.room.selectedCameraId
  );

  const handleChangeMic = (event: React.ChangeEvent<HTMLInputElement>) => {
    const deviceId = event.target.value;
    dispatch(roomActions.setSelectedCameraId(deviceId));
  };

  return (
    <Box width="360px" py={2} px={3}>
      <Typography variant="body1">{t('Camera')}</Typography>
      <RadioGroup
        value={selectedCameraId}
        onChange={handleChangeMic}
        sx={{ mt: 1, gap: 1 }}
      >
        {camerasInfo.map((option) => (
          <FormControlLabel
            key={option.deviceId}
            value={option.deviceId}
            control={<Radio />}
            label={formatDeviceLabel(
              option.label,
              option.deviceId === 'default'
            )}
          />
        ))}
      </RadioGroup>
    </Box>
  );
}

function CameraPopover() {
  const [open, setOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setOpen((o) => !o);
  }, []);

  return (
    <Popover open={open} content={<Content />} onClickAway={handleToggle}>
      <IconButton size="small" onClick={handleToggle}>
        <KeyboardArrowDownIcon
          style={{
            transform: `rotate(${open ? '180deg' : '0deg'})`,
            transition: 'transform 0.2s',
          }}
        />
      </IconButton>
    </Popover>
  );
}

export default CameraPopover;
