import { Toaster as T } from 'react-hot-toast';

function Toaster() {
  return (
    <T
      toastOptions={{
        style: {
          padding: '8px 16px',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
          color: 'rgba(0, 0, 0, 0.86)',
          borderRadius: '4px',
          boxShadow:
            '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
        },
        success: {
          iconTheme: {
            primary: '#00D99D',
            secondary: 'white',
          },
        },
        error: {
          iconTheme: {
            primary: '#FF6C6C',
            secondary: 'white',
          },
        },
      }}
    />
  );
}

export default Toaster;
