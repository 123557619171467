import client from './client';
import { isInterviewee, isInterviewer } from '../utils/localStorage';

function checkMobile(roomToken: string, mobile: string) {
  return client('rtcInterview/login/permissionCheck', {
    body: {
      roomToken,
      mobile,
    },
  });
}

function sendCodeToMobile(mobile: string, mobileAreaCode: string) {
  return client('mobile/login/sendSmsCode', {
    body: {
      mobile,
      mobileAreaCode,
    },
  });
}

function sendCodeToEmail(email: string, roomToken: string) {
  return client('email/login/sendCode', {
    body: {
      email,
      roomToken,
      event: 1,
    },
  });
}

function loginByMobile(
  roomToken: string,
  mobile: string,
  mobileAreaCode: string,
  code: string
) {
  return client('rtcInterview/login', {
    body: {
      roomToken,
      mobile,
      mobileAreaCode,
      code,
    },
  });
}

function loginByEmail(roomToken: string, email: string, code: string) {
  return client('rtcInterview/loginEmail', {
    body: {
      roomToken,
      email,
      code,
    },
  });
}

function userInfo(type: 1 | 2, roomToken: string) {
  if (type === 1) {
    return client(`rtcInterview/${roomToken}/interviewer/userInfo`);
  }
  return client(`rtcInterview/${roomToken}/interviewee/userInfo`);
}

function refreshAgoraToken(roomToken: string) {
  if (isInterviewer()) {
    return client(`rtcInterview/refreshAgoraToken/interviewer/${roomToken}`);
  }
  if (isInterviewee()) {
    return client(`rtcInterview/refreshAgoraToken/interviewee/${roomToken}`);
  }
}

function getUserVirtualBackground() {
  return client('rtcInterview/interviewer/virtual/backgroundInfo');
}

function setUserVirtualBackground(data: any) {
  return client('rtcInterview/interviewer/virtual/backgroundInfo', {
    body: data,
  });
}

function getMobileCodes() {
  return client('api/v1/setting/mobile/codes');
}

const AccountAPI = {
  checkMobile,
  sendCodeToMobile,
  sendCodeToEmail,
  loginByMobile,
  loginByEmail,
  userInfo,
  refreshAgoraToken,
  getUserVirtualBackground,
  setUserVirtualBackground,
  getMobileCodes,
};

export default AccountAPI;
