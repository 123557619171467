import { useEffect } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { isInterviewee } from '../../utils/localStorage';
import RoomModel from './RoomModel';
import useRoomToken from './useRoomToken';

function useEffectStartAntiCheat() {
  const roomToken = useRoomToken();
  const rtcCheatInfo = useAppSelector((state) => state.account.rtcCheatInfo);
  const {
    cheatKey,
    rtcUserToken: userToken,
    rtcTitle: title,
  } = rtcCheatInfo || {};

  useEffect(() => {
    if (isInterviewee() && cheatKey) {
      const data = { roomToken, userToken, title: title || '视频沟通' };
      RoomModel.enableAntiProcessor(cheatKey, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cheatKey]);
}

export default useEffectStartAntiCheat;
