import * as React from 'react';
import { useRoomSend } from './useMachineContext';
import useRoom from './useRoom';
import { useAppSelector } from '../../redux/hooks';

function useEffectLeaveRoom() {
  const send = useRoomSend();
  const { leave } = useRoom();
  const leaveRoomDispatched = useAppSelector(
    (state) => state.room.leaveRoomDispatched
  );

  React.useEffect(() => {
    if (leaveRoomDispatched > 1) {
      send('LEAVE');
      leave();
      sessionStorage.clear();
    }
  }, [send, leaveRoomDispatched, leave]);
}

export default useEffectLeaveRoom;
