import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { toast } from '@avocadoui/components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { roomActions } from './slice';
import { isInterviewer } from '../../utils/localStorage';
import useQueryPositionInfo from './useQueryPositionInfo';

function toastAtTitleBar(username: string) {
  const currentTitle = document.title;
  const toastTitle = `【${username}已上线】`;
  const hasToast = currentTitle.indexOf(toastTitle) > -1;
  const newTitle = hasToast
    ? currentTitle.split(toastTitle)[1]
    : `${toastTitle}${currentTitle}`;
  document.title = newTitle;
}

function clearToastAtTitleBar(id?: NodeJS.Timeout, title?: string) {
  if (id && title) {
    clearInterval(id);
    document.title = title;
  }
}

const useEffectToastWhenJoinWaitingRoom = () => {
  const dispatch = useAppDispatch();
  const joinWaitingRoomUserId = useAppSelector(
    (state) => state.room.joinWaitingRoomUserId
  );
  const roomUsers = useAppSelector((state) => state.room.roomUsers);

  const positionInfoRes = useQueryPositionInfo();
  const isWaitMode = positionInfoRes?.data?.model === 2;
  const shouldShowToast = isInterviewer() && isWaitMode;

  const nameId = React.useMemo(() => {
    if (joinWaitingRoomUserId) {
      const user = roomUsers.find((u) => u.agoraUid === +joinWaitingRoomUserId);
      if (user) {
        return `${joinWaitingRoomUserId}::${user.userName}`;
      }
    }
    return '';
  }, [joinWaitingRoomUserId, roomUsers]);

  const isAtCurrentTab = React.useRef(true);
  const originalTitle = React.useRef('');
  const titleBarId = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    if (shouldShowToast && nameId) {
      const [, username] = nameId.split('::');
      if (isAtCurrentTab.current) {
        toast.success(`${username}已上线`);
      } else {
        originalTitle.current = document.title;
        titleBarId.current = setInterval(() => toastAtTitleBar(username), 1000);
      }
      dispatch(roomActions.setJoinWaitingRoomUserId(''));
    }
  }, [shouldShowToast, nameId, dispatch]);
  useEffectOnce(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        isAtCurrentTab.current = true;
        clearToastAtTitleBar(titleBarId.current, originalTitle.current);
      } else {
        isAtCurrentTab.current = false;
      }
    });
  });

  return null;
};

export default useEffectToastWhenJoinWaitingRoom;
