import { Stack, Text } from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';

function ResumeTab() {
  const resumeImgUrl = useAppSelector(
    (state) => state.room.positionInfoForCandidate.resumeImg
  );

  if (resumeImgUrl) {
    return (
      <Stack>
        <img src={resumeImgUrl} style={{ width: '100%' }} alt="" />
      </Stack>
    );
  }

  return (
    <Stack spacing={3} alignItems="center">
      <img
        src="//webcdn.fbmms.cn/interview/live/no-resume.svg"
        style={{ maxWidth: '200px' }}
        alt=""
      />
      <Text variant="body1" fontWeight="fontWeightBold">
        尚无简历
      </Text>
    </Stack>
  );
}

export default ResumeTab;
