import { useQuery } from 'react-query';
import useRoomToken from './useRoomToken';
import RoomAPI from '../../api/room';
import { useAppDispatch } from '../../redux/hooks';
import { roomActions } from './slice';
import { isInterviewer } from '../../utils/localStorage';

interface IRoomInfo {
  channelStatus: 0 | 1 | 2 | 3 | 4; // 0:未开始 1:开始 2:暂停 3:继续 4:结束
  roomType: 1 | 2; // 1:视频沟通 2:实时面试
}

const placeholderData: IRoomInfo = {
  channelStatus: 0,
  roomType: 2,
};

function useQueryRoomInfo() {
  const roomToken = useRoomToken();
  const dispatch = useAppDispatch();
  return useQuery(
    ['roomInfo', roomToken],
    async () => {
      const result = await RoomAPI.getRoomInfo(roomToken);
      dispatch(roomActions.setRoomInfo(result.data));
      return result.data;
    },
    {
      placeholderData,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: isInterviewer(),
    }
  );
}

export default useQueryRoomInfo;
