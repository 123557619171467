import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import AgoraRTC, {
  CameraVideoTrackInitConfig,
  MicrophoneAudioTrackInitConfig,
} from 'agora-rtc-sdk-ng';
import { toast, LoadingDots } from '@avocadoui/components';
import { isMobile, log, sleep } from '@avocadoui/utils';

import { roomActions } from './slice';
import { useAppDispatch } from '../../redux/hooks';
import RoomModel from './RoomModel';
import { useTranslation } from 'react-i18next';

const useEffectGetDevices = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isInitialed, setIsInitialed] = React.useState(false);

  useEffectOnce(() => {
    async function initCamera() {
      if (!RoomModel.rtcClient) await RoomModel.createRtcClient();

      try {
        const camerasInfo = await AgoraRTC.getCameras();
        console.log('camerasInfo:', camerasInfo);
        dispatch(roomActions.setCamerasInfo(camerasInfo));

        const cameraParams: CameraVideoTrackInitConfig = {
          encoderConfig: '720p',
        };
        const cameraId = camerasInfo?.[0]?.deviceId;
        if (cameraId) cameraParams.cameraId = cameraId;
        const videoTrack = await AgoraRTC.createCameraVideoTrack(cameraParams);

        RoomModel.localTracks.videoTrack = videoTrack;
        // 播放本地摄像头
        RoomModel.localTracks.videoTrack?.play('local-player-test-container', {
          fit: 'contain',
        });
        dispatch(roomActions.setIsLocalVideoMuted(false));
        log.capture('SUCCESS:获取摄像头', { deviceId: cameraId });
        return true;
      } catch (error) {
        console.log('initCamera error:', error);
        if (error?.code === 'PERMISSION_DENIED') {
          // toast.error('未获取到摄像头权限');
        } else if (error?.code === 'DEVICE_NOT_FOUND') {
          toast.error('未找到摄像头');
        }
        dispatch(roomActions.setIsLocalVideoMuted(true));
        dispatch(roomActions.setIsLocalVideoPermissionDenied(true));
        log.capture('FAIL:获取摄像头', { error });
        return false;
      }
    }

    async function initMic() {
      try {
        const micsInfo = await AgoraRTC.getMicrophones();
        console.log('micsInfo:', micsInfo);
        dispatch(roomActions.setMicsInfo(micsInfo));

        const microphoneParams: MicrophoneAudioTrackInitConfig = {};
        const microphoneId = micsInfo?.[0]?.deviceId;
        if (microphoneId) microphoneParams.microphoneId = microphoneId;
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack(
          microphoneParams
        );

        RoomModel.localTracks.audioTrack = audioTrack;
        dispatch(roomActions.setIsLocalAudioMuted(false));
        log.capture('SUCCESS:获取麦克风', { deviceId: microphoneId });

        // 检测麦克风音量
        const audioVolumeLevelId = setInterval(() => {
          const level = audioTrack.getVolumeLevel();
          if (level > 0 && audioVolumeLevelId) {
            RoomModel.audioVolumeLevelIsGood = true;
            log.capture('SUCCESS:麦克风音量', { audioVolumeLevel: level });
            clearInterval(audioVolumeLevelId);
          } else {
            RoomModel.audioVolumeLevelIsGood = false;
          }
        }, 1000);

        return true;
      } catch (error) {
        console.log('initMic error:', error);
        if (error?.code === 'PERMISSION_DENIED') {
          // toast.error('未获取到麦克风权限');
        } else if (error?.code === 'DEVICE_NOT_FOUND') {
          toast.error('未找到麦克风');
        }
        dispatch(roomActions.setIsLocalAudioMuted(true));
        dispatch(roomActions.setIsLocalAudioPermissionDenied(true));
        log.capture('FAIL:获取麦克风', { error });
        return false;
      }
    }

    async function initSpeaker() {
      try {
        const speackersInfo = await AgoraRTC.getPlaybackDevices();
        dispatch(roomActions.setSpeakersInfo(speackersInfo));
        log.capture('SUCCESS:获取扬声器');
        return true;
      } catch (error) {
        console.log('initSpeaker error:', error);
        if (error?.code === 'PERMISSION_DENIED') {
          // toast.error('未获取到扬声器权限');
        } else if (error?.code === 'DEVICE_NOT_FOUND') {
          toast.error('未找到扬声器');
        }
        log.capture('FAIL:获取扬声器', { error });
        return false;
      }
    }

    async function init() {
      try {
        await toast.promise(
          Promise.all([initCamera(), initMic(), initSpeaker(), sleep(2000)]),
          {
            loading: (
              <span>
                {t('Initializing Devices.')}
                <LoadingDots />
              </span>
            ),
            success: t('Initialization completed.'),
            error: '初始化失败',
          }
        );
        setIsInitialed(true);
      } catch (error) {
        console.log('useEffectGetDevices error:', error);
      }
    }

    if (!isMobile()) {
      init();
    }
  });

  return {
    isInitialed,
  };
};

export default useEffectGetDevices;
