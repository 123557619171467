import { Paper } from '@mui/material';
import { Stack } from 'fbm-ui/lib/mui';
import { FC, ReactNode } from 'react';
import { StyledVideo } from '../styles';
import { Box, Typography } from 'fbm-ui';

export interface SingleChoiceProps {
  title: ReactNode;
  pusher?: ReactNode;
  okButon?: ReactNode;
  extra?: {
    readText: string;
  };
}

const Alphabet: FC<SingleChoiceProps> = ({ title, pusher, okButon, extra }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      height={612}
      sx={{
        background: '#F9FAF9',
      }}
    >
      <Stack
        spacing={4}
        py={2}
        component={Paper}
        sx={{ position: 'relative', width: 747, borderRadius: 2 }}
      >
        <Stack px={2}>{title}</Stack>
        <Stack
          spacing={2}
          px={2}
          flexGrow={1}
          sx={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Stack direction="row" spacing={2} justifyContent="center">
            <Typography variant="h5">{extra.readText}</Typography>
          </Stack>
        </Stack>
        <Box height={32}>{okButon}</Box>
      </Stack>
      <Stack
        height={612}
        component={Paper}
        sx={{
          borderRadius: 2,
          position: 'relative',
          width: 325,
          flexShrink: 0,
          backgroundSize: '100% auto',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            "url('//webcdn.fbmms.cn/assets/ytGX/sRsajH3OEGH1NrzqjSW3n.png')",
          aspectRatio: '16/9',
        }}
      >
        <StyledVideo size="small">{pusher}</StyledVideo>
      </Stack>
    </Stack>
  );
};

export default Alphabet;
