import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { Box, Stack } from '@avocadoui/components';
import useRTC from './useRTC';
import { useRoomSend } from './useMachineContext';
import VideoContainerLayout from './VideoContainerLayout';
import ControllerLayout from './ControllerLayout';
import SidebarLayout from './SidebarLayout';
import CommentLayout from './CommentLayout';
import useEffectSetCanComment from '../Comment/useEffectSetCanComment';
import useEffectSetJoinTimestamp from './useEffectSetJoinTimestamp';
import useEffectConnectedRoom from './useEffectConnectedRoom';
import useEffectRememberRoomToken from './useEffectRememberRoomToken';
import useEffectChangedMaxAudioVolumeUserId from './useEffectChangedMaxAudioVolumeUserId';
import useEffectFetchRemoteNetworkQuality from './useEffectFetchRemoteNetworkQuality';
import useEffectRoomOnlinePing from './useEffectRoomOnlinePing';
import useEffectToastWhenJoinWaitingRoom from './useEffectToastWhenJoinWaitingRoom';
import useEffectLeaveRoom from './useEffectLeaveRoom';
import useQueryRoomUsersInfo from './useQueryRoomUsersInfo';
import useEffectPreventTabClose from './useEffectPreventTabClose';
import useLatestRef from '../../hooks/useLatestRef';
import useEffectVirtualBackground from './useEffectVirtualBackground';
import useEffectStartAntiCheat from './useEffectStartAntiCheat';

function RoomPublished() {
  const send = useRoomSend();
  const { join: joinRTC, publish: publishStreams } = useRTC();

  useEffectSetCanComment();
  useEffectSetJoinTimestamp();
  useEffectConnectedRoom();
  useEffectRememberRoomToken();
  useEffectChangedMaxAudioVolumeUserId();
  useEffectFetchRemoteNetworkQuality();
  useEffectRoomOnlinePing();
  useEffectToastWhenJoinWaitingRoom();
  useEffectLeaveRoom();
  useEffectPreventTabClose();
  useEffectVirtualBackground();
  useEffectStartAntiCheat();

  useQueryRoomUsersInfo();

  const handleJoin = React.useCallback(async () => {
    try {
      await joinRTC();
      await publishStreams();
      send('NEXT');
    } catch (error) {
      console.error('handleJoin error:', error);
    }
  }, [send, joinRTC, publishStreams]);

  const handleJoinRef = useLatestRef(handleJoin);
  useEffectOnce(() => {
    handleJoinRef.current?.();
  });

  return (
    <Box
      flex={1}
      display="flex"
      position="relative"
      sx={{ overflowX: 'hidden' }}
    >
      <Stack flex={1} style={{ width: 'calc(100vw - 320px)' }}>
        <Box flex={1} sx={{ position: 'relative', backgroundColor: '#f9faf9' }}>
          <VideoContainerLayout />
          <CommentLayout />
        </Box>

        <ControllerLayout />
      </Stack>

      <SidebarLayout />
    </Box>
  );
}

export default RoomPublished;
