import * as React from 'react';
import { Box, Stack } from '@avocadoui/components';
import { sleep } from '@avocadoui/utils';
import useRoom from './useRoom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import RoomModel from './RoomModel';
import { roomActions } from './slice';
import LocalPlayerContainer from './LocalPlayerContainer';
import RemotePlayerContainer from './RemotePlayerContainer';
import useEffectResizeVideoInFocusedMode from './useEffectResizeVideoInFocusedMode';
import { useDimensions } from '../../hooks';
import PlayerListInFocusedMode from './PlayerListInFocusedMode';
import { SIDEBAR_TRANSFORM_DURATION } from './const';

function ResizeLayout({ children }: { children: React.ReactNode }) {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const isUserListDrawerOpen = useAppSelector(
    (state) => state.room.isUserListDrawerOpen
  );
  const isPlayerListDrawerOpen = useAppSelector(
    (state) => state.room.isPlayerListDrawerOpen
  );
  const [playerContainerRef, playerContainerSize] = useDimensions({
    deps: [open1, open2],
  });
  const { width: playerContainerWidth, height: playerContainerHeight } =
    playerContainerSize;
  useEffectResizeVideoInFocusedMode({
    playerContainerWidth,
    playerContainerHeight,
  });

  React.useEffect(() => {
    async function init() {
      await sleep(SIDEBAR_TRANSFORM_DURATION);
      setOpen1(isUserListDrawerOpen);
      setOpen2(isPlayerListDrawerOpen);
    }
    init();
  }, [isUserListDrawerOpen, isPlayerListDrawerOpen]);

  return (
    <div
      ref={playerContainerRef}
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 60px - 72px)',
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  );
}

function FocusedModeLayout() {
  const {
    ingUserIds,
    localUserId,
    focusedUserId,
    focusedIsLocalUser,
    unfocusedUserIds,
  } = useRoom();
  const dispatch = useAppDispatch();

  const handleSetFocusedUserId = React.useCallback(
    (uid: string) => {
      dispatch(roomActions.setFocusedUserId(uid));
    },
    [dispatch]
  );

  React.useEffect(() => {
    RoomModel.localTracks?.videoTrack?.play('local-player-container', {
      fit: 'contain',
    });
  }, [focusedIsLocalUser]);

  React.useEffect(() => {
    ingUserIds.forEach((id) => {
      const user = RoomModel.rtcClient.remoteUsers.find(
        (u) => u.uid.toString() === id
      );
      if (user) {
        const player = document.getElementById(`remote-player-${id}`);
        if (player) {
          user.videoTrack?.play(player, { fit: 'contain' });
          user.audioTrack?.play();
        }
      }
    });
  }, [ingUserIds, unfocusedUserIds]);

  React.useEffect(() => {
    // 当变更聚焦时，将在线的所有流进行大小流变更
    ingUserIds.forEach((id) => {
      const focused = id === focusedUserId ? 0 : 1;
      RoomModel.rtcClient.setRemoteVideoStreamType(+id, focused);
    });

    // 当聚焦的大流离线时，将在线的第一个流设为聚焦为大流
    if (localUserId !== focusedUserId && !ingUserIds.includes(focusedUserId)) {
      dispatch(roomActions.setFocusedUserId(ingUserIds[0]));
    }
  }, [ingUserIds, focusedUserId, localUserId, dispatch]);

  return (
    <Stack direction="row">
      <PlayerListInFocusedMode>
        <Box display="flex" alignItems="center" height="100%">
          <Box
            flex={1}
            className="hideScroller"
            sx={{
              minHeight: '0px',
              maxHeight: 'calc(100vh - 60px - 72px)',
              overflowY: 'auto',
            }}
          >
            <Stack spacing={0.5}>
              {!focusedIsLocalUser && (
                <Box
                  key="mini-local-player"
                  onClick={() => handleSetFocusedUserId(localUserId)}
                  sx={{ cursor: 'pointer', zIndex: 3 }}
                >
                  <LocalPlayerContainer />
                </Box>
              )}
              {unfocusedUserIds.map((uid) => {
                return (
                  <Box
                    key={`mini-remote-player-${uid}`}
                    onClick={() => handleSetFocusedUserId(uid)}
                    sx={{ cursor: 'pointer', zIndex: 3 }}
                  >
                    <RemotePlayerContainer uid={uid} />
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Box>
      </PlayerListInFocusedMode>

      <ResizeLayout>
        {focusedIsLocalUser ? (
          <LocalPlayerContainer />
        ) : (
          <RemotePlayerContainer uid={focusedUserId} />
        )}
      </ResizeLayout>
    </Stack>
  );
}

export default FocusedModeLayout;
