import { createSlice } from '@reduxjs/toolkit';

import { CODE_LENGTH, DEFAULT_COUNTDOWN_NUMBER } from './const';

const slice = createSlice({
  name: 'login',
  initialState: {
    mode: 0, // 0:手机号 1:邮箱
    step: 0, // 0:登录 1:验证码
    mobile: '',
    mobileAreaCode: '',
    email: '',
    code: '',
    countdown: DEFAULT_COUNTDOWN_NUMBER,
    disabledCodeBtn: true,
    disabledSubmitBtn: true,
    isMobileError: false,
    isCodeError: false,
    isAgreementChecked: false,
    mobileCodes: [],
  },
  reducers: {
    nextStep: (state) => {
      state.step += 1;
    },
    prevStep: (state) => {
      state.step -= 1;
    },
    editMobile: (state, action) => {
      const { mobile, mobileAreaCode } = action.payload || {};
      state.mobile = mobile || '';
      state.mobileAreaCode = mobileAreaCode || '';
    },
    editCode: (state, action) => {
      const code = action.payload.slice(0, CODE_LENGTH);
      state.code = code;
      state.disabledSubmitBtn = code.length < CODE_LENGTH;
      state.isCodeError = false;
    },
    count: (state) => {
      if (state.countdown <= 0) {
        state.countdown = DEFAULT_COUNTDOWN_NUMBER;
      } else {
        state.countdown -= 1;
      }
    },
    setMobileError: (state) => {
      state.isMobileError = true;
    },
    setCodeError: (state) => {
      state.isCodeError = true;
    },
    checkAgreement: (state, action) => {
      state.isAgreementChecked = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setStepOneForm: (state, action) => {
      const {
        mobile: { mobile = '', mobileAreaCode = '' } = {},
        email,
        isAgreementChecked,
      } = action.payload || {};
      state.mobile = mobile || '';
      state.mobileAreaCode = mobileAreaCode || '';
      state.email = email || '';
      state.isAgreementChecked = isAgreementChecked || false;
    },
    resetCountdown: (state) => {
      state.countdown = DEFAULT_COUNTDOWN_NUMBER;
    },
    setMobileCodes: (state, action) => {
      state.mobileCodes = action.payload;
    },
  },
});

export const loginActions = slice.actions;
export const loginReducer = slice.reducer;
