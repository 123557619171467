export const OEM_MAP = {
  official: {
    loginLayout: 'row',
    entity: '企业',
    name: '方便面招聘',
    home: 'https://hr.fbmms.cn/',
    logo: '//public-static-assets.oss-cn-beijing.aliyuncs.com/img/hr_logo.png',
    shortcut: '/favicon.ico',
    loading: '//webcdn.fbmms.cn/web/saas/asset/icons/logo-loading.svg',
    loginRight:
      '//webcdn.fbmms.cn/web/saas/asset/icons/loginEntryAnimation.svg',
    rpaEmptyBackground: '//webcdn.fbmms.cn/assets/img/rpaUnBindEmpty.png',
    loginRightColor: '#4CAF50',
    privacy: '//webcdn.fbmms.cn/data/public_privacy.txt',
    policy: '//webcdn.fbmms.cn/data/public_policy.txt',
    company: '北京鳄梨科技有限公司',
    icp: '京ICP备19043412号-3',
  },
  fbmai: {
    loginLayout: 'row',
    entity: '企业',
    name: '方便面招聘',
    home: 'https://hr.fbmai.cn',
    logo: '//public-static-assets.oss-cn-beijing.aliyuncs.com/img/hr_logo.png',
    shortcut: '/favicon.ico',
    loading: '//webcdn.fbmms.cn/web/saas/asset/icons/logo-loading.svg',
    loginRight:
      '//webcdn.fbmms.cn/web/saas/asset/icons/loginEntryAnimation.svg',
    rpaEmptyBackground: '//webcdn.fbmms.cn/assets/img/rpaUnBindEmpty.png',
    loginRightColor: '#4CAF50',
    privacy: '//webcdn.fbmms.cn/data/public_privacy.txt',
    policy: '//webcdn.fbmms.cn/data/public_policy.txt',
    company: '北京鳄梨智面科技有限公司',
    icp: '京ICP备2024059675号',
  },
  qianyu: {
    loginLayout: 'row-reverse',
    entity: '企业',
    name: 'KUAI面试',
    home: 'https://www.kuaims.cn/',
    logo: '//webcdn.fbmms.cn/assets/3bOe/rmLFaS_ji0j882KUx5aLM',
    shortcut: '//webcdn.fbmms.cn/assets/-WJl/bh3RnliOxrOWLZIrcSc_h',
    loading: '//webcdn.fbmms.cn/assets/fJaZ/TESba4dSnoGuId6rD_L0t',
    loginRight: '//webcdn.fbmms.cn/assets/xSvg/6AYuS7wU1fb7asGq62dPe',
    rpaEmptyBackground: '//webcdn.fbmms.cn/assets/vP2D/U-wZWFbIP4YnNBsZYWUQA',
    loginRightColor: '#00BEAB',
    privacy: '//rule.kuaims.cn/privacy.html',
    policy: '//rule.kuaims.cn/user.html',
    company: '无锡浅雨科技有限公司',
    icp: '苏ICP备2022022620号-6',
    interviewHost: 'https://i.kuaims.cn/',
  },
  cmcc: {
    loginLayout: 'row',
    entity: '企业',
    name: '方便面招聘',
    home: 'https://cmcc.hr.fbmms.cn/',
    logo: '//webcdn.fbmms.cn/assets/wqst/UPvCLJzryIelbNi1jHgfv',
    shortcut: '/favicon.ico',
    loading: '//webcdn.fbmms.cn/web/saas/asset/icons/logo-loading.svg',
    loginRight: '//webcdn.fbmms.cn/assets/xSvg/6AYuS7wU1fb7asGq62dPe',
    rpaEmptyBackground: '//webcdn.fbmms.cn/assets/img/rpaUnBindEmpty.png',
    loginRightColor: '#00BEAB',
    privacy: '//webcdn.fbmms.cn/data/public_privacy.txt',
    policy: '//webcdn.fbmms.cn/data/public_policy.txt',
    company: '北京鳄梨科技有限公司',
    icp: '京ICP备19043412号-3',
  },
  jiuyeqiao: {
    loginLayout: 'row',
    entity: '院校',
    name: '就业桥',
    home: 'https://cmcc.hr.fbmms.cn/',
    logo: '//webcdn.fbmms.cn/assets/dm7y/5H47tzGylQkv7Zyhdwx_o',
    shortcut: '//webcdn.fbmms.cn/assets/xV6P/oGOuR4BPhB6QI6WH58mTt',
    loading: '//webcdn.fbmms.cn/assets/nZjt/7R7fXb5AonBcr-oYLmohd',
    loginRight: '//webcdn.fbmms.cn/assets/xSvg/6AYuS7wU1fb7asGq62dPe',
    rpaEmptyBackground: '//webcdn.fbmms.cn/assets/img/rpaUnBindEmpty.png',
    loginRightColor: '#00BEAB',
    privacy: '//www.jiuyeqiao.cn/yinsi/xieyi',
    policy: '//www.jiuyeqiao.cn/yinsi/xieyi',
    company: '中教智网（北京）信息技术有限公司',
    icp: '京ICP备2020046552号-4   京公网安备1101082038637号',
    interviewHost: 'https://zmx.jiuyeqiao.cn/',
  },
  mstkj: {
    loginLayout: 'row',
    entity: '企业',
    name: '面试通招聘',
    home: 'https://cmcc.hr.fbmms.cn/',
    logo: '//webcdn.fbmms.cn/assets/Ey3g/wivXHni8WmlRMVVF65J5x',
    shortcut: '/favicon.ico',
    loading: '//webcdn.fbmms.cn/assets/HH5C/culI3me1JjGwNjm46y7tn',
    loginRight: '//webcdn.fbmms.cn/assets/yFHk/D8LrNjl_4cRt0N_68OOSB',
    rpaEmptyBackground: '//webcdn.fbmms.cn/assets/img/rpaUnBindEmpty.png',
    loginRightColor: '#5ACBB7',
    privacy: '//alicdn.mstkj.com.cn/user/public_privacy.txt',
    policy: '//alicdn.mstkj.com.cn/user/public_policy.txt',
    company: '北京面试通科技有限公司',
    icp: '京ICP备2024066221号-1',
  },
};

const HOST_MAP = {
  localhost: 'official',
  'i.kuaims.cn': 'qianyu',
  'cmcc.hr.fbmms.cn': 'cmcc',
  'hr.fbmai.cn': 'fbmai',
  'zmx.jiuyeqiao.cn': 'jiuyeqiao',
  'hr.mstkj.com.cn': 'mstkj',
};

export const OEM_NAME = HOST_MAP[window.location.hostname] || 'official';

export const OEM = OEM_MAP[OEM_NAME];

export const IS_OFFICIAL = OEM_NAME === 'official';
export const IS_JIUYEQIAO = OEM_NAME === 'jiuyeqiao';
export const IS_QIANYU = OEM_NAME === 'qianyu';
