import Lottie from 'lottie-react';
import peopleAnimation from './Animation/people.json';
import { Box } from 'fbm-ui/lib/mui';
import Action, { ActionProps } from './Action';
import { FC } from 'react';

type PlayerProps = ActionProps;

const Player: FC<PlayerProps> = ({ action = 'idle' }) => (
  <Box
    width={1}
    height={1}
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{
      borderRadius: 2,
      overflow: 'hidden',
      background:
        "url('//webcdn.fbmms.cn/assets/xor4/37RrJbq9B_dRgnPd3MMui.png') no-repeat center center",
      backgroundSize: 'cover',
    }}
  >
    <Box
      style={{
        width: '200px',
        height: '200px',
        position: 'absolute',
        top: 47,
        zIndex: 1,
        left: 'calc(50% - 300px)',
      }}
    >
      <Action action={action} />
    </Box>
    <Lottie
      animationData={peopleAnimation}
      loop={true}
      style={{
        width: '615px',
        height: '615px',
      }}
    />
  </Box>
);

export default Player;
