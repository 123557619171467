import * as React from 'react';
import { Box, Logo, Stack } from '@avocadoui/components';
import RoomHelp from './RoomHelp';
import RoomPositionInfo from './RoomPositionInfo';
import RoomTimer from './RoomTimer';
import { isInterviewer } from '../../utils/localStorage';
import AccountMenu from '../Account/AccountMenu';
import useSentry from '../../hooks/useSentry';
import useQueryCurrentUserInfo from './useQueryCurrentUserInfo';
import RecordStatus from './RecordStatus';
import { useRoomState } from './useMachineContext';

function Divider() {
  return (
    <Box
      sx={{
        width: '1px',
        height: '16px',
        backgroundColor: 'rgba(0,0,0,0.08)',
      }}
    />
  );
}

const RoomLayout: React.FC = ({ children }) => {
  const state = useRoomState();
  const hasPublished = ['ing.initial', 'ing.published'].some(state.matches);

  useQueryCurrentUserInfo();
  useSentry();

  const handleClickLogo = React.useCallback(() => {
    const link = isInterviewer() ? 'https://hr.fbmms.cn/' : 'https://fbmms.cn/';
    window.location.href = link;
  }, []);

  return (
    <Stack sx={{ height: '100vh' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexShrink={0}
        sx={{
          height: '60px',
          backgroundColor: 'white',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
      >
        <Stack
          direction="row"
          divider={<Divider />}
          spacing={2}
          alignItems="center"
          ml={1.5}
        >
          <Logo onClick={handleClickLogo} />
          <RoomPositionInfo />
          {hasPublished ? <RoomTimer /> : null}
          {hasPublished ? <RecordStatus /> : null}
        </Stack>
        <Stack direction="row" spacing={1} mr={2}>
          <RoomHelp />
          <AccountMenu />
        </Stack>
      </Box>
      {children}
    </Stack>
  );
};

export default RoomLayout;
