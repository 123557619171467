import * as React from 'react';
import {
  ConnectionDisconnectedReason,
  ConnectionState,
  IAgoraRTCRemoteUser,
  RemoteStreamType,
  UID,
} from 'agora-rtc-sdk-ng';
import { toast } from '@avocadoui/components';
import { isChrome, log } from '@avocadoui/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import fromQualityToScore from '../../utils/fromQualityToScore';
import RoomModel, { ILocalTracks } from './RoomModel';
import { roomActions } from './slice';
import { useRoomSend } from './useMachineContext';
import config from '../../config';
import useQueryCurrentUserInfo from './useQueryCurrentUserInfo';
import useCallbackRenewAgoraToken from './useCallbackRenewAgoraToken';
import { RTC_EXCEPTION_EVENTS } from './const';
import { accountActions } from '../Account/slice';

type MediaType = 'audio' | 'video';
type IVolumes = { level: number; uid: UID }[];
type IExceptionEvent = {
  code:
    | 1001
    | 1002
    | 1003
    | 1005
    | 2001
    | 2002
    | 2003
    | 2005
    | 3001
    | 3002
    | 3003
    | 3005
    | 4001
    | 4002
    | 4003
    | 4005;
  msg: string;
  uid: UID;
};
interface INetworkQuality {
  uplinkNetworkQuality: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  downlinkNetworkQuality: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

async function subscribe(user: IAgoraRTCRemoteUser, mediaType: MediaType) {
  const uid = user.uid;
  await RoomModel.rtcClient.subscribe(user, mediaType);
  console.log('=== subscribe success:', uid);

  // 为远程音频流设置输出设备
  if (mediaType === 'audio' && isChrome() && RoomModel.playbackDeviceId) {
    try {
      user.audioTrack?.setPlaybackDevice(RoomModel.playbackDeviceId);
    } catch (error) {
      console.log('remote audio track setPlaybackDevice error', error);
    }
  }
}

const APP_ID = config.AGORA_APP_ID || '';
let reconnectingTimeout: NodeJS.Timeout;

function useRTC() {
  const send = useRoomSend();
  const dispatch = useAppDispatch();

  const {
    data: { agoraCname: channelName, agoraUid: uid, agoraRTCToken: token },
  } = useQueryCurrentUserInfo();

  const isLocalAudioMuted = useAppSelector(
    (state) => state.room.isLocalAudioMuted
  );
  const isLocalVideoMuted = useAppSelector(
    (state) => state.room.isLocalVideoMuted
  );
  const isBeautyEffectEnabled = useAppSelector(
    (state) => state.room.isBeautyEffectEnabled
  );
  const virtualBackgroundType = useAppSelector(
    (state) => state.account.virtualOpen
  );

  const handleUserJoinAtWaiting = React.useCallback(
    (user: IAgoraRTCRemoteUser, reason: string) => {
      const id = user.uid;
      console.log('=== handleUserJoin:', id, reason);
      RoomModel.setRemoteUser(id, user);
      dispatch(roomActions.changedRemoteUsers());
      dispatch(roomActions.leftBeforeMeWaitingUserList(id.toString()));
    },
    [dispatch]
  );

  const handleUserLeftAtWaiting = React.useCallback(
    (user: IAgoraRTCRemoteUser, reason: string) => {
      const id = user.uid;
      console.log('=== handleUserLeft:', id, reason);
      RoomModel.removeRemoteUser(id);
      dispatch(roomActions.changedRemoteUsers());
      dispatch(roomActions.removeFromUserIdsAtWaiting(id));
      dispatch(roomActions.removeFromUserIdsAtPublished(id));
    },
    [dispatch]
  );

  const handleUserJoinAtPublished = React.useCallback(
    (user: IAgoraRTCRemoteUser, reason: string) => {
      const id = user.uid;
      console.log('=== handleUserJoin:', id, reason);
      RoomModel.setRemoteUser(id, user);
      dispatch(roomActions.changedRemoteUsers());
      dispatch(roomActions.addToUserIdsAtPublished(id));
      // 托底操作，取消已发送的邀请，避免邀请失效后将用户从 userIdsAtPublished 列表中移出
      try {
        RoomModel.rtmLocalInvitations?.[id.toString()]?.cancel?.();
        RoomModel.removeRTMLocalInvitations(id.toString());
      } catch (e) {
        console.log(
          'cancel rtmLocalInvitations error at handleUserJoinAtPublished:',
          e
        );
      }
    },
    [dispatch]
  );

  const handleUserLeftAtPublished = React.useCallback(
    (user: IAgoraRTCRemoteUser, reason: string) => {
      const id = user.uid;
      console.log('=== handleUserLeft:', id, reason);

      const playerContainer = document.getElementById(
        `remote-player-${id.toString()}`
      );
      playerContainer?.remove();

      RoomModel.removeRemoteUser(id);
      dispatch(roomActions.changedRemoteUsers());
      dispatch(roomActions.removeFromUserIdsAtPublished(id));

      if (reason === 'ServerTimeOut') {
        const name = RoomModel.allUsers?.[id]?.userName;
        if (name) toast.error(`${name}掉线了`);
      }
    },
    [dispatch]
  );

  const handleUserPublishedAtWaiting = React.useCallback(
    async (user: IAgoraRTCRemoteUser, mediaType: MediaType) => {
      try {
        await subscribe(user, mediaType);
        const id = user.uid;
        RoomModel.setRemoteUser(id, user);
        dispatch(roomActions.changedRemoteUsers());
        dispatch(roomActions.addToUserIdsAtWaiting(id));
        dispatch(roomActions.addToUserIdsAtPublished(id));
      } catch (error) {
        log.capture('FAIL:订阅远端流', {
          user,
          mediaType,
          error,
          handler: 'handleUserPublishedAtWaiting',
        });
      }
    },
    [dispatch]
  );

  const handleUserUnpublishedAtWaiting = React.useCallback(
    (user: IAgoraRTCRemoteUser, mediaType: MediaType) => {
      const id = user.uid;
      console.log('=== handleUserUnpublished:', id, mediaType);
      RoomModel.setRemoteUser(id, user);
      dispatch(roomActions.changedRemoteUsers());
    },
    [dispatch]
  );

  const handleUserPublishedAtPublished = React.useCallback(
    async (user: IAgoraRTCRemoteUser, mediaType: MediaType) => {
      try {
        await subscribe(user, mediaType);

        const id = user.uid;
        console.log('=== handleUserPublished:', id, mediaType);
        RoomModel.setRemoteUser(id, user);
        dispatch(roomActions.changedRemoteUsers());

        if (mediaType === 'video') {
          const remoteVideoTrack = user.videoTrack;
          const remotePlayerContainer = document.getElementById(
            `remote-player-${id.toString()}`
          );
          if (remoteVideoTrack && remotePlayerContainer) {
            remoteVideoTrack.play(remotePlayerContainer, { fit: 'contain' });
          }
        }

        if (mediaType === 'audio') {
          const remoteAudioTrack = user.audioTrack;
          remoteAudioTrack?.play();
        }
      } catch (error) {
        log.capture('FAIL:订阅远端流', {
          user,
          mediaType,
          error,
          handler: 'handleUserPublishedAtPublished',
        });
      }
    },
    [dispatch]
  );

  const handleUserUnpublishedAtPublished = React.useCallback(
    (user: IAgoraRTCRemoteUser, mediaType: MediaType) => {
      const id = user.uid;
      console.log('=== handleUserUnpublished:', id, mediaType);
      RoomModel.setRemoteUser(id, user);
      dispatch(roomActions.changedRemoteUsers());
    },
    [dispatch]
  );

  const handleVolumeIndicator = React.useCallback(
    (volumes: IVolumes) => {
      let maxAudioVolumeUserId: UID = '';
      let maxAudioVolumeLevel = 0;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      volumes.forEach((volume, index) => {
        // console.log(`=== ${index} UID ${volume.uid} Level ${volume.level}`);
        if (volume.level > maxAudioVolumeLevel) {
          maxAudioVolumeUserId = volume.uid;
          maxAudioVolumeLevel = volume.level;
        }
      });
      dispatch(
        roomActions.setMaxAudioVolumeUserId(maxAudioVolumeUserId.toString())
      );
    },
    [dispatch]
  );

  const prevNetworkQualityScore = React.useRef<undefined | number>();
  const handleNetworkQuality = React.useCallback(
    (stats: INetworkQuality) => {
      const score = fromQualityToScore(stats);
      if (score !== prevNetworkQualityScore.current) {
        if (score === 1) {
          toast.error('当前网络质量差，请尝试更换网络');
        }
        dispatch(roomActions.setNetworkQualityScore(score));
        log.capture(`网络质量: ${score}`, { score, stats });
      }
      prevNetworkQualityScore.current = score;
    },
    [dispatch]
  );

  const handleException = React.useCallback((event: IExceptionEvent) => {
    const { code, msg, uid } = event;
    const errorMessage = RTC_EXCEPTION_EVENTS[code];
    if (errorMessage) {
      if (code > 3000) toast.success(errorMessage);
      else toast.error(errorMessage);
    }
    log.capture(`FAIL:RTC exception ${code}`, { code, msg, uid });
  }, []);

  const handleConnectionStateChange = React.useCallback(
    (
      curState: ConnectionState,
      revState: ConnectionState,
      reason?: ConnectionDisconnectedReason
    ) => {
      dispatch(roomActions.setRtcConnectionState(curState));
      log.capture(
        `RTC: ${revState} -> ${curState}${reason ? ': ' + reason : ''}`
      );
      if (reason) {
        // 被踢出，比如被“移出视频”
        const isBanned = reason.indexOf('BANNED') > -1;
        if (isBanned) {
          send('LEAVE');
        }
      }
      // 连接上 RTC 后更新计数
      if (
        (revState === 'CONNECTING' || revState === 'RECONNECTING') &&
        curState === 'CONNECTED'
      ) {
        dispatch(roomActions.countConnectedRTC());
      }

      // 断网超过10分钟时自主移出
      if (revState === 'CONNECTED' && curState === 'RECONNECTING') {
        reconnectingTimeout = setTimeout(() => {
          send('LEAVE');
        }, 10 * 60 * 1000);
        toast.error('网络已断开');
      }
      if (revState === 'RECONNECTING' && curState === 'CONNECTED') {
        reconnectingTimeout && clearTimeout(reconnectingTimeout);
        toast.success('网络已恢复');
      }
    },
    [send, dispatch]
  );

  const handleStreamTypeChanged = React.useCallback(
    (uid: UID, streamType: RemoteStreamType) => {
      console.log('handleStreamTypeChanged:', uid, streamType);
    },
    []
  );

  const handleStreamFallback = React.useCallback(
    (uid: UID, isFallbackOrRecover: 'fallback' | 'recover') => {
      console.log('handleStreamFallback:', uid, isFallbackOrRecover);
    },
    []
  );

  const toggleBeautyEffect = React.useCallback(async () => {
    try {
      await RoomModel.localTracks.videoTrack?.setBeautyEffect(
        !isBeautyEffectEnabled
      );
      dispatch(roomActions.setIsBeautyEffectEnabled(!isBeautyEffectEnabled));
      log.capture(`SUCCESS:${isBeautyEffectEnabled ? '关闭' : '开启'}美颜`);
    } catch (error) {
      toast.error(`${isBeautyEffectEnabled ? '关闭' : '开启'}美颜失败`);
      log.capture(`FAIL:${isBeautyEffectEnabled ? '关闭' : '开启'}美颜`, {
        error,
      });
    }
  }, [dispatch, isBeautyEffectEnabled]);

  const toggleBlurEffect = React.useCallback(async () => {
    const shouldDisable = virtualBackgroundType !== 0;
    const nextVirtualBackgroundType = shouldDisable ? 0 : 1;
    dispatch(
      accountActions.setVirtualBackgroundType(nextVirtualBackgroundType)
    );
    if (shouldDisable) {
      log.capture('SUCCESS:关闭虚拟背景', { vbType: 'blur' });
    }
  }, [dispatch, virtualBackgroundType]);

  const handleExpiredAgoraToken = React.useCallback(() => {
    window.location.reload();
  }, []);

  const onRenewAgoraToken = useCallbackRenewAgoraToken();
  const handleRenewAgoraToken = React.useCallback(
    () => onRenewAgoraToken('RTC'),
    [onRenewAgoraToken]
  );
  const join = React.useCallback(async () => {
    try {
      if (!RoomModel.rtcClient) await RoomModel.createRtcClient();
      RoomModel.rtcClient.on('user-joined', handleUserJoinAtWaiting);
      RoomModel.rtcClient.on('user-left', handleUserLeftAtWaiting);
      RoomModel.rtcClient.on('user-published', handleUserPublishedAtWaiting);
      RoomModel.rtcClient.on(
        'user-unpublished',
        handleUserUnpublishedAtWaiting
      );
      RoomModel.rtcClient.on('exception', handleException);
      RoomModel.rtcClient.on(
        'connection-state-change',
        handleConnectionStateChange
      );
      RoomModel.rtcClient.on('stream-type-changed', handleStreamTypeChanged);
      RoomModel.rtcClient.on('stream-fallback', handleStreamFallback);
      RoomModel.rtcClient.on(
        'token-privilege-will-expire',
        handleRenewAgoraToken
      );
      RoomModel.rtcClient.on(
        'token-privilege-did-expire',
        handleExpiredAgoraToken
      );

      await RoomModel.rtcClient.join(APP_ID, channelName, token, uid);
      dispatch(roomActions.setLocalUserId(uid.toString()));
      log.capture('SUCCESS:RTC加入房间', {
        appId: APP_ID,
        channelName,
        token,
        uid,
      });
    } catch (error) {
      toast.error('连接失败', { id: 'join_room_error' });
      send('LEAVE');
      log.capture('FAIL:RTC加入房间', {
        appId: APP_ID,
        channelName,
        token,
        uid,
        error,
      });
    }
  }, [
    send,
    channelName,
    token,
    uid,
    dispatch,
    handleUserJoinAtWaiting,
    handleUserLeftAtWaiting,
    handleUserPublishedAtWaiting,
    handleUserUnpublishedAtWaiting,
    handleException,
    handleConnectionStateChange,
    handleStreamTypeChanged,
    handleStreamFallback,
    handleRenewAgoraToken,
    handleExpiredAgoraToken,
  ]);

  const publish = React.useCallback(async () => {
    try {
      RoomModel.rtcClient.removeAllListeners('user-joined');
      RoomModel.rtcClient.removeAllListeners('user-left');
      RoomModel.rtcClient.removeAllListeners('user-published');
      RoomModel.rtcClient.removeAllListeners('user-unpublished');

      RoomModel.rtcClient.on('user-joined', handleUserJoinAtPublished);
      RoomModel.rtcClient.on('user-left', handleUserLeftAtPublished);
      RoomModel.rtcClient.on('user-published', handleUserPublishedAtPublished);
      RoomModel.rtcClient.on(
        'user-unpublished',
        handleUserUnpublishedAtPublished
      );
      RoomModel.rtcClient.on('volume-indicator', handleVolumeIndicator);
      RoomModel.rtcClient.on('network-quality', handleNetworkQuality);

      await RoomModel.rtcClient.publish(Object.values(RoomModel.localTracks));
      log.capture('SUCCESS:RTC推流');
    } catch (error) {
      toast.error('推流失败', { id: 'join_room_error' });
      send('LEAVE');
      log.capture('FAIL:RTC推流', { error });
    }
  }, [
    send,
    handleUserJoinAtPublished,
    handleUserLeftAtPublished,
    handleUserPublishedAtPublished,
    handleUserUnpublishedAtPublished,
    handleVolumeIndicator,
    handleNetworkQuality,
  ]);

  const leave = React.useCallback(
    async (closeCamera = true) => {
      try {
        const playersContainer = document.getElementById('players-container');
        while (playersContainer?.firstChild) {
          playersContainer.removeChild(playersContainer.firstChild);
        }

        // 执行美颜关闭(哪怕没有开)，避免下一步 close 摄像头时报错
        await RoomModel.localTracks.videoTrack?.setBeautyEffect(false);
        if (closeCamera) {
          Object.keys(RoomModel.localTracks).forEach((trackName) => {
            const track =
              RoomModel.localTracks[trackName as keyof ILocalTracks];
            if (track) {
              track.stop();
              track.close();
            }
          });
          RoomModel.localTracks = { videoTrack: null, audioTrack: null };
        }

        await RoomModel.rtcClient.leave();

        dispatch(roomActions.setLocalUserId(''));
      } catch (error) {
        log.capture('FAIL:离开RTC', { error, closeCamera });
      }
    },
    [dispatch]
  );

  const toggleLocalAudio = React.useCallback(async () => {
    try {
      if (RoomModel.localTracks.audioTrack) {
        await RoomModel.localTracks.audioTrack?.setMuted(!isLocalAudioMuted);
        dispatch(roomActions.setIsLocalAudioMuted(!isLocalAudioMuted));
        log.capture(`SUCCESS:${isLocalAudioMuted ? '开启' : '关闭'}麦克风`);

        const comment = isLocalAudioMuted ? '解除静音了' : '开启静音了';
        await RoomModel.rtmChannel?.sendMessage({
          text: JSON.stringify({ type: 2, message: comment }),
        });
      }
    } catch (error) {
      toast.error(`${isLocalAudioMuted ? '开启' : '关闭'}麦克风失败`);
      log.capture(`FAIL:${isLocalAudioMuted ? '开启' : '关闭'}麦克风`, {
        error,
      });
    }
  }, [dispatch, isLocalAudioMuted]);

  const toggleLocalVideo = React.useCallback(async () => {
    try {
      if (RoomModel.localTracks.videoTrack) {
        await RoomModel.localTracks.videoTrack?.setMuted(!isLocalVideoMuted);
        dispatch(roomActions.setIsLocalVideoMuted(!isLocalVideoMuted));
        log.capture(`SUCCESS:${isLocalVideoMuted ? '开启' : '关闭'}摄像头`);
      }
    } catch (error) {
      toast.error(`${isLocalVideoMuted ? '开启' : '关闭'}摄像头失败`);
      log.capture(`FAIL:${isLocalVideoMuted ? '开启' : '关闭'}摄像头`, {
        error,
      });
    }
  }, [dispatch, isLocalVideoMuted]);

  return {
    join,
    publish,
    toggleLocalAudio,
    toggleLocalVideo,
    toggleBeautyEffect,
    toggleBlurEffect,
    leave,
  };
}

export default useRTC;
