import { useLocation } from 'react-router-dom';

import i18n from './i18n';
const Language = ({ children }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang') || sessionStorage.getItem('lang');
  if (lang) {
    sessionStorage.setItem('lang', lang);
    i18n
      .changeLanguage(lang)
      .catch((err) => console.error('Failed to change language', err));
  }
  console.log('lang', lang);
  return children;
};

export default Language;
