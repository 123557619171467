import { Box, Button, Message, Typography } from 'fbm-ui';
import Pusher from './Pusher';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Mask from './Mask';
import { Stack } from 'fbm-ui/lib/mui';
import IntviewAPI from '../../api/Intview';
import Progress from './Progress';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { interviewActions } from './slice';
import useInvterviewStatus from './useInvterviewStatus';
import {
  INTVIEW_STATUS_PREPARE,
  INTVIEW_STATUS_START,
  INTVIEW_STATUS_FOLLOWUP,
  INTVIEW_STATUS_VIDEO_BROADCAST,
  SETP_ACTION_MAP,
} from './constant';
import usePlaySound from './usePlaySound';
import { Alphabet, ColorBlindness, Deacon, SingleChoice } from './QuestionType';
import { keyframes } from '@emotion/react';
import { formatTimeNumber } from './utils/format';
import {
  INTERVIEW_TYPE,
  syncEndInterview,
  syncStartInterview,
} from './utils/Interview';
import { showErrorMessage } from '../../utils/apiCheck';
import Video from './Video';
import VideoMask from './VideoMask';
import { StyledVideo } from './styles';
import { XiaomianPlayer } from './Xiaomian';
import { useTranslation } from 'react-i18next';

const getDuration = (startTimestamp) => {
  return Math.floor((new Date().getTime() - startTimestamp) / 1000);
};

const IS_LANGUAGE_TEST = (step) => step.type === 1 && step.extra?.readText;

const spin = keyframes`
  0% {
    background: red;
  }
  80%, 100% {
    background: transparent;
  }
`;

const BroadcastTitle = ({
  stepIndex,
  desc,
  countdown,
  duration,
  isFllowUp,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2}>
      <Typography variant="body1" fontWeight="medium" flexGrow={1}>
        {!isFllowUp && <>{stepIndex + 1}.</>}
        {isFllowUp ? (
          <Typography
            variant="caption"
            fontWeight="medium"
            display="inline"
            sx={{
              backgroundColor: 'rgb(76, 175, 80)',
              color: 'white',
              borderRadius: 1,
              overflow: 'hidden',
              px: 0.5,
              py: 0.25,
              mr: 1,
            }}
          >
            {t('Follow-up')}
          </Typography>
        ) : (
          ''
        )}
        {desc}
      </Typography>
      <Stack
        flexShrink={0}
        direction="row"
        alignItems="center"
        spacing={1}
        width={64}
        alignSelf="flex-start"
      >
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: '#ff0000',
            animation: `${spin} infinite 1s linear`,
          }}
        />
        <Typography variant="body1" fontWeight="medium">
          {formatTimeNumber(countdown || duration)}
        </Typography>
      </Stack>
    </Stack>
  );
};

const OkButton = ({
  step,
  handleNext,
  answerRemainTimes,
  answerStatus,
  isFllowUp,
  handleRetry,
}) => {
  const { t } = useTranslation();
  if (step === INTVIEW_STATUS_START) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          width: 'calc(100% - 24px)',
          position: 'absolute',
          bottom: 8,
        }}
      >
        <Button size="medium" sx={{ width: 208 }} onClick={handleNext}>
          {t("I'm Done")}
        </Button>
        {answerRemainTimes > 1 && answerStatus === 1 && !isFllowUp && (
          <Button
            size="medium"
            sx={{ width: 208 }}
            color="inherit"
            onClick={handleRetry}
          >
            {t('Retry  (X More)', { times: answerRemainTimes - 1 })}
          </Button>
        )}
      </Stack>
    );
  }
  return null;
};

const Interview = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { play } = usePlaySound();

  useInvterviewStatus();

  const ref = useRef(null);
  const room = useAppSelector((state) => state.interview);
  const stepInfo = useAppSelector((state) => state.interview.stepInfo);
  const interviewSteps = useAppSelector(
    (state) => state.interview.progressInfo.interviewSteps
  );
  const { step, nextProcess, stepIndex, isAllFinished, interviewInfo } = room;

  const { interaction, interactType } = interviewInfo || {};

  const { token: interviewToken } = nextProcess.process || {};

  const [selected, setSelected] = useState(-1);

  const {
    pcLiveUrl,
    desc,
    answerRemainTimes,
    streamName,
    type,
    extra,
    aiShowType,
    broadcastType,
    videoBroadcast,
    isOpenAIFollowUp,
    answerStatus,
    duration,
    minResponseDuration,
    isFllowUp,
    options,
  } = stepInfo || {};

  const countdownRef = useRef(0);
  const countdownPreRef = useRef(0);
  const totalTimeRef = useRef(0);

  const [countdown, setCountdown] = useState(duration);
  const currentStep = interviewSteps[stepIndex];

  const interviewType = INTERVIEW_TYPE(
    interactType,
    interaction?.interviewerType
  );

  const videoUrl = videoBroadcast?.videoUrlWide || videoBroadcast.videoUrl;

  const handleReloadProgress = useCallback(() => {
    IntviewAPI.getProgress(interviewToken)
      .then((res) => {
        setCountdown(res.duration);
        countdownRef.current = 0;
        countdownPreRef.current = 0;

        dispatch(interviewActions.setProgressInfo(res));
        setSelected(-1);
      })
      .catch(showErrorMessage);
  }, [dispatch, interviewToken]);

  const handleStartAnswer = useCallback(() => {
    syncStartInterview({
      stepToken: currentStep.stepToken,
      isOpenAIFollowUp: isOpenAIFollowUp,
      streamName: streamName,
      prepareTime: getDuration(totalTimeRef.current),
    })
      .then(() => {
        console.log('syncStartInterview');
      })
      .catch(showErrorMessage);
    IntviewAPI.setStage(interviewToken, {
      stepToken: currentStep.stepToken,
    })
      .then(() => {
        console.log('setStage');
      })
      .catch(showErrorMessage);
  }, [currentStep.stepToken, isOpenAIFollowUp, streamName, interviewToken]);

  const handleBroadcastEnd = useCallback(() => {
    IntviewAPI.broadcastVideo(interviewToken, {
      stepToken: currentStep.stepToken,
    })
      .then(() => {
        dispatch(interviewActions.nextStep());
      })
      .catch(showErrorMessage);
  }, [dispatch, currentStep.stepToken, interviewToken]);

  const handleNext = useCallback(() => {
    if (countdownRef.current < minResponseDuration) {
      Message.error(
        t('Your answer is too short, at least X seconds', {
          duration: minResponseDuration,
        })
      );
      return;
    }
    if (isOpenAIFollowUp && interviewType !== 'virtualHuman') {
      dispatch(interviewActions.nextStep());
      IntviewAPI.followup('test', {
        stepToken: currentStep.stepToken,
        mediaName: streamName,
      }).then(({ isNeedFollowup, detail = {} }) => {
        if (!isNeedFollowup) {
          syncEndInterview({
            selected,
            stepToken: currentStep.stepToken,
            isOpenAIFollowUp: isOpenAIFollowUp,
            streamName: streamName,
            prepareTime: getDuration(totalTimeRef.current),
          }).then(() => {
            console.log('syncEndInterview');
            ref.current?.stopPush();
            return handleReloadProgress();
          });
        } else {
          setCountdown(duration);
          countdownRef.current = 0;
          countdownPreRef.current = 0;
          dispatch(interviewActions.setFollowUpStepInfo(detail));
          play(detail?.soundUrl, handleBroadcastEnd);
        }
      });
    } else {
      syncEndInterview({
        selected: selected ? [selected] : [],
        stepToken: currentStep.stepToken,
        isOpenAIFollowUp: isOpenAIFollowUp,
        streamName: streamName,
        prepareTime: getDuration(totalTimeRef.current),
      }).then(() => {
        console.log('syncEndInterview');
        ref.current?.stopPush();
        return handleReloadProgress();
      });
    }
  }, [
    minResponseDuration,
    isOpenAIFollowUp,
    interviewType,
    dispatch,
    currentStep.stepToken,
    streamName,
    selected,
    duration,
    handleReloadProgress,
    play,
    handleBroadcastEnd,
    t,
  ]);

  const handleSelected = useCallback((value) => {
    setSelected(value);
  }, []);
  const handlePusherError = useCallback(() => {
    console.log('error');
  }, []);

  const handleRetry = useCallback(() => {
    ref.current?.stopPush();
    handleReloadProgress();
  }, [handleReloadProgress]);

  const LivePuser = useMemo(
    () => (
      <Pusher liveUrl={pcLiveUrl} ref={ref} onPusherError={handlePusherError} />
    ),
    [handlePusherError, pcLiveUrl]
  );

  useEffect(() => {
    if (!duration || step !== INTVIEW_STATUS_START) return;

    setCountdown(duration);

    const timer = setInterval(() => {
      countdownRef.current = countdownRef.current + 1;
      setCountdown((timeLeft) => {
        if (timeLeft === 1) {
          handleNext();
          return 0;
        } else {
          return timeLeft - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [duration, step, handleNext]);

  useEffect(() => {
    if (step === INTVIEW_STATUS_START && !isFllowUp) {
      handleStartAnswer();
    }
  }, [handleStartAnswer, step, isFllowUp]);

  useEffect(() => {
    const pusherRef = ref.current;
    if (pusherRef && INTVIEW_STATUS_PREPARE === step) {
      pusherRef?.startPush();
      totalTimeRef.current = new Date().getTime();
    }
  }, [step]);

  useEffect(() => {
    if (isAllFinished) return;
    IntviewAPI.getStepInfo(currentStep.stepToken, {
      stepToken: currentStep.stepToken,
    })
      .then((res) => {
        setCountdown(res.duration);
        countdownRef.current = 0;
        countdownPreRef.current = 0;

        dispatch(interviewActions.setStepInfo(res));
        console.log(res);
        const { broadcastType, soundUrl } = res;

        if (
          (soundUrl && broadcastType === 1) ||
          (type === 1 && aiShowType === 2) ||
          type === 17
        ) {
          play(soundUrl, handleBroadcastEnd);
        }
      })
      .catch(showErrorMessage);
  }, [
    currentStep.stepToken,
    isAllFinished,
    dispatch,
    handleBroadcastEnd,
    play,
    answerRemainTimes,
    type,
    aiShowType,
  ]);

  const action = SETP_ACTION_MAP[step] as 'activated' | 'taking' | 'thinking';

  return (
    <Box
      width={1}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height={1}
      overflow="hidden"
      sx={{
        background: '#F9FAF9',
      }}
    >
      <Progress />
      <Box
        width={1087}
        position="relative"
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          {type === 17 && (
            <SingleChoice
              options={options}
              selected={selected}
              onChange={handleSelected}
              title={
                <BroadcastTitle
                  stepIndex={stepIndex}
                  desc={desc}
                  countdown={countdown}
                  duration={duration}
                  isFllowUp={isFllowUp}
                />
              }
              pusher={LivePuser}
              okButon={
                <OkButton
                  step={step}
                  handleNext={handleNext}
                  answerRemainTimes={answerRemainTimes}
                  answerStatus={answerStatus}
                  isFllowUp={isFllowUp}
                  handleRetry={handleRetry}
                />
              }
            />
          )}
          {type === 4 && (
            <Alphabet
              extra={extra}
              title={
                <BroadcastTitle
                  stepIndex={stepIndex}
                  desc={desc}
                  countdown={countdown}
                  duration={duration}
                  isFllowUp={isFllowUp}
                />
              }
              pusher={LivePuser}
              okButon={
                <OkButton
                  step={step}
                  handleNext={handleNext}
                  answerRemainTimes={answerRemainTimes}
                  answerStatus={answerStatus}
                  isFllowUp={isFllowUp}
                  handleRetry={handleRetry}
                />
              }
            />
          )}
          {type === 2 && (
            <ColorBlindness
              extra={extra}
              title={
                <BroadcastTitle
                  stepIndex={stepIndex}
                  desc={desc}
                  countdown={countdown}
                  duration={duration}
                  isFllowUp={isFllowUp}
                />
              }
              pusher={LivePuser}
              okButon={
                <OkButton
                  step={step}
                  handleNext={handleNext}
                  answerRemainTimes={answerRemainTimes}
                  answerStatus={answerStatus}
                  isFllowUp={isFllowUp}
                  handleRetry={handleRetry}
                />
              }
            />
          )}
          {type === 1 && aiShowType === 2 && (
            <Deacon
              extra={extra}
              title={
                <BroadcastTitle
                  stepIndex={stepIndex}
                  desc={desc}
                  countdown={countdown}
                  duration={duration}
                  isFllowUp={isFllowUp}
                />
              }
              pusher={LivePuser}
              okButon={
                <OkButton
                  step={step}
                  handleNext={handleNext}
                  answerRemainTimes={answerRemainTimes}
                  answerStatus={answerStatus}
                  isFllowUp={isFllowUp}
                  handleRetry={handleRetry}
                />
              }
            />
          )}
          {type === 1 && aiShowType !== 2 && (
            <>
              <Box
                sx={{
                  position: 'static !important',
                }}
              >
                <StyledVideo
                  size={IS_LANGUAGE_TEST(stepInfo) ? 'small' : 'large'}
                >
                  {[2, 3].includes(broadcastType) && (
                    <Video src={videoUrl} onEnd={handleBroadcastEnd}></Video>
                  )}
                  {interviewType === 'robot' && broadcastType === 1 && (
                    <XiaomianPlayer action={action} />
                  )}
                  {interviewType === 'normal' &&
                    broadcastType === 1 &&
                    LivePuser}
                  <Box
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: 1,
                      width: 1,
                      alignItems: 'center',
                      top: 0,
                    }}
                  >
                    <Box
                      width="calc(100% - 48px)"
                      borderRadius={2}
                      bgcolor="rgba(255, 255, 255, 0.9)"
                      p={1.5}
                      fontSize="body1"
                      fontWeight={500}
                      color="textSecondary"
                      mx={3}
                      pb={6}
                      sx={{ position: 'absolute' }}
                      bottom={16}
                    >
                      {step === INTVIEW_STATUS_FOLLOWUP && isOpenAIFollowUp ? (
                        <Typography color="rgb(76, 175, 80)">
                          {t('The AI interviewer is thinking...')}
                        </Typography>
                      ) : (
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant="body1"
                            fontWeight="medium"
                            flexGrow={1}
                          >
                            {!isFllowUp && <>{stepIndex + 1}.</>}
                            {isFllowUp ? (
                              <Typography
                                variant="caption"
                                fontWeight="medium"
                                display="inline"
                                sx={{
                                  backgroundColor: 'rgb(76, 175, 80)',
                                  color: 'white',
                                  borderRadius: 1,
                                  overflow: 'hidden',
                                  px: 0.5,
                                  py: 0.25,
                                  mr: 1,
                                }}
                              >
                                {t('Follow-up')}
                              </Typography>
                            ) : (
                              ''
                            )}
                            {desc}
                          </Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            width={64}
                            alignSelf="flex-start"
                          >
                            <Box
                              sx={{
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                                backgroundColor: '#ff0000',
                                animation: `${spin} infinite 1s linear`,
                              }}
                            />
                            <Typography variant="body1" fontWeight="medium">
                              {formatTimeNumber(countdown || duration)}
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                      {step === INTVIEW_STATUS_START && (
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                          sx={{
                            width: 'calc(100% - 24px)',
                            position: 'absolute',
                            bottom: 8,
                          }}
                        >
                          <Button
                            size="medium"
                            sx={{ width: 208 }}
                            onClick={handleNext}
                          >
                            {t("I'm Done")}
                          </Button>
                          {answerRemainTimes > 1 &&
                            answerStatus === 1 &&
                            !isFllowUp && (
                              <Button
                                size="medium"
                                sx={{ width: 208 }}
                                color="inherit"
                                variant="contained"
                                onClick={handleRetry}
                              >
                                {t('Retry  (X More)', {
                                  times: answerRemainTimes - 1,
                                })}
                              </Button>
                            )}
                        </Stack>
                      )}
                    </Box>

                    {type === 1 && (
                      <Box
                        border="rgb(151, 151, 151)"
                        borderRadius={1}
                        sx={{
                          maxHeight: 350,
                          overflowY: 'auto',
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ wordBreak: 'break-all', lineHeight: '34px' }}
                        >
                          {extra?.readText}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </StyledVideo>
              </Box>
              {([2, 3].includes(broadcastType) ||
                interviewType !== 'normal') && (
                <StyledVideo size="small">
                  {(interviewType === 'robot' ||
                    [2, 3].includes(broadcastType)) &&
                    LivePuser}
                  {interviewType === 'normal' && broadcastType === 1 && (
                    <>
                      <Video src={videoUrl} onEnd={handleBroadcastEnd}></Video>
                      <Box
                        bgcolor="white"
                        sx={{ position: 'absolute' }}
                        top={6}
                        left={6}
                        width={58}
                        borderRadius={1}
                        height={26}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="body2">面试官</Typography>
                      </Box>
                    </>
                  )}
                </StyledVideo>
              )}
            </>
          )}
        </Box>
      </Box>
      {step === INTVIEW_STATUS_PREPARE && (
        <Mask countdownPreRef={countdownPreRef} />
      )}
      {step === INTVIEW_STATUS_VIDEO_BROADCAST && <VideoMask />}
    </Box>
  );
};

export default Interview;
