import { styled } from '@mui/material';
import { Stack, Text } from '..';

const StyledStack = styled(Stack)(({ onClick }: ILogo) => ({
  alignItems: 'center',
  ...(onClick && { cursor: 'pointer' }),
}));

const StyledImg = styled('img')(() => ({
  width: '32px',
  height: '32px',
}));

interface ILogo {
  onClick?: () => void;
}

function Logo({ onClick }: ILogo) {
  return (
    <StyledStack direction="row" spacing={0.5} onClick={onClick}>
      <StyledImg
        alt="logo"
        src="https://public-static-assets.oss-cn-beijing.aliyuncs.com/img/hr_logo.png"
      />
      <Text variant="body2" color="text.primary" fontWeight="fontWeightMedium">
        方便面招聘
      </Text>
    </StyledStack>
  );
}

export default Logo;
