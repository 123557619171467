import { createMachine, actions } from 'xstate';
const { send } = actions;

interface RoomContext {
  show: true;
}

type RoomEvent = { type: 'NEXT' } | { type: 'WAIT' } | { type: 'LEAVE' };

type RoomState = { value: string; context: RoomContext };

const roomMachine = createMachine<RoomContext, RoomEvent, RoomState>({
  id: 'room',
  context: {
    show: true,
  },
  initial: 'before',
  states: {
    before: {
      initial: 'checkAuth',
      states: {
        checkAuth: {
          entry: send({ type: 'NEXT' }),
          on: {
            NEXT: [
              {
                target: 'getPermission',
                cond: () =>
                  !!localStorage.getItem('id_token') &&
                  !!localStorage.getItem('user_type'),
              },
            ],
          },
        },
        getPermission: {
          on: { NEXT: 'done', WAIT: 'waitInvitation', LEAVE: '#after.initial' },
        },
        waitInvitation: {
          on: { NEXT: 'done' },
        },
        done: {
          type: 'final',
        },
      },
      onDone: 'ing',
    },
    ing: {
      initial: 'initial',
      states: {
        initial: {
          on: { NEXT: 'published', LEAVE: 'left' },
        },
        published: {
          on: { LEAVE: 'left' },
        },
        left: {
          type: 'final',
        },
      },
      onDone: 'after',
    },
    after: {
      id: 'after',
      initial: 'initial',
      states: {
        initial: {},
      },
    },
  },
});

export default roomMachine;
