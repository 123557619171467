import React from 'react';
import { Typography, Button } from 'fbm-ui';
import { Box, Paper, Stack } from 'fbm-ui/lib/mui';
import { DoneIcon, BackspaceIcon, SpeedIcon, CloseIcon } from 'fbm-icons';
import { useNavigate, useParams } from 'react-router-dom';

const Finish = ({ bgImg = '', typingInfo }) => {
  const { applicationToken = '', interviewToken = '' } = useParams();
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{
        background: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Stack
        component={Paper}
        spacing={5}
        justifyContent="center"
        alignItems="center"
        sx={{ width: 480, height: 360 }}
      >
        <Typography
          fontWeight={700}
          width={340}
          textAlign="center"
          fontSize={20}
        >
          练习已结束
        </Typography>
        <Stack spacing={2} mb={2}>
          <Stack direction="row" flex={1} spacing={1} height={64}>
            <SpeedIcon htmlColor="#FFC107"></SpeedIcon>
            <Typography>速度：{typingInfo?.typingSpeed} 字/分钟</Typography>
          </Stack>
          <Stack direction="row" flex={1} spacing={1} height={64}>
            <DoneIcon color="primary"></DoneIcon>
            <Typography>正确率：{typingInfo?.typingAccuracy} %</Typography>
          </Stack>
          <Stack direction="row" flex={1} spacing={1} height={64}>
            <CloseIcon color="error"></CloseIcon>
            <Typography>错误：{typingInfo?.typingError} 字</Typography>
          </Stack>
          <Stack direction="row" flex={1} spacing={1} height={64}>
            <BackspaceIcon color="info"></BackspaceIcon>
            <Typography>退格：{typingInfo?.typingBackspace} 次</Typography>
          </Stack>
        </Stack>
        <Button
          color="primary"
          sx={{ width: 160 }}
          onClick={() => {
            navigate(`/t/login/${applicationToken}/${interviewToken}`);
          }}
        >
          好的
        </Button>
      </Stack>
    </Box>
  );
};

export default Finish;
