import { getSalaryDesc } from './enum';

export function getFormatSalaryNumStrWithK(
  from: number,
  to: number,
  type: number
) {
  const typeStr = getSalaryDesc(type);
  const f = from >= 1000 ? Math.round((from / 1000) * 10) / 10 + 'k' : from;
  const t = to >= 1000 ? Math.round((to / 1000) * 10) / 10 + 'k' : to;
  if (type === 5) {
    return typeStr;
  } else {
    return f === t ? f : f + '-' + t;
  }
}

export function getFormatSalaryUnitStr(type: number) {
  if (type === 5) {
    return '';
  } else {
    return getSalaryDesc(type) === '' ? '' : '/' + getSalaryDesc(type);
  }
}

export function formatTimeNumber(n) {
  if (n < 10) {
    return '00:0' + Math.floor(n);
  } else if (n < 60) {
    return '00:' + Math.floor(n);
  } else if (n > 59) {
    const min = Math.floor(n / 60);
    const second = Math.floor(n % 60);
    const minStr = min < 10 ? '0' + min.toString() : min.toString();
    const secondStr = second < 10 ? '0' + second.toString() : second.toString();
    return minStr + ':' + secondStr;
  } else {
    return '00:00';
  }
}
