import * as React from 'react';

interface IOptions {
  open: boolean;
}

function useDialog(options?: IOptions) {
  const { open = false } = options ?? {};
  const [isOpen, setOpen] = React.useState(open);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return {
    isOpen,
    onOpen,
    onClose,
  };
}

export default useDialog;
