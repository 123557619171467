import React, { useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Select,
  FormItem,
  confirm,
} from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import TypewriterAPI from '../../api/typewriter';
import { useEffect, useState } from 'react';
import * as z from 'zod';
import { showErrorMessage } from '../../utils/apiCheck';
import { useNavigate, useParams } from 'react-router-dom';

export type FormData = {
  mobile: {
    mobile: string;
    mobileAreaCode?: string;
  };
  code: string;
};

export const schema = z.object({
  mobile: z.object({
    mobile: z.string().min(1),
    mobileAreaCode: z.string(),
  }),
  code: z.string().length(4),
});

const Login = () => {
  const { applicationToken = '', interviewToken = '' } = useParams();
  const navigate = useNavigate();
  const [aritcleToken, setArticleToken] = useState('');
  const [info, setInfo] = useState<{
    positionName: string;
    standard_type: number[];
    articleList: {
      title: string;
      token: string;
    }[];
    noteDetails: string;
    bgImg: string;
    maxLimit: number;
    tryCount: number;
  }>({
    standard_type: [],
    maxLimit: 0,
    tryCount: 0,
    positionName: '',
    articleList: [],
    noteDetails: '',
    bgImg: '',
  });

  const articles = useMemo(() => {
    if (!info.articleList.length) return [];
    setArticleToken(info.articleList[0]?.token);
    return info.articleList.map((item) => ({
      label: item.title,
      value: item.token,
    }));
  }, [info]);

  const handleLogin = (mode = 1) => {
    const canTryNumber = info.maxLimit - info.tryCount;
    if (mode === 1) {
      if (canTryNumber > 0) {
        if (info?.standard_type?.length > 0) {
          confirm({
            title: '提示',
            content: `您还有${canTryNumber}次正式测试机会，请在测试中保持专注，展现您的最佳水平！`,
            onOk: (props) => {
              props.onClose();
              navigate(
                `/t/${applicationToken}/${interviewToken}/${aritcleToken}/${mode}`
              );
            },
          });
        } else {
          navigate(
            `/t/${applicationToken}/${interviewToken}/${aritcleToken}/${mode}`
          );
        }
      } else {
        navigate(`/process/${applicationToken}`);
      }
    } else {
      navigate(
        `/t/${applicationToken}/${interviewToken}/${aritcleToken}/${mode}`
      );
    }
  };

  useEffect(() => {
    TypewriterAPI.getTypewriterArticles(interviewToken)
      .then(setInfo)
      .catch(showErrorMessage);
  }, [interviewToken]);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${info.bgImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <form>
        <Stack p={4} spacing={4} component={Paper} sx={{ width: 480 }}>
          <Typography variant="h5" fontWeight={500}>
            你好~欢迎你来参加{info.positionName}岗位面试
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            {info?.noteDetails}
          </Typography>
          <Stack spacing={3}>
            <FormItem
              label="选择文章"
              sx={{ display: articles.length === 1 ? 'none' : 'block' }}
            >
              <Select
                value={aritcleToken}
                options={articles}
                disabled={articles.length === 1}
                onChange={(e) => {
                  const { value } = e.target;
                  setArticleToken(value as string);
                }}
              ></Select>
            </FormItem>
          </Stack>
          <Stack spacing={3}>
            <Button
              color="inherit"
              size="large"
              variant="outlined"
              onClick={() => handleLogin(2)}
            >
              练习模式
              <Typography fontSize={12} color="textSecondary">
                （不计入成绩）
              </Typography>
            </Button>
            <Button
              size="large"
              onClick={() => handleLogin(1)}
              variant="contained"
            >
              正式打字测速
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default Login;
