import { Stack } from '@avocadoui/components';
import { useAppSelector } from '../../redux/hooks';
import { MAX_ITEM_PER_PAGE } from './const';

interface IShowOrHide {
  children: React.ReactNode;
  idx: number;
}

function ShowOrHide({ children, idx }: IShowOrHide) {
  const page = useAppSelector((state) => state.room.page);
  const isShow =
    idx >= MAX_ITEM_PER_PAGE * page && idx < MAX_ITEM_PER_PAGE * (page + 1);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      style={isShow ? {} : { display: 'none' }}
    >
      {children}
    </Stack>
  );
}

export default ShowOrHide;
