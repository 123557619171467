import * as React from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { isEmpty } from 'lodash';
import {
  confirm,
  InfoIcon,
  MicIcon,
  MicOffIcon,
  VideocamIcon,
  VideocamOffIcon,
} from 'fbm-ui';
import {
  Box,
  Button,
  LoadingDots,
  Stack,
  Avatar,
  toast,
  Tooltip,
} from '@avocadoui/components';
import { log } from '@avocadoui/utils';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { COLOR } from '../../utils/const';
import ToggleButton from './ToggleButton';
import useRTC from './useRTC';
import useRoomToken from './useRoomToken';
import useRTM from './useRTM';
import { useRoomSend } from './useMachineContext';
import useEffectChangedDevice from './useEffectChangedDevice';
import useEffectRedirectToMobile from './useEffectRedirectToMobile';
import MicPopover from './MicPopover';
import CameraPopover from './CameraPopover';
import useEffectSetDevices from './useEffectSetDevices';
import useEffectGetDevices from './useEffectGetDevices';
import RoomModel from './RoomModel';
import { useIsFetching } from 'react-query';
import useQueryRoomInfo from './useQueryRoomInfo';
import useQueryPositionInfo from './useQueryPositionInfo';
import { isInterviewer } from '../../utils/localStorage';
import { getPermissionTip, getPermissionTip2 } from '../../utils/tips';
import { roomActions } from './slice';
import useEffectVirtualBackground from './useEffectVirtualBackground';

function checkHasJoinedCurrentRoom(roomToken: string) {
  const joinedRoomToken = sessionStorage.getItem('roomToken');
  return roomToken === joinedRoomToken;
}

function RoomTest() {
  const send = useRoomSend();
  const isFetching = useIsFetching() > 0;
  const dispatch = useAppDispatch();

  const isLocalAudioMuted = useAppSelector(
    (state) => state.room.isLocalAudioMuted
  );
  const isLocalVideoMuted = useAppSelector(
    (state) => state.room.isLocalVideoMuted
  );
  const isLocalAudioPermissionDenied = useAppSelector(
    (state) => state.room.isLocalAudioPermissionDenied
  );
  const isLocalVideoPermissionDenied = useAppSelector(
    (state) => state.room.isLocalVideoPermissionDenied
  );
  const localUsername = useAppSelector((state) => state.account.username);
  const localAvatar = useAppSelector((state) => state.account.avatar);

  const { toggleLocalAudio, toggleLocalVideo } = useRTC();
  const { join: joinRTM } = useRTM();
  const roomToken = useRoomToken();

  const { isInitialed: isDeviceInitialed } = useEffectGetDevices();
  useEffectSetDevices();
  useEffectChangedDevice();
  useEffectRedirectToMobile();
  useEffectVirtualBackground(isDeviceInitialed);

  useQueryRoomInfo();
  const { refetch: refetchPositionInfo } = useQueryPositionInfo();

  const [isJoining, setIsJoining] = React.useState(false);
  async function handleJoin() {
    const isBrowserSupport = await AgoraRTC.checkSystemRequirements();
    const isTracksReady = !isEmpty(RoomModel.localTracks);
    console.log('isBrowserSupport:', isBrowserSupport);
    if (!isBrowserSupport) {
      toast.error('不支持当前浏览器');
      return;
    }
    console.log('isTracksReady:', isTracksReady, RoomModel.localTracks);

    if (isLocalAudioPermissionDenied || isLocalVideoPermissionDenied) {
      let type: 'audio' | 'video' | 'all' = 'audio';
      if (isLocalAudioPermissionDenied) type = 'audio';
      if (isLocalVideoPermissionDenied) type = 'video';
      if (isLocalAudioPermissionDenied && isLocalVideoPermissionDenied) {
        type = 'all';
      }
      const [text1, text2] = getPermissionTip2(type);
      toast.error(
        <span>
          {text1}
          {text2 && (
            <>
              <br />
              {text2}
            </>
          )}
        </span>,
        {
          duration: 10000,
        }
      );

      if (isLocalAudioPermissionDenied) {
        return;
      }
    }

    if (!RoomModel.audioVolumeLevelIsGood) {
      // toast.error('麦克风收音异常，请检查后重试');
      log.capture('FAIL:麦克风音量');
      confirm({
        header: null,
        content: '麦克风收音异常，请检测后重试',
        onOk: ({ onClose }) => {
          dispatch(roomActions.setTestDeviceDialogIsOpen(true));
          onClose?.();
        },
        okText: '开始检测',
      });
      return;
    }

    // 直通房间
    if (isInterviewer() || checkHasJoinedCurrentRoom(roomToken)) {
      try {
        setIsJoining(true);
        await joinRTM();
        send('NEXT');
      } catch (error) {
        console.error('handleQuickJoin:', error);
      } finally {
        setIsJoining(false);
      }
    } else {
      // 判断房间模式：1:直通模式 2:叫号模式(需面试官邀请)
      try {
        setIsJoining(true);
        const result = await refetchPositionInfo();
        if (result.isSuccess) {
          const canJoinRoomWithoutInvitation = result.data.model === 1;
          await joinRTM();
          if (canJoinRoomWithoutInvitation) {
            send('NEXT');
          } else {
            send('WAIT');
          }
        } else if (result.isError) {
          setIsJoining(false);
        }
      } catch (error) {
        console.error('handleJoin:', error);
      } finally {
        setIsJoining(false);
      }
    }
  }

  return (
    <Box sx={{ backgroundColor: '#F9FAF9', height: '100%' }}>
      <Stack
        spacing={2}
        sx={{
          margin: '24px auto',
          padding: '24px',
          width: '640px',
          backgroundColor: 'white',
          borderRadius: '8px',
          border: '1px solid rgba(0,0,0,0.08)',
        }}
      >
        {/* 本地摄像头 */}
        <Box
          id="local-player-test-container"
          sx={{
            position: 'relative',
            width: '100%',
            height: 'calc((640px - 48px - 2px) * 9 / 16)',
            overflow: 'hidden',
            borderRadius: '8px',
          }}
        >
          {isLocalVideoMuted && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: '#D8D8D8',
                zIndex: 1,
              }}
            >
              <Avatar
                size="extraLarge"
                name={localUsername}
                src={localAvatar}
              />
            </Box>
          )}
        </Box>
        {/* 控制面板 */}
        <Stack
          direction="row"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <Box sx={{ position: 'relative' }}>
            {isLocalAudioPermissionDenied ? (
              <Tooltip title={getPermissionTip('audio')} placement="top">
                <span>
                  <Box sx={{ position: 'relative' }}>
                    <ToggleButton
                      icon={<MicOffIcon style={{ color: COLOR.DISABLED }} />}
                    >
                      麦克风
                      <Box sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                        <InfoIcon sx={{ width: 16, color: '#F5C441' }} />
                      </Box>
                    </ToggleButton>
                  </Box>
                </span>
              </Tooltip>
            ) : (
              <ToggleButton
                icon={
                  isLocalAudioMuted ? (
                    <MicOffIcon style={{ color: COLOR.SECONDARY }} />
                  ) : (
                    <MicIcon style={{ color: COLOR.DEFAULT }} />
                  )
                }
                onClick={toggleLocalAudio}
              >
                麦克风
              </ToggleButton>
            )}
            {!isLocalAudioMuted && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '-50%',
                  transform: 'translate(-30%, -50%)',
                }}
              >
                <MicPopover />
              </Box>
            )}
          </Box>
          <Box sx={{ position: 'relative' }}>
            {isLocalVideoPermissionDenied ? (
              <Tooltip title={getPermissionTip('video')} placement="top">
                <span>
                  <Box sx={{ position: 'relative' }}>
                    <ToggleButton
                      icon={
                        <VideocamOffIcon style={{ color: COLOR.DISABLED }} />
                      }
                    >
                      摄像头
                      <Box sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                        <InfoIcon sx={{ width: 16, color: '#F5C441' }} />
                      </Box>
                    </ToggleButton>
                  </Box>
                </span>
              </Tooltip>
            ) : (
              <ToggleButton
                icon={
                  isLocalVideoMuted ? (
                    <VideocamOffIcon style={{ color: COLOR.SECONDARY }} />
                  ) : (
                    <VideocamIcon style={{ color: COLOR.DEFAULT }} />
                  )
                }
                onClick={toggleLocalVideo}
              >
                摄像头
              </ToggleButton>
            )}
            {!isLocalVideoMuted && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '-50%',
                  transform: 'translate(-30%, -50%)',
                }}
              >
                <CameraPopover />
              </Box>
            )}
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleJoin}
              disabled={isFetching || !isDeviceInitialed || isJoining}
            >
              进入视频沟通{isJoining && <LoadingDots />}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default RoomTest;
