import { useMutation } from 'react-query';
import { useAppDispatch } from '../../redux/hooks';
import RoomModel from '../Room/RoomModel';
import { commentActions } from './slice';

function useMutationAddComment() {
  const dispatch = useAppDispatch();
  const mutation = useMutation(
    async ({ message }: { uid: string; message: string }) => {
      await RoomModel.rtmChannel.sendMessage({
        text: JSON.stringify({ type: 1, message }),
      });
    },
    {
      onSuccess: (res, { uid, message }) => {
        console.log('useMutationAddComment:', res);
        dispatch(
          commentActions.addComment({
            uid,
            type: 1,
            message,
          })
        );
      },
    }
  );
  return mutation;
}

export default useMutationAddComment;
