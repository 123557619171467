import * as React from 'react';

import RoomAPI from '../../api/room';
import { useAppDispatch } from '../../redux/hooks';
import { roomActions } from './slice';
import useRoomToken from './useRoomToken';

function useEffectCheckOnlineIntervieweeNum() {
  const dispatch = useAppDispatch();
  const roomToken = useRoomToken();

  const fetch = React.useCallback(async () => {
    const result = await RoomAPI.getOnlineIntervieweeNum(roomToken);
    if (result.code === 0) {
      dispatch(roomActions.setOnlineIntervieweeNum(result.data.count));
    }
  }, [roomToken, dispatch]);

  React.useEffect(() => {
    fetch();

    const id = setInterval(async () => {
      await fetch();
    }, 5000);

    return () => {
      id && clearInterval(id);
    };
  }, [fetch]);
}

export default useEffectCheckOnlineIntervieweeNum;
