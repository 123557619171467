import { createSlice } from '@reduxjs/toolkit';
import {
  ConnectionState,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import { uniq } from '@avocadoui/utils';
// import { filterDefaultDeviceId } from '../../utils/webrtc';

export interface ILocalTracks {
  videoTrack: ICameraVideoTrack | null;
  audioTrack: IMicrophoneAudioTrack | null;
}

export interface IRemoteUsers {
  [key: string]: IAgoraRTCRemoteUser;
}

export interface IRoomUser {
  userName: string;
  avatar: string;
  userType: 1 | 2;
  joinTime: number;
  agoraUid: number;
  connectionStatus: 1 | 2;
  applicationToken: string;
  candidateToken: string;
}

interface IPositionInfo {
  enterpriseName: string;
  positionName: string;
  status: 0 | 1 | 2 | 3;
  model: 0 | 1 | 2; // 1:自动进入，无需邀请 2:叫号模式
}

interface IPositionInfoForCandidate {
  positionDesc: string;
  treatments: string[];
  requirement: string;
  cover: {
    shareCoverImg: string;
    videoUrl: string;
  };
  resumeImg: string;
}

type TNetworkQualityScore = -1 | 0 | 1 | 2 | 3;

interface IRoomInfo {
  channelStatus: 0 | 1 | 2 | 3 | 4; // 0:未开始 1:开始 2:暂停 3:继续 4:结束
  roomType: 1 | 2; // 1:视频沟通 2:实时面试
}

interface RoomState {
  isDrawerOpen: boolean;
  localUserId: string;
  camerasInfo: MediaDeviceInfo[];
  micsInfo: MediaDeviceInfo[];
  speakersInfo: MediaDeviceInfo[];
  localTracks: ILocalTracks;
  selectedCameraId: string;
  selectedMicId: string;
  selectedSpeakerId: string;
  remoteUsers: IRemoteUsers;
  maxAudioVolumeUserId: string;
  isLocalAudioMuted: boolean;
  isLocalVideoMuted: boolean;
  isLocalAudioPermissionDenied: boolean;
  isLocalVideoPermissionDenied: boolean;
  isBeautyEffectEnabled: boolean;
  remoteUsersChanged: number;
  leaveRoomDispatched: number;
  userIdsAtWaiting: string[]; // 在 RoomWaiting 中获知的 userIds
  userIdsAtPublished: string[]; // 在 RoomPublished 中获知的 userIds
  userIdsAtInviting: string[]; // 在 RoomPublished 中发起视频的 userIds
  roomUsers: IRoomUser[]; // 从服务端获知所有应参会者
  positionInfo: IPositionInfo;
  positionInfoForCandidate: IPositionInfoForCandidate;
  page: number;
  afterPageType: 1 | 2 | 3; // 1:正常结束 2:被移出视频 3:在别处登录;
  networkQualityScore: TNetworkQualityScore;
  joinedTimestamp: number;
  remoteNetworkScores: Record<string, TNetworkQualityScore>;
  userIdsAtRooms: string[];
  focusedUserId: string;
  connectedRTCCounts: number;
  isUserListDrawerOpen: boolean;
  isPlayerListDrawerOpen: boolean;
  beforeMeWaitingUserList: string[];
  joinWaitingRoomUserId: string;
  onlineIntervieweeNum: number;
  roomInfo: IRoomInfo;
  mobileLinkUrl: string;
  testDeviceDialogIsOpen: boolean;
  rtcConnectionState: ConnectionState;
  rtmConnectionState: ConnectionState;
}

const initialState = {
  isDrawerOpen: true,
  localUserId: '',
  camerasInfo: [],
  micsInfo: [],
  speakersInfo: [],
  localTracks: {
    videoTrack: null,
    audioTrack: null,
  },
  selectedCameraId: '',
  selectedMicId: '',
  selectedSpeakerId: '',
  remoteUsers: {},
  maxAudioVolumeUserId: '',
  isLocalAudioMuted: true,
  isLocalVideoMuted: true,
  isLocalAudioPermissionDenied: false,
  isLocalVideoPermissionDenied: false,
  isBeautyEffectEnabled: false,
  remoteUsersChanged: 1,
  leaveRoomDispatched: 1,
  userIdsAtWaiting: [],
  userIdsAtPublished: [],
  userIdsAtInviting: [],
  roomUsers: [],
  positionInfo: {
    enterpriseName: '',
    positionName: '',
    status: 0, // 邀约的状态 1:正常 2:已取消 3:已结束
    model: 0, // 1:自动 2:手动
  },
  positionInfoForCandidate: {
    positionDesc: '',
    treatments: [],
    requirement: '',
    resumeImg: '',
    cover: {
      videoUrl: '',
      shareCoverImg: '',
    },
  },
  page: 0,
  afterPageType: 1,
  networkQualityScore: 0,
  joinedTimestamp: 0,
  remoteNetworkScores: {},
  userIdsAtRooms: [],
  focusedUserId: '',
  connectedRTCCounts: 0,
  isUserListDrawerOpen: true,
  isPlayerListDrawerOpen: true,
  beforeMeWaitingUserList: [],
  joinWaitingRoomUserId: '',
  onlineIntervieweeNum: 0,
  roomInfo: {
    channelStatus: 1,
    roomType: 2,
  },
  mobileLinkUrl: '',
  testDeviceDialogIsOpen: false,
  rtcConnectionState: 'DISCONNECTED',
  rtmConnectionState: 'DISCONNECTED',
} as RoomState;

const slice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    setLocalUserId: (state, action) => {
      state.localUserId = action.payload;
    },
    setCamerasInfo: (state, action) => {
      const info = action.payload;
      if (Array.isArray(info)) {
        state.camerasInfo = info;
        state.selectedCameraId = info?.[0]?.deviceId;
      }
    },
    setMicsInfo: (state, action) => {
      const info = action.payload;
      if (Array.isArray(info)) {
        state.micsInfo = info;
        state.selectedMicId = info?.[0]?.deviceId;
      }
    },
    setSpeakersInfo: (state, action) => {
      const info = action.payload;
      if (Array.isArray(info)) {
        state.speakersInfo = info;
        state.selectedSpeakerId = info?.[0]?.deviceId;
      }
    },
    setLocalTracks: (state, action) => {
      const { videoTrack = null, audioTrack = null } = action.payload;
      state.localTracks.videoTrack = videoTrack;
      state.localTracks.audioTrack = audioTrack;
    },
    setSelectedCameraId: (state, action) => {
      state.selectedCameraId = action.payload;
    },
    setSelectedMicId: (state, action) => {
      state.selectedMicId = action.payload;
    },
    setSelectedSpeakerId: (state, action) => {
      state.selectedSpeakerId = action.payload;
    },
    selectNextMic: (state) => {
      const currentMicId = state.selectedMicId;
      const currentMicIdx = state.micsInfo.findIndex(
        (p) => p.deviceId === currentMicId
      );
      const nextMicIdx = currentMicIdx + 1;
      const nextMicId =
        state.micsInfo[nextMicIdx % state.micsInfo.length].deviceId;
      state.selectedMicId = nextMicId;
    },
    selectNextSpeaker: (state) => {
      const currentSpeakerId = state.selectedSpeakerId;
      const currentSpeakerIdx = state.speakersInfo.findIndex(
        (p) => p.deviceId === currentSpeakerId
      );
      const nextSpeakerIdx = currentSpeakerIdx + 1;
      const nextSpeakerId =
        state.speakersInfo[nextSpeakerIdx % state.speakersInfo.length].deviceId;
      state.selectedSpeakerId = nextSpeakerId;
    },
    changedRemoteUsers: (state) => {
      state.remoteUsersChanged += 1;
    },
    setMaxAudioVolumeUserId: (state, action) => {
      state.maxAudioVolumeUserId = action.payload;
    },
    setIsLocalAudioMuted: (state, action) => {
      state.isLocalAudioMuted = action.payload;
    },
    setIsLocalVideoMuted: (state, action) => {
      state.isLocalVideoMuted = action.payload;
    },
    setIsLocalAudioPermissionDenied: (state, action) => {
      state.isLocalAudioPermissionDenied = action.payload;
    },
    setIsLocalVideoPermissionDenied: (state, action) => {
      state.isLocalVideoPermissionDenied = action.payload;
    },
    setIsBeautyEffectEnabled: (state, action) => {
      state.isBeautyEffectEnabled = action.payload;
    },
    leaveRoomDispatched: (state, action) => {
      state.leaveRoomDispatched += 1;
      state.afterPageType = action.payload;
    },
    addToUserIdsAtWaiting: (state, action) => {
      const id = `${action.payload}`;
      state.userIdsAtWaiting = Array.from(
        uniq([...state.userIdsAtWaiting, id])
      );
    },
    removeFromUserIdsAtWaiting: (state, action) => {
      const id = `${action.payload}`;
      state.userIdsAtWaiting = state.userIdsAtWaiting.filter(
        (uid) => uid !== id
      );
    },
    addToUserIdsAtPublished: (state, action) => {
      const id = `${action.payload}`;
      state.userIdsAtPublished = Array.from(
        uniq([...state.userIdsAtPublished, id])
      );
    },
    removeFromUserIdsAtPublished: (state, action) => {
      const id = `${action.payload}`;
      state.userIdsAtPublished = state.userIdsAtPublished.filter(
        (uid) => uid !== id
      );
    },
    addToUserIdsAtInviting: (state, action) => {
      const id = `${action.payload}`;
      state.userIdsAtInviting = Array.from(
        uniq([...state.userIdsAtInviting, id])
      );
    },
    removeFromUserIdsAtInviting: (state, action) => {
      const id = `${action.payload}`;
      state.userIdsAtInviting = state.userIdsAtInviting.filter(
        (uid) => uid !== id
      );
    },
    addToUserIdsAtRooms: (state, action) => {
      const id = `${action.payload}`;
      state.userIdsAtRooms = Array.from(uniq([...state.userIdsAtRooms, id]));
    },
    removeFromUserIdsAtRooms: (state, action) => {
      const id = `${action.payload}`;
      state.userIdsAtRooms = state.userIdsAtRooms.filter((uid) => uid !== id);
    },
    setUserIdsAtRooms: (state, action) => {
      state.userIdsAtRooms = action.payload;
    },
    setRoomUsers: (state, action) => {
      state.roomUsers = action.payload;
    },
    setPositionInfo: (state, action) => {
      state.positionInfo = action.payload;
    },
    setPositionInfoForCandidate: (state, action) => {
      state.positionInfoForCandidate = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setNetworkQualityScore: (state, action) => {
      state.networkQualityScore = action.payload;
    },
    setJoinedTimestamp: (state, action) => {
      state.joinedTimestamp = action.payload;
    },
    setRemoteNetworkScores: (state, action) => {
      state.remoteNetworkScores = action.payload;
    },
    toggleInvitationModel: (state) => {
      const model = state.positionInfo.model;
      if (model === 1) {
        state.positionInfo.model = 2;
      }
      if (model === 2) {
        state.positionInfo.model = 1;
      }
    },
    setFocusedUserId: (state, action) => {
      state.focusedUserId = action.payload;
    },
    countConnectedRTC: (state) => {
      state.connectedRTCCounts += 1;
    },
    toggleUserListDrawer: (state) => {
      state.isUserListDrawerOpen = !state.isUserListDrawerOpen;
    },
    togglePlayerListDrawer: (state) => {
      state.isPlayerListDrawerOpen = !state.isPlayerListDrawerOpen;
    },
    setBeforeMeWaitingUserList: (state, action) => {
      state.beforeMeWaitingUserList = action.payload;
    },
    leftBeforeMeWaitingUserList: (state, action) => {
      state.beforeMeWaitingUserList = state.beforeMeWaitingUserList.filter(
        (uid: string) => uid !== action.payload.toString()
      );
    },
    setJoinWaitingRoomUserId: (state, action) => {
      state.joinWaitingRoomUserId = action.payload;
    },
    setOnlineIntervieweeNum: (state, action) => {
      state.onlineIntervieweeNum = action.payload;
    },
    setRoomInfo: (state, action) => {
      state.roomInfo = action.payload;
    },
    toggleRecording: (state, action) => {
      state.roomInfo.channelStatus = action.payload;
    },
    setMobileLinkUrl: (state, action) => {
      state.mobileLinkUrl = action.payload;
    },
    setTestDeviceDialogIsOpen: (state, action) => {
      state.testDeviceDialogIsOpen = action.payload;
    },
    setRtcConnectionState: (state, action) => {
      state.rtcConnectionState = action.payload;
    },
    setRtmConnectionState: (state, action) => {
      state.rtmConnectionState = action.payload;
    },
  },
});

export const roomActions = slice.actions;
export const roomReducer = slice.reducer;
