import * as React from 'react';
import RoomAPI from '../../api/room';
import { isInterviewer } from '../../utils/localStorage';
import useRoomToken from './useRoomToken';

function useConnectRoom() {
  const roomToken = useRoomToken();

  return React.useCallback(async () => {
    try {
      if (isInterviewer()) {
        await RoomAPI.connectInterviewer(roomToken);
      } else {
        await RoomAPI.connectInterviewee(roomToken);
      }
    } catch (error) {
      console.error('useConnectRoom:', error);
    }
  }, [roomToken]);
}

export default useConnectRoom;
