import { counterReducer } from '../modules/Counter/slice';
import { loginReducer } from '../modules/LoginForm/slice';
import { roomReducer } from '../modules/Room/slice';
import { commentReducer } from '../modules/Comment/slice';
import { accountReducer } from '../modules/Account/slice';
import { interviewReducer } from '../modules/Interview/slice';

const reducer = {
  counter: counterReducer,
  login: loginReducer,
  room: roomReducer,
  comment: commentReducer,
  account: accountReducer,
  interview: interviewReducer,
};

export default reducer;
