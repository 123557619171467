import { Message } from 'fbm-ui';

const MESSAGES = {
  '-2030': {
    zh: '手机号错误',
    en: 'Incorrect phone number',
  },
  '-2001': {
    zh: '参数验证失败',
    en: 'Parameter validation failed',
  },
  '-1001': {
    zh: '系统错误',
    en: 'System Error',
  },
  '-5021': {
    zh: '发送过于频繁',
    en: 'Sending too frequently',
  },
  '-2055': {
    zh: '发送失败，请稍后重试',
    en: 'Sending failed, please try again later',
  },
  '-2054': {
    zh: '验证码失效，请重新获取',
    en: 'Invalid verification code, please try again.',
  },
  '-1002': {
    zh: '操作太频繁了',
    en: 'Operation too frequent',
  },
  '-2016': {
    zh: '验证码错误，请重新输入',
    en: 'Incorrect verification code, please re-enter',
  },
};

class APIError extends Error {
  public code: number; // 声明 `code` 属性类型

  constructor(message, code) {
    super(message); // 调用父类的构造函数
    this.name = this.constructor.name; // 保留自定义错误类的名称
    this.code = code; // 添加自定义的错误码属性

    // 捕获堆栈信息（在 V8 引擎中可用）
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export const apiCheck = (resp) => {
  const { code, message, data } = resp;
  if (code !== 0) return Promise.reject(new APIError(message, code));
  return data;
};

export const showErrorMessage = (err: APIError) => {
  const lang = sessionStorage.getItem('lang') || 'zh';
  const message = MESSAGES[err?.code]?.[lang] || err.message;
  Message.error(message);
};
export const suppressErrorMessage = (err: APIError) => console.log(err.message);
